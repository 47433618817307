import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { AreaOfExpertise } from "../core/models/user-clinic-select-overlay-data.model";

@Injectable({
  providedIn: 'root'
})
export class UserApi {


  private _areaOfExpertisAll:AreaOfExpertise[] = []

  get areaOfExpertisAll():AreaOfExpertise[] {
    return this.areaOfExpertisAll
  }
  
  set areaOfExpertisAll(val: AreaOfExpertise[]) {
    this.areaOfExpertisAll = val
  }
  

  constructor(private http:HttpClient) { }

  async get(call:USER_API_CALL){
    const _d = await this.http.get(USER_API_REQUESTS(call)).toPromise()
    // .subscribe( _data => {
    //   return _data })
    return _d
  }

  async getP(call:USER_API_CALL, id:string){
    const _d =  this.http.get(USER_API_REQUESTS(call,id)).toPromise()
    // .subscribe( _data => {
    //   return _data })
    return _d
  }
  async post(path:string, payload:Object){
    return await this.http.post(path,payload).toPromise()
    // .subscribe(
    //   _data => {
    //     return _data
    //   }
    // )
  }
  async put(path:string, payload:Object){
    return await this.http.put(path,payload).subscribe(
      _data => {
        return _data
      }
    )
  }

   async loadAreOfExpertise(){
    if(this._areaOfExpertisAll.length > 0){

      return this._areaOfExpertisAll
    } else {
      const _data2:any = await this.get(USER_API_CALL.GET_AREAS_OF_EXPERTISE_ADMIN)

      this._areaOfExpertisAll = _data2 as AreaOfExpertise[]
      return this._areaOfExpertisAll
    }
    
  }
}



export enum USER_API_CALL {
  GET_ALL,
  GET_AREAS_OF_EXPERTISE_BY_UID,
  GET_AREAS_OF_EXPERTISE_ADMIN,
  POST_AREAS_OF_EXPERTISE_BY_UID,
  DELETE_AREAS_OF_EXPERTISE_BY_UID,
  TOGGLE_PRIMARY_AREA_OF_EXPERTISE_BY_ID,
  MANAGER_ACCOUNT_UPDATE,
  POST_USER_FACHGEBIET_PAD,
  INVITE_NEW_USER,
  POST_INVITE_NEW_USER_AND_ADD_TO_POOL,
  CHECK_EMAIL_EXISTS,
  POST_USER_SUBSCRION_ID,
  REMOVE_USER_BY_ID
  // GET_BLOCK_USER_BY_ID,
  // GET_UNBLOCK_USER_BY_ID

  // UPDATE = "UPDATE",
  // CREATE = "CREATE",
  // // USERS_BY_CLINIC = "USERS_BY_CLINIC",
  // ADD_CLINIC_TO_USER = "ADD_CLINIC_TO_USER"
}

export function USER_API_REQUESTS(call:USER_API_CALL, params:string = '') {
  switch(call) {
    case USER_API_CALL.GET_ALL:
    return `${environment.endpoints.apiBasePathV2}admin/clinic/`;

    case USER_API_CALL.GET_AREAS_OF_EXPERTISE_BY_UID:
      return `${environment.endpoints.apiBasePathV2}ui/expertise-areas-uid/${params}`;

    case USER_API_CALL.GET_AREAS_OF_EXPERTISE_ADMIN:
      return `${environment.endpoints.apiBasePathV2}ui/expertise-areas-admin`;

    case USER_API_CALL.POST_AREAS_OF_EXPERTISE_BY_UID:
      return `${environment.endpoints.apiBasePathV2}ui/add-user-expertise-area-uid/${params}`;

    case USER_API_CALL.DELETE_AREAS_OF_EXPERTISE_BY_UID:
      return `${environment.endpoints.apiBasePathV2}ui/remove-user-expertise-area-id/${params}`;

    case USER_API_CALL.TOGGLE_PRIMARY_AREA_OF_EXPERTISE_BY_ID:
      return `${environment.endpoints.apiBasePathV2}ui/toggle-user-expertise-area-uid-primary/${params}`;

    case USER_API_CALL.MANAGER_ACCOUNT_UPDATE:
      return `${environment.endpoints.apiBasePathV2}admin/user-update/${params}`;
    case USER_API_CALL.POST_USER_FACHGEBIET_PAD:
      return `${environment.endpoints.apiBasePathV2}admin/user-pad-next-fach`;

    case USER_API_CALL.INVITE_NEW_USER:
      return `${environment.endpoints.apiBasePathV2}admin/user-invite`;
    case USER_API_CALL.REMOVE_USER_BY_ID:
      return `${environment.endpoints.apiBasePathV2}admin/user-remove/${params}`;

    case USER_API_CALL.POST_INVITE_NEW_USER_AND_ADD_TO_POOL:
      return `${environment.endpoints.apiBasePathV2}admin/clinic-user-invite/`;
    
    case USER_API_CALL.CHECK_EMAIL_EXISTS:
      return `${environment.endpoints.apiBasePathV2}accounts/email-exists/`;
    case USER_API_CALL.POST_USER_SUBSCRION_ID:
      return `${environment.endpoints.apiBasePathV2}admin/access/user-subscription-plan/`;

                  // case USER_API_CALL.GET_BLOCK_USER_BY_ID:
                  // return `${environment.endpoints.apiBasePathV2}admin/block-user/${params}`;

                  // case USER_API_CALL.GET_UNBLOCK_USER_BY_ID:
                  // return `${environment.endpoints.apiBasePathV2}admin/un-block-user/${params}`;



  }


  

}
