import { Injectable } from '@angular/core';
const TOKEN_KEY = 'auth-token';
const UID = 'u';
@Injectable({
  providedIn: 'root'
})
export class TokenstorageService {

  constructor() { }

  signOut(): void {
    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.removeItem(UID);
    // window.sessionStorage.clear();
  }

  public saveToken(token: string): void {


    window.sessionStorage.removeItem(TOKEN_KEY);
    window.sessionStorage.setItem(TOKEN_KEY, token);
  }
  public saveUID(uid: string): void {


    window.sessionStorage.removeItem(UID);
    window.sessionStorage.setItem(UID, uid);
  }

  public getUID(): string | null{
    return sessionStorage.getItem(UID);
  }
  public getToken(): string | null{
    return sessionStorage.getItem(TOKEN_KEY);
  }
}
