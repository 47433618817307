export interface ITextEditOverlayData {
  lineMode:LINEMODE;
  text:string;
}

export enum LINEMODE {
  SINGLE = "SINGLE",
  MULTI = "MULTI"
}


export enum TEXTTYPE {
  HEADLINE,
  DESCRIPTION
}
