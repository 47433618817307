import { environment } from "src/environments/environment";


export enum AUTH_CALL {

  POST_SIGN_IN,
  GET_UID,
  ACCOUNT

}


export function AUTH_REQUEST(call: AUTH_CALL, params: string = "") : string {

  switch (call) {
    case AUTH_CALL.POST_SIGN_IN:
      return `${environment.endpoints.apiBasePathV2}auth/sign-in`;
    case AUTH_CALL.GET_UID:
      return `${environment.endpoints.apiBasePathV2}auth/uid`;
    case AUTH_CALL.ACCOUNT: 
    return `${environment.endpoints.apiBasePathV2}admin/access`;
  }
}


export interface IAuthResponse {
  status?:STATUS_RESPONSE;
  type?: ERROR_TYPE
  message?: REPONSE_MESSAGE
  token?: string
  uid?: string
}

export enum STATUS_RESPONSE {
  ERROR = "ERROR",
  UPDATE_PASSWORD_REQUIRED = "UPDATE_PASSWORD_REQUIRED",
  ACCESS_ADDRESS_MISSING = "ACCESS_ADDRESS_MISSING",
  ACCESS = "ACCESS",
  SUCCESS = "SUCCESS",
}

export enum ERROR_TYPE {
  NO_PW_IN_DB = "NO_PW_IN_DB",
  WRONG_PW = "WRONG_PW",
  NO_SA_ACCESS = "NO_SA_ACCESS",
}


export enum REPONSE_MESSAGE {
  UPDATE_PASSWORD_REQUIRED = "UPDATE_PASSWORD_REQUIRED",
  MISSING_PAYLOAD = "MISSING_PAYLOAD",
  RETISTRATION_FAILED = "RETISTRATION_FAILED"

}
export interface IValiateFBResponse {
  status?:STATUS_RESPONSE;
  uid?: string
  token?: string
}
