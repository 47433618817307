import { HttpClient } from '@angular/common/http';
import { EventEmitter, Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AUTH_CALL, AUTH_REQUEST, ERROR_TYPE, IAuthResponse, STATUS_RESPONSE } from '../core/models/auth.call.enum';
import { TokenstorageService } from './tokenstorage.service';
import { UiService } from './ui.service';
import * as CryptoJS from 'crypto-js';
import { environment } from 'src/environments/environment';
@Injectable({
  providedIn: 'root'
})

export class AuthService {
  private _navigation:string[] = [];
  private key = environment.EncryptKey;
  loginEmitter: EventEmitter<boolean> = new EventEmitter<boolean>()
  get NAVIGATION():string[] {
    return this._navigation
  }

  // set NAVIGATION(val:string[]) {
  //   this._navigation = val
  // }
  private _loggendIn:boolean = false;
  timeout:any

  get loggedIn() {
    return this._loggendIn;
  }

  constructor(
    private http:HttpClient,
    private ui: UiService,
    private ts: TokenstorageService,
    private router:Router) {
    console.log('AUTH');
    this.post()
   }


   private post(){
    this.checkToken()
    const loggedIn = this._loggendIn
  }

  private setLoggedInd(){
    this._loggendIn = true;
    this.loginEmitter.emit(true)
  }
  private encryptAES = (text: string) => {
    return CryptoJS.AES.encrypt(text, this.key).toString();
  };

   async signIn(email:string, password:string){
    const pass = this.encryptAES(password)
    const _path = AUTH_REQUEST(AUTH_CALL.POST_SIGN_IN) +'?superadmin=yes'
    var _res:any = await this.http.post(_path,{
      email: email,
      password: pass
    }).toPromise()

    let _authResp : IAuthResponse = _res as IAuthResponse
    // console.log('_authResp', _authResp);
    if (_authResp && _authResp.status) {
      // console.log('_authResp.status', _authResp.status);

      if ( _authResp.status == STATUS_RESPONSE.SUCCESS) {

        if ( _authResp.token && _authResp.uid) {
          this.ts.saveToken(_authResp.token)
          this.ts.saveUID(_authResp.uid)
          setTimeout(()=>{
            this.checkToken()
          
          },600)
        } else {
          this.ui.snack.emit("AUTH ERROR")
        }




      } else if ( _authResp.status == STATUS_RESPONSE.ERROR) {

        if ( _authResp.type && _authResp.type == ERROR_TYPE.NO_SA_ACCESS) {
          this.ui.snack.emit("Super Admin access missing")
        } else {
          this.ui.snack.emit("AUTH ERROR")
        }

      }


    }

   }

   async signOut(){
    this._loggendIn = false;
     this.ts.signOut()
     this._navigation = []
    setTimeout(()=>{
      this.router.navigateByUrl('sign-in')
    },500)
    this.loginEmitter.emit(false)
   }

   checkToken() {
    const _token = this.ts.getToken()
    if(_token) {
      this.getAccount()
      this.setLoggedInd()
      setTimeout(()=>{
        this.router.navigateByUrl('users')
      },600)
    } else {
      this.loginEmitter.emit(false)
    }
   }

   private async getAccount() {
    const _path = AUTH_REQUEST(AUTH_CALL.ACCOUNT)
    var _res:any = await this.http.get(_path).toPromise()
    // this.ui.NAVIGATION = _res as string[]
    this._navigation = _res as string[]
   
    this._navigation.push("DASHBOARD")
    this._navigation.push("USERS")
    console.log(this._navigation);
   }
}
