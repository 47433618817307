<mat-toolbar class="overlay-toolbar tlc justify-content-center">
  {{title}}
  </mat-toolbar>
  <div class="container p_t40 p_b40">

    <div class="row">
      <div class="col-12 pr32 pl32">
        <p style="text-align:center; font-size:16px; font-weight:400;" [innerHtml]='data.bodyMessage'></p>
      </div>
    </div>

  </div>
  <mat-toolbar class="overlay-toolbar tlc justify-content-center">
    <div style="width:auto; text-align:center;" *ngFor='let action of data.actions' class="tlc justify-content-center ml5" >
      <button mat-flat-button color="{{colorForActionColorType(action.color)}}" (click)="tapped(action.actionType)">{{action.title}}</button>
    </div>
  </mat-toolbar>
