<div class="container">
  <div class="d-flex flex-column">
    <div class="p-2 contentItem" *ngFor="let template of emailTemplates" (click)="openEmailDetail(template)">
      <div class="d-flex">
        <div class="p-2">
          {{template.title}}
        </div>
        <div class="p-2 ms-auto">
          <mat-icon  style="font-size: 22px;  user-select: none;" >open_in_new</mat-icon>
        </div>
      </div>
    </div>
  </div>
</div>
