
<mat-toolbar class="overlay-toolbar" >
  <mat-label style="font-weight:500!important; font-size: 16px!important;">
    {{windowTitle}}</mat-label>
  <span class="toolbar-spacer"></span>
    <mat-icon style="color:#ABBBC7; cursor: pointer; user-select:none;" (click)='close()'>close</mat-icon>
</mat-toolbar>


<div class="container-fluid" style="height: calc(100% - 88px); position: relative;">

<div class="d-flex" style="height:100%; overflow: hidden;" *ngIf='overlayMode === "EDIT_MODE" '>

  <div class="p-0 w60p">


    <div class="d-flex">
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Title</mat-label>
        <input class="formControl mat-body" [(ngModel)]='currentAccount.title' type="text"     (ngModelChange)="modelChangeFn($event)">
      </div>
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Facharzt-Bezeichnung</mat-label>
        <input class="formControl mat-body" [(ngModel)]='currentAccount.medical_specialty_designation' type="text"    (ngModelChange)="modelChangeFn($event)" >
      </div>
    </div>

    <dxfx-padnext-fachgebiet-selection
    [currentAccount]="currentAccount"
    [padNextFachBereichSelected]="padNextFachBereichSelected"
    [padNextFachArztBereichSelected]="padNextFachArztBereichSelected"
    ></dxfx-padnext-fachgebiet-selection>
    <!-- <div class="d-flex">
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Fach</mat-label>
        <mat-form-field appearance="outline" style="margin-top: -5px!important" class="select-form-field w100 ">
          <mat-select [(value)]="padNextFachBereichSelected" (selectionChange)='fachbereichCHanged($event)'>
            <mat-option *ngFor="let g of padNextFachFull" [value]="g">{{g.title}}</mat-option>      
          </mat-select>
        </mat-form-field>
      </div>
      <div class="p-2 w50p" *ngIf="padNextFachBereichSelected">
        <mat-label class='mat-caption'>Fach</mat-label>
        <mat-form-field  appearance="outline" style="margin-top: -5px!important" class="select-form-field w100 ">
          <mat-select [(value)]="padNextFachArztBereichSelected" (selectionChange)='fachArztCHanged($event)' >
            <mat-option *ngFor="let g of padNextItemsSelected" [value]="g">{{g.title}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div> -->

    <div class="d-flex">
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Vorname</mat-label>
        <input class="formControl mat-body" [(ngModel)]='currentAccount.firstName' type="text"    (ngModelChange)="modelChangeFn($event)" >
      </div>
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Nachname</mat-label>
        <input class="formControl mat-body" [(ngModel)]='currentAccount.lastName'type="text"    (ngModelChange)="modelChangeFn($event)" >
      </div>
    </div>
    <div class="d-flex">
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Email</mat-label>
        <input class="formControl mat-body" [(ngModel)]='currentAccount.email' type="text"  [disabled]="overlayMode === 'EDIT_MODE' ">
      </div>
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Tel</mat-label>
        <input class="formControl mat-body" [(ngModel)]='currentAccount.phone' type="text"    (ngModelChange)="modelChangeFn($event)" >
      </div>
    </div>
    <!-- <div class="d-flex">
        <div class="p-2 w50p">
          <mat-form-field appearance="outline" class="select-form-field w100">
            <mat-select (selectionChange)='onSubscriptionChanged($event)' [value]="currenPlanId">
              <mat-option [value]="plan.id" *ngFor="let plan of subscriptionPlan">
                {{plan.name}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
    </div> -->
  </div>

  <div class="p-0 w40p " >
    <div class="d-flex flex-column w100" style="height: 100%;">
      <div class="p-0 fachbereichHeader">
        <mat-label class='mat-caption' (dblclick)="initDeleteMode()">Fachbereiche</mat-label>
      </div>
      <div class="p-0 flex-fill" style="background-color: rgba(0,0,0,0.05); height: 100%;overflow-y: scroll;overflow-x: hidden;">
        <mat-list  (selectionChange)="optionChanged($event)" #_options>
          <mat-list-item *ngFor="let expertise of areaOfExpertisAll" >
            <div class="d-flex w100 align-items-center h32px">
              <div class="p-0 pl5">{{expertise.name}}</div>
              <div class="p-0 ms-auto  mr5 " >
                <div class="miniButton" [class.miniButton_active]="isPrimaryUserExpertise(expertise)" (click)="togglePrimary(expertise, isPrimaryUserExpertise(expertise))" *ngIf="userHasExpertise(expertise)">Primary</div>
              </div>
              <div class="p-0 mr5 ml5">
                <mat-checkbox [checked]="userHasExpertise(expertise)" (change)="checkBoxChanged($event,expertise)" [disabled]="userHasExpertise(expertise) && isPrimaryUserExpertise(expertise)"></mat-checkbox>
              </div>
            </div>
          </mat-list-item>

        </mat-list>
      </div>
    </div>
  </div>


    
</div>


<!-- INVITE_MODE -->
<div class="d-flex" style="height:100%; overflow: hidden;" *ngIf='overlayMode === "INVITE_MODE" '>
  <div class="p-0 w60p">
    <div class="d-flex">
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Titel (Optional)</mat-label>
        <input class="formControl mat-body" [(ngModel)]='newAccount.title' type="text" >
      </div>
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Facharzt-Bezeichnung (Optional)</mat-label>
        <input class="formControl mat-body" [(ngModel)]='newAccount.medical_specialty_designation' type="text">
      </div>
    </div>

    <div class="d-flex">
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Vorname*</mat-label>
        <input class="formControl mat-body" [(ngModel)]='newAccount.firstName' type="text"  >
      </div>
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Nachname*</mat-label>
        <input class="formControl mat-body" [(ngModel)]='newAccount.lastName' type="text"   >
      </div>
    </div>
    <div class="d-flex">
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Email*</mat-label>
        <input class="formControl mat-body" [(ngModel)]='newAccount.email' type="text" >
      </div>
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Tel (Optional)</mat-label>
        <input class="formControl mat-body" [(ngModel)]='newAccount.phone' type="text" >
      </div>
    </div>
  </div>

  <div class="p-0 w40p " >
    <div class="d-flex flex-column" style="height: 100%;">
      <div class="p-0 fachbereichHeader">
        <mat-label class='mat-caption' (dblclick)="initDeleteMode()">Fachbereiche</mat-label>
      </div>
      <div class="p-0" style="background-color: rgba(0,0,0,0.05); height: 100%;overflow-y: scroll;overflow-x: hidden;">
        <mat-list  (selectionChange)="optionChanged($event)" #_options>
          <mat-list-item *ngFor="let expertise of areaOfExpertisAll" >
            <div class="d-flex w100 align-items-center h32px">
              <div class="p-0 pl5">{{expertise.name}}</div>
              <div class="p-0 ms-auto  mr5 ">
                <div class="miniButton"   [class.miniButton_active]="isPrimaryNewUserExpertise(expertise)" (click)="togglePrimaryForNewInvite(expertise,isPrimaryNewUserExpertise(expertise))" *ngIf="newAccountHasAnyExpertise && newUserHasExpertise(expertise)">Primary</div>
              </div>
              <div class="p-0 mr5 ml5">
                <mat-checkbox [checked]="newUserHasExpertise(expertise)" (change)="newAccountCheckBoxChanged($event,expertise)"></mat-checkbox>
              </div>
            </div>
          </mat-list-item>

        </mat-list>
      </div>
    </div>
  </div>

</div>


   <div class="loader-overlay" *ngIf="isLoading">
      <div class="d-flex align-items-center justify-content-center" style="height: 100%;">
        <div class="p-2">
          <mat-spinner diameter='25' class="blue_spinner" color='primary'></mat-spinner>
        </div>
      </div>
   </div>

</div>


<mat-toolbar class="overlay-toolbar" style="height:44px!important;" >
  <!-- <dxfx-df-icon-button *ngIf='overlayMode === "EDIT_MODE" && currentAccount.state_enum === USER_STATE_ENUMS.BLOCKED_BY_ADMIN' color="warn"
  title="Freischalten" icon="person"  (clicked)="unBlockUser()" ></dxfx-df-icon-button>
  <dxfx-df-icon-button *ngIf='overlayMode === "EDIT_MODE" && currentAccount.state_enum === USER_STATE_ENUMS.REGISTERED' color="warn"
  title="Sperren" icon="person_off"  (clicked)="blockUser()" ></dxfx-df-icon-button> -->
  <span class="toolbar-spacer"></span>
  <div  *ngIf='!isSaving'>
      <dxfx-df-icon-button *ngIf='showSS_stuff' color="warn"
        title="Löschen" icon="delete"  (clicked)="removeUser()" ></dxfx-df-icon-button>

      <dxfx-df-icon-button *ngIf='overlayMode === "EDIT_MODE" && showSaveButton' color="accent"
      title="Speichern" icon="save" [disabled]="!saveEnabled" (clicked)="saveChanged()" ></dxfx-df-icon-button>

      <!-- <dxfx-df-icon-button *ngIf='overlayMode === "INVITE_MODE" ' title="Einladen" icon="send" [disabled]="!inviteButtonEnabled" (clicked)="startInvite()" ></dxfx-df-icon-button> -->
    </div>
  <div style="width:50px;"  *ngIf='isSaving'>
    <mat-spinner diameter='25' color='primary'></mat-spinner>
  </div>
</mat-toolbar>
