<div class="container" *ngIf="currentAddress">


  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 "  >
      <div class="d-flex flex-column">
        <div class="p-2">
          <mat-label class='mat-caption'>Klinik Rechn. Info</mat-label>
          <input class="formControl" placeholder="Die Rechnungserstellung erfolgt im Auftrag..."  [(ngModel)]="currentAddress.clinicLegalMain" (ngModelChange)="modelChangeFn($event)">
        </div>
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 "  >
      <div class="d-flex flex-column">
        <div class="p-2">
          <mat-label class='mat-caption'>Klinik Address Name</mat-label>
          <input class="formControl" placeholder="Name"  [(ngModel)]="currentAddress.klinikName" (ngModelChange)="modelChangeFn($event)">
        </div>
        <div class="p-2">
          <mat-label class='mat-caption'>Behandelnde(r) Arzt/Ärztin</mat-label>
          <input class="formControl" placeholder="Name"  [(ngModel)]="currentAddress.behandelnder_arzt" (ngModelChange)="modelChangeFn($event)">
        </div>
  
      </div>
    </div>
    <div class="col-lg-12 col-md-12 col-sm-12 "  >
      <div class="d-flex">
        <div class="p-2 flex-fill">
          <mat-label class='mat-caption'>Strasse</mat-label>
          <input  class="formControl" placeholder="Strasse"  [(ngModel)]="currentAddress.streetName" (ngModelChange)="modelChangeFn($event)">
        </div>
        <div class="p-2 " style="width: 240px">
          <mat-label class='mat-caption'>Stadt</mat-label>
          <input class="formControl" placeholder="Stadt"  [(ngModel)]="currentAddress.city" (ngModelChange)="modelChangeFn($event)">
        </div>
        <div class="p-2" style="width: 130px">
          <mat-label class='mat-caption'>Plz</mat-label>
          <input class="formControl" placeholder="Plz"  [(ngModel)]="currentAddress.zip" (ngModelChange)="modelChangeFn($event)">
        </div>
  
      </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 "  >
    <div class="d-flex flex-column">
      <div class="p-2">
        <mat-label class='mat-caption'></mat-label>
        <input class="formControl" placeholder="..."  [(ngModel)]="currentAddress.clinicInvoiceProviderFirst" (ngModelChange)="modelChangeFn($event)">
      </div>
      <div class="p-2">
        <mat-label class='mat-caption'></mat-label>
        <input class="formControl" placeholder="..."  [(ngModel)]="currentAddress.clinicInvoiceProviderThird" (ngModelChange)="modelChangeFn($event)">
      </div>
    </div>
    </div>
  
    <div class="col-lg-6 col-md-6 col-sm-12 "  >
    <div class="d-flex flex-column">
      <div class="p-2">
        <mat-label class='mat-caption'></mat-label>
        <input class="formControl" placeholder="..."  [(ngModel)]="currentAddress.clinicInvoiceProviderSecond" (ngModelChange)="modelChangeFn($event)">
      </div>
      <div class="p-2">
        <mat-label class='mat-caption'></mat-label>
        <input class="formControl" placeholder="..."  [(ngModel)]="currentAddress.clinicInvoiceProviderFourth" (ngModelChange)="modelChangeFn($event)">
      </div>
    </div>
    </div>
  </div>
  
  <div class="row">
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Kontoinhaber</mat-label>
      <input class="formControl" placeholder="Kontoinhaber"  [(ngModel)]="currentAddress.kontoinhaber" (ngModelChange)="modelChangeFn($event)">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Bankinstitut</mat-label>
      <input class="formControl" placeholder="Bankinstitut"  [(ngModel)]="currentAddress.bank_name" (ngModelChange)="modelChangeFn($event)">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>IBAN</mat-label>
      <input class="formControl" placeholder="IBAN"  [(ngModel)]="currentAddress.iban" (ngModelChange)="modelChangeFn($event)">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>BIC</mat-label>
      <input class="formControl" placeholder="BIC"  [(ngModel)]="currentAddress.bic" (ngModelChange)="modelChangeFn($event)">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Ust-IdNr.</mat-label>
      <input class="formControl" placeholder="Ust-IdNr."  [(ngModel)]="currentAddress.ust_nr" (ngModelChange)="modelChangeFn($event)">
    </div>
  </div>
</div>