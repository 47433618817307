<div class='BTN' (click)='tapped()'>
  <div class="hit-item" #_hitItem  (mouseover)='mouseover()' (mouseout)='mouseout()'></div>
  <div class="btn-active-background" [class.btn-active-background-active]='_isActive'></div>
  <div class="btn-holder" [class.button-holder-active]='_isActive'>
    <div class="item" [class.item-sub]='hasSubMenu'>
      <div class="bg" [style.background-image]="'url(' + icon + ')'" ></div>
      <div class="item-copy tlc">{{btnTitle}}</div>
    </div>
    <div class="underLineAnimated pl0 pr0" #_underLineAnimated></div>
    
    
  
  </div>
  
</div>


