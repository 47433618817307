<div style="height: 100%; overflow: hidden;">

  <mat-toolbar class="overlay-toolbar">
    Benutzer zum Bereich hinzufügen
    <span class="toolbar-spacer"></span>
    <mat-icon class="mediumIconButton"  (click)='close()'>close</mat-icon>
  </mat-toolbar>


  <div class="d-flex flex-column" style="height: calc(100% - 88px); overflow-y: scroll;">

    <div class="p-2">
      <mat-form-field class="textfield100 select-form-field" appearance="outline">
        <input matInput placeholder="Benutzer suchen" (keyup)="applyFilter($event.target)">
        <mat-icon matSuffix>search</mat-icon>
      </mat-form-field>
    </div>
    <table mat-table [dataSource]="poolUserDataSource" matSort class="mat-elevation-z0" style="width: 100%;" >
      <ng-container matColumnDef="email">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl10 ">Email</th>
        <td mat-cell *matCellDef="let element" class="pl10 " style="min-width:200px;max-width:350px">{{element?.email}}</td>
       </ng-container>
       <ng-container matColumnDef="lastName">
         <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl10 ">Name</th>
         <td mat-cell *matCellDef="let element" class="pl10 " style="min-width:200px;max-width:350px">{{element?.lastName}}</td>
        </ng-container>
      <ng-container matColumnDef="firstName">
        <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl10 ">Vorname</th>
        <td mat-cell *matCellDef="let element" class="pl10 " style="min-width:200px;max-width:350px" >{{element?.firstName}}</td>
       </ng-container>


       <ng-container matColumnDef="action" class="pr0">
        <th mat-header-cell *matHeaderCellDef  class="pr0"></th>
        <td mat-cell *matCellDef="let element" class="pr0 pl10">
          <mat-checkbox *ngIf="!element.isInPool" [(ngModel)]="element.selected"
          ></mat-checkbox>
          <!-- (change)="selectionChanged($event, element)" -->
          <mat-icon *ngIf="element.isInPool" matTooltip="{{element.isInPool}}">info</mat-icon>
        </td>
       </ng-container>

       <tr mat-header-row *matHeaderRowDef="poolUsersColumns;  sticky: true"></tr>
       <tr mat-row class="element-row" *matRowDef="let row; columns: poolUsersColumns; let i = index;"
        [class.trGray]="(i % 2) === 1" ></tr>
    </table>

    <!-- <ng-template ngFor let-item [ngForOf]="availableUsers" >

      <div class="p-2 d-flex">
        <div class="p-2" >{{item.email}}</div>
        <div class="p-2 ms-auto">
          <mat-checkbox *ngIf="!item.isInPool"
          (change)="selectionChanged($event, item)"></mat-checkbox>
          <mat-icon *ngIf="item.isInPool">info</mat-icon>
        </div>
      </div>

    </ng-template> -->

  </div>


  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <dxfx-df-icon-button
    title="Benutzer übernehmen" icon="save"
   (clicked)="save()" ></dxfx-df-icon-button>
  </mat-toolbar>

</div>
