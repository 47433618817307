import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AlertActionI, AlertI } from 'src/app/core/models/alert/ialert.model';

@Component({
  selector: 'dxfx-alert-overlay',
  templateUrl: './alert-overlay.component.html',
  styleUrls: ['./alert-overlay.component.scss']
})
export class AlertOverlayComponent implements OnInit {

  title = ""
  constructor(public dialogRef: MatDialogRef<AlertOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AlertI) {
      this.title = data.title
    }


  ngOnInit(): void {
    console.log('AlertOverlayComponent INIT');

  }

  tapped(action:AlertActionI.BUTTON_ACTION_TYPE){
    console.log(' TAPPED ', action)
     this.dialogRef.close(action)
  }

  colorForActionColorType(type :AlertActionI.BUTTON_COLOR_TYPE): string {
    switch(type) {
      case AlertActionI.BUTTON_COLOR_TYPE.BASIC:
        return 'basic';
      case AlertActionI.BUTTON_COLOR_TYPE.PRIMARY:
          return 'primary';
      case AlertActionI.BUTTON_COLOR_TYPE.ACCENT:
          return 'accent';
      case AlertActionI.BUTTON_COLOR_TYPE.WARN:
          return 'warn';
      default:
        return 'basic'
    }
  }
}
