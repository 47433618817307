import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cgDate'
})
export class CgdatePipe implements PipeTransform {

  transform(value: any): string {

    let date:Date = new Date(value);
    let MM:number = date.getMonth() + 1;
    let DAY:number = date.getDate();
    
    let _d:string = DAY < 10 ? '0'+DAY.toString() : DAY.toString();
    let _m:string = MM < 10 ? '0'+MM.toString() : MM.toString();
    return _d +'.'+ _m  +'.'+ date.getFullYear().toString();    
  }

}
