import { PadnextFachGebietUser } from "./role.model";
import { ManagedAccountI_Fachgebiete } from "./user-clinic-select-overlay-data.model";
import { APP_ACCESS_ENUM } from "./user.states.model";

export interface DocAccount {
  uid: string;
  // managedBy:string;
  email: string;
  docName: string;
  disabled?: boolean;
  doc_description?: string;
  status?: string;
  managerEmail?: string;
  roles: number[];
  templateAccess?: number[];
  countManagedUsers?: number;
  template_filter_ids: number[];
}
export interface DocAccountRaw {
  uid: string;
  managedBy: string;
  email: string;
  docName: string;
  // klinikName?:string;
  doc_description?: string;
  status?: string;
  managerEmail?: string;
  role_id: number;
  template_filter_type_id: number;
}
export interface TemplateFilterAccess {
  id: number;
  filter_type: TemplateFilterType;
  color: string;
  title: string;
}

export enum TemplateFilterType {
  UNPUBLISHED_PUBLIC_SCS = "UNPUBLISHED_PUBLIC_SCS",
  UNPUBLISHED_PUBLIC_DOXFOX = "UNPUBLISHED_PUBLIC_DOXFOX",
  PUBLISHED_PUBLIC_DOXFOX = "PUBLISHED_PUBLIC_DOXFOX",
  PUBLISHED_PUBLIC_SCS = "PUBLISHED_PUBLIC_SCS",
  USER_OWN_DOXFOX = "USER_OWN_DOXFOX",
  USER_OWN_SCS = "USER_OWN_SCS",
}

export interface UserI {
  uid: string;
  title?: string;
  medical_specialty_designation?: string;
  firstName: string;
  lastName: string;
  email: string;
  phone?: string;
  managedBy?: string;
  roles: number[];
  subscription_plan_id?: number;
  user_access_type_id: number;
  app_access_type_enum: USER_APP_ACCESS_TYPE;//string;
  accountType: ACCOUNT_TYPE_BASE;
  activity?: UserIActivity;
  activityDate?: number
  fachgebietPadNext?:PadnextFachGebietUser
  // state_view_value?: string;
  // state_enum?: USER_STATE_ENUM;
  
  
  docName?: string;
  
  state_view_value?: string;
  state_enum?: USER_STATE_ENUM;
  num_processes?: number;
  
  num_invoices?: number;
  fachgebiete?: ManagedAccountI_Fachgebiete[];
  num_reports?: number;
  lastLogin?: number;
}

export enum USER_APP_ACCESS_TYPE {
  ACTIVE = 'ACTIVE',
  BLOCKED = 'BLOCKED',
  NEED_TO_BE_CHECKED = 'NEED_TO_BE_CHECKED',
}

export interface UserIActivity {
  clientIp?: string;
  dateTime: number;
  remoteAddress?: string;
  logType: string;
}
// hastPatientEncryptions:boolean
// inviteOrRecoveryPassword:boolean
// user_access_type_id: number
// app_access_type_enum: APP_ACCESS_ENUM
// access_type_view_value:string
// template_pool_ids?:number[]
// logs?:IUserLog[]
// state_view_value?:string;
// state_enum?:USER_STATE_ENUM
// disabled?:boolean
// roles:number[];
// countManagedUsers?:number
// template_filter_ids:number[];
// base_role_key:number
// roleTitle:string
// roleKey:number
// hasAccess:boolean

export enum USER_STATE_ENUM {
  INVITED = "INVITED",
  REGISTERED = "REGISTERED",
  BLOCKED_BY_ADMIN = "BLOCKED_BY_ADMIN",
}
export interface IUserLog {
  dateTime: number;
  logType: string;
  remoteAddress?: string;
  clientIp?: string;
  logMessage?: string;
}

export enum ACCOUNT_TYPE_BASE {
  BASIC = "BASIC",
  CLINIC = "CLINIC",
}
