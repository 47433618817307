import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from "src/environments/environment";
import { API_CALL_CLINIC } from './models/clinic.call.enum';




@Injectable({
  providedIn: 'root'
})
export class ClinicApi {

  constructor(private http:HttpClient) { }

  async get(call:API_CALL_CLINIC){
    const _d = await this.http.get(API_REQUESTS(call)).pipe();
    return _d
  }

  async getP(call:API_CALL_CLINIC, id:string){
    const _d = await this.http.get(API_REQUESTS(call,id)).toPromise()
    return _d
  }
  async post(path:string, payload:Object){
    return await this.http.post(path,payload).toPromise()
  }
  async put(path:string, payload:Object){
    return await this.http.put(path,payload).subscribe(
      _data => {
        return _data
      }
    )
  }

}





export function API_REQUESTS(call:API_CALL_CLINIC, params?:string) {
  switch(call) {

    case API_CALL_CLINIC.GET_ALL:
      return `${environment.endpoints.apiBasePathV2}admin/clinic/`;

    case API_CALL_CLINIC.GET_SINGLE:
        return `${environment.endpoints.apiBasePathV2}admin/clinic/single/${params}`;

    case API_CALL_CLINIC.GET_REMOVE_CLINIC_FULL:
        return `${environment.endpoints.apiBasePathV2}admin/clinic/remove-full/${params}`;

    case API_CALL_CLINIC.CREATE:
      return `${environment.endpoints.apiBasePathV2}admin/clinic/`;

    case API_CALL_CLINIC.POST_CREATE_CLINIC_ADMIN:
      return `${environment.endpoints.apiBasePathV2}admin/clinic/clinic-admin`;
    
    case API_CALL_CLINIC.GET_CLINIC_ADMINS:
      return `${environment.endpoints.apiBasePathV2}admin/clinic/admins/${params}`;

    case API_CALL_CLINIC.UPDATE:
      return `${environment.endpoints.apiBasePathV2}admin/clinic/update/${params}`;

    case API_CALL_CLINIC.ADDRESSES_BY_POOL_ID:
       return `${environment.endpoints.apiBasePathV2}admin/clinic/addresses/${params}`;

    case API_CALL_CLINIC.CREATE_ADDRESS:
        return `${environment.endpoints.apiBasePathV2}admin/clinic/create-address-by-pool`;

    case API_CALL_CLINIC.UPDATE_ADDRESS_BY_ID:
      return `${environment.endpoints.apiBasePathV2}admin/clinic/address-update/${params}`;

    case API_CALL_CLINIC.REMOVE_ADDRESS_BY_ID:
      return `${environment.endpoints.apiBasePathV2}admin/clinic/delete-address/${params}`;

    case API_CALL_CLINIC.GET_CLINIC_SECTORS_BY_CLINIC_ID:
      return `${environment.endpoints.apiBasePathV2}admin/clinic/sector/${params}`;

    // case API_CALL_CLINIC.GET_ACCOUNTING_BY_CLINIC_ID:
    //   return `${environment.endpoints.apiBasePathV2}admin/clinic/sector/accounting/${params}`;

    case API_CALL_CLINIC.UPDATE_ITEM_BY_ID:
    return `${environment.endpoints.apiBasePathV2}admin/clinic/update-item/${params}`;

    case API_CALL_CLINIC.UPDATE_SECTOR_ITEM_BY_ID:
    return `${environment.endpoints.apiBasePathV2}admin/clinic/sector/update-sector-item/${params}`;

    case API_CALL_CLINIC.POST_DELETE_SECTOR:
        return `${environment.endpoints.apiBasePathV2}admin/clinic/sector/delete-sector`;

    case API_CALL_CLINIC.POST_CREATE_SECTOR:
        return `${environment.endpoints.apiBasePathV2}admin/clinic/sector/create/`;
    
    case API_CALL_CLINIC.CREATE_ACCOUNTING:
        return `${environment.endpoints.apiBasePathV2}admin/clinic/accounting/create/`;


  }

}
