import { Injectable } from '@angular/core';
import { IClinic } from '../core/models/clinic.model';
import { API_REQUESTS, ClinicApi } from '../core/clinic.api';
import { API_CALL_CLINIC } from '../core/models/clinic.call.enum';
import { IClinicSector } from '../core/models/clinic.sector.model';
import { IClinicAdmin } from '../core/models/poolUser.model';

@Injectable({
  providedIn: 'root'
})
export class ClinicService {

  private _clinics: IClinic[] = [];
  private  _sectors:IClinicSector[] = []
  private _currentClinic?: IClinic

  private _clinicAdmins: IClinicAdmin[] = []
  // private _clinicAccountingPoolsFlat: IAccountingPoolFlat[] = []
  private _clinicRetryCount = 0

  constructor(private cApi: ClinicApi) { 
    this.loadClinics()
  }

  get currentClinic(): IClinic | undefined {
    return this._currentClinic
  }
  set currentClinic(value) {
    this._currentClinic = value
  }

  get clinics(): IClinic[] {
    return this._clinics
  }
  get clinicAdmins(): IClinicAdmin[] {
    return this._clinicAdmins
  }

  get sectors(): IClinicSector[] {
    return this._sectors
    if (this._currentClinic) {
      return this._sectors//.filter(item => item.clinic_id == this._currentClinic!.id)
    } else {
      return []
    }
    
  }

  // get clinicAccountingPoolsFlat(): IAccountingPoolFlat[] {
  //   return this._clinicAccountingPoolsFlat
  // }
  async updateClinicItem(id:string, payload:object) {
    const _path = API_REQUESTS(API_CALL_CLINIC.UPDATE_ITEM_BY_ID, id.toString())
    await this.cApi.post(_path, { dto: payload }).then(
      _data => {
        this._clinics = _data as IClinic[]
      }
    )
  }

  async createClinic(clinic:IClinic){
    await this.cApi.put(API_REQUESTS(API_CALL_CLINIC.CREATE), {dto: clinic}).then(
      _data => {
        this.loadClinics()
      }
    )
  }

  async loadClinics() {
    (await this.cApi.get(API_CALL_CLINIC.GET_ALL)).pipe().subscribe(
      _data => {
        this._clinics = _data as IClinic[]
        console.log('clinics', this._clinics);
      }
    )
  }

  private async loadSingle(id:string) {
    const _resiult = await this.cApi.getP(API_CALL_CLINIC.GET_SINGLE,id)
    return _resiult
    // ).pipe().subscribe(
    //   _data => {
    //     this._clinics = _data as IClinic[]
    //     console.log('clinics', this._clinics);
    //   }
    // )
  }

  async updateClinic(){
    
    if(this._currentClinic) {
      await this.cApi.post(API_REQUESTS(API_CALL_CLINIC.UPDATE, this._currentClinic.id.toString()), {dto: this._currentClinic} )
      .then(
        _data => {
          // this.loadClinics()
          setTimeout(()=>{
            this.loadClinics()
          },100)
        }
      )
    }
  }
  


  async setCurrentClinic(clinicId: string) {
    const index = this._clinics.findIndex(item => item.id == clinicId)
    if(index != -1 ){
      this._currentClinic = this._clinics[index]
      this.loadSectors(clinicId)
      this.loadClinicAdmins(clinicId)

      // this.accountingPoolsFlat(clinicId)
    } else {
      const r = await this.loadSingle(clinicId)
      if (r) {
        this._currentClinic = r as IClinic 
        this.loadSectors(clinicId)
        this.loadClinicAdmins(clinicId)
        // this.accountingPoolsFlat(clinicId)
      }
    }
     
  }



  async loadSectors(clinicId: string) {
    
    const _result = (await this.cApi.getP(API_CALL_CLINIC.GET_CLINIC_SECTORS_BY_CLINIC_ID, clinicId))
    this._sectors = _result as IClinicSector[]
  }

 async loadClinicAdmins(clinicId: string) {
    
    const _result = (await this.cApi.getP(API_CALL_CLINIC.GET_CLINIC_ADMINS, clinicId))
    this._clinicAdmins = _result as IClinicAdmin[]
    console.log( this._clinicAdmins)
  
  }
 async createClinicAdmin(clinicId: string, o:IClinicAdmin) {
  await this.cApi.post(API_REQUESTS(API_CALL_CLINIC.POST_CREATE_CLINIC_ADMIN), {dto:o})
    // const _result = (await this.cApi.getP(API_CALL_CLINIC.GET_CLINIC_ADMINS, clinicId))
    // this._clinicAdmins = _result as IClinicAdmin[]
    this.loadClinicAdmins(clinicId)
  
  }
  // private async accountingPoolsFlat(clinicId: string) {
  //   const _result = (await this.cApi.getP(API_CALL_CLINIC.GET_ACCOUNTING_POOLS_FLAT, clinicId))
  //   this._clinicAccountingPoolsFlat = _result as IAccountingPoolFlat[]
  //   console.log(this._clinicAccountingPoolsFlat)
  // }

  // async createAccountingForClinic(cId: string, description: string) {
  //   const r = await this.cApi.post(API_REQUESTS(API_CALL_CLINIC.CREATE_ACCOUNTING),{clinicId: cId, description: description})
  //   this._clinicAccountingPoolsFlat = r as IAccountingPoolFlat[]
  // }

  // async accountingPoolFull(accounting_pool_id:number) {
  //   const _result = (await this.cApi.getP(API_CALL_CLINIC.GET_SINGLE_ACCOUNTING_POOL_FULL, accounting_pool_id.toString()))
  //   // this._clinicAccountingPoolsFlat = _result as IAccountingPoolFlat[]
  //   console.log(_result)
  // }


   async removeFull(id:string) {
    const _resiult = await this.cApi.getP(API_CALL_CLINIC.GET_REMOVE_CLINIC_FULL,id)
    return _resiult
    
  }
}
