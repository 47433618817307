import { ACCOUNT_TYPE_BASE, USER_STATE_ENUM, UserI } from "./docAccount";
import { IPoolUser } from "./poolUser.model";

export interface USER_CLINIC_SELECT_DATA {
  selectedClinicId: number;
}

export interface ADD_USER_TO_POOL_OVERLAY_DATA {
  selected_pool_id: string;
  // includedUsers:IPoolUser[]
  alreadyIncluded: IPoolUser[];
}

export interface AreaOfExpertise {
  id: number;
  fachgebiet_id?: number;
  name: string;
  short: string;
  is_active: boolean;
}

export interface AreaOfExpertiseUserI {
  id: number;
  name: string;
  fachgebiet_id?: number;
  is_primary: boolean;
  is_active: boolean;
  short: string;
}
export interface SettingsUsersOvlerayDataI {
  mode: SETTINGS_USER_OVERLAY_MODE;
  // managedAccount: ManagedAccountI;
  user: UserI;
}

export enum SETTINGS_USER_OVERLAY_MODE {
  INVITE_MODE = "INVITE_MODE",
  EDIT_MODE = "EDIT_MODE",
}
export interface ManagedAccountI {
  uid?: string;
  title?: string;
  medical_specialty_designation?: string;
  firstName?: string;
  lastName?: string;
  docName?: string;
  email: string;
  phone?: string;
  accountType?: ACCOUNT_TYPE_BASE;
  state_view_value?: string;
  state_enum?: USER_STATE_ENUM;
  num_processes?: number;
  subscription_plan_id?: number;
  num_invoices?: number;
  fachgebiete: ManagedAccountI_Fachgebiete[];
  num_reports?: number;
  lastLogin?: number;
}
export interface ManagedAccountI_Fachgebiete {
  fachgebiet_id?: number;
  is_primary?: boolean;
  name: string;
  short: string;
}

export interface InviteUserOverlayData {
  mode: InviteUserOverlayDataMODE;
  poolId?: string;
  clinicId?: string;
}

export enum InviteUserOverlayDataMODE {
  SIMPLE_INVITE,
  INVITE_AND_ADD_TO_POOL,
}

export interface AccountingOverlayData {
  mode: AccuntingOverlayMode;
  clinic_id: string;
  accounting_pool_id?: number;
}

export enum AccuntingOverlayMode {
  NONE,
  CREATE_ACCOUNTING,
  ACCOUNTING_DETAIL,
  CREATE_CLINIC_ADMIN,
}
