import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { INVITE_RESPONSE, INVITE_RESPONSE_MESSAGE, INVITE_RESPONSE_STATUS, INVITE_STEP } from 'src/app/core/models/inviewUserStep.enum';
import { POOL_CALL } from 'src/app/core/models/pool.call.enum';
import {  IPoolUserFlat } from 'src/app/core/models/poolUser.model';
import { ISubScriptionPLan, PadNextFachArztFull, PadNextFachArztItem } from 'src/app/core/models/role.model';
import { AreaOfExpertise, AreaOfExpertiseUserI, InviteUserOverlayData, InviteUserOverlayDataMODE, ManagedAccountI } from 'src/app/core/models/user-clinic-select-overlay-data.model';
import { UserApi, USER_API_CALL, USER_API_REQUESTS } from 'src/app/services/api.user.service';
import { PoolService } from 'src/app/services/pool.service';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';
import * as _ from 'lodash'
import { AuthService } from '../../services/auth.service';
import { ACCOUNT_TYPE_BASE, USER_APP_ACCESS_TYPE, UserI } from 'src/app/core/models/docAccount';
import { APP_ACCESS_ENUM } from 'src/app/core/models/user.states.model';
interface InviteRole {
  name: string
  role_id: number
}
@Component({
  selector: 'dxfx-invite-user-overlay',
  templateUrl: './invite-user-overlay.component.html',
  styleUrls: ['./invite-user-overlay.component.scss']
})
export class InviteUserOverlayComponent implements OnInit{

  private _allPoolUsers: IPoolUserFlat[] = []
  INVITE_STEPS = INVITE_STEP
  windowTitle = ""

  emailError?: string
  currentStep:INVITE_STEP = INVITE_STEP.LOADING

  areaOfExpertiseUser:AreaOfExpertiseUserI[] = [];
  areaOfExpertisAll:AreaOfExpertise[] = [];

  overlayData?:InviteUserOverlayData
  olModes = InviteUserOverlayDataMODE

  inviteRoles: InviteRole[] = [
    {name: 'Abo Account', role_id: 12},
    {name: '30 Tage Demo', role_id: 14},
    {name: 'Test-User intern', role_id: 15},
  ]
  // accountRole: InviteRole | undefined
  accountRole = 12//: InviteRole | undefined
  newAccount:UserI = {
    email: 'ce@artificialrome.com',
    // email: '',
    firstName: 'Christoph',
    lastName: 'Espig',
    title: 'Dr.',
    medical_specialty_designation: '',
    fachgebiete: [],
    subscription_plan_id: 1,
    uid: '',
    roles: [],
    user_access_type_id: 0,
    app_access_type_enum: USER_APP_ACCESS_TYPE.ACTIVE,//string;
    accountType: ACCOUNT_TYPE_BASE.BASIC
  }


  

  constructor(
    public ui:UiService,
    public us:UserService,
    private auth: AuthService,
    private userApi:UserApi,
    private poolApi: PoolService,
    private dialogRef:MatDialogRef<InviteUserOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: InviteUserOverlayData

    ) {
      this.overlayData = data
    }

  ngOnInit(): void {
    console.log('InviteUserOverlayComponent INIT');
    this.loadUsers()
    this.loadData()
    this.accountRole = this.inviteRoles[0].role_id
    this.newAccount.subscription_plan_id = this.overlayData?.mode == InviteUserOverlayDataMODE.SIMPLE_INVITE ? 1 : 4
    // const index = this.ui.subscriptionPlan.findIndex(item => item.id == this.data.subscription_plan_id)
    // if(index != -1) {
    //     this.currenId = this.ui.subscriptionPlan[index].id
    //     this.currentPlan = this.ui.subscriptionPlan[index].name
    // }
  }

  close(){
    this.dialogRef.close()
  }
  get isUserAdmin(): boolean {
    return this.auth.NAVIGATION.includes('USER_DELETE')
  }
  emailChanged(e:any){
    this.emailError = undefined
  }
  accountRoleChange(id:number){
    this.accountRole = this.accountRole ==  id ? 0 : id
    console.log(this.accountRole);
    
  }

  onPlanSelect(e: any) {
    console.log(e)
  }
  setStep(step:INVITE_STEP) {
    switch (step) {
      case INVITE_STEP.EMAIL:
        this.currentStep = INVITE_STEP.LOADING
        this.validateEmail()
        return
      case INVITE_STEP.COMPLETE:
        this.currentStep = INVITE_STEP.LOADING
        this.apiInviteAccount()
        return
    }
  }

  private async validateEmail() {
    if(this.newAccount.email == "") {
      this.emailError = "Bitte und so..."
      this.currentStep = INVITE_STEP.EMAIL
      return
    } else  if(!this.newAccount.email.includes("@")) {
      this.emailError = "Bitte @ und so..."
      this.currentStep = INVITE_STEP.EMAIL
      return
    } else {
     
      await this.checkIfEmailIsInDB(this.newAccount.email)
      
      // const _index = this._allPoolUsers.findIndex(item => item.email == this.newAccount.email)
      // if(_index != -1 ){
      //   this.emailError = `Diese Email existiert in ${this._allPoolUsers[_index].title}`
      //   this.currentStep = INVITE_STEP.EMAIL
      //   return
      // } else {

      //   const _index2 = this.us.users.findIndex(item => item.email == this.newAccount.email)
      //   if(_index2 != -1 ){
      //     this.emailError = `Diese Email existiert und so...`
      //     this.currentStep = INVITE_STEP.EMAIL
      //     return
      //   } else {
      //     this.currentStep = INVITE_STEP.USER_DATA
      //     return
      //   }

      // }

    }
  }

  private async checkIfEmailIsInDB(email: string) {
    const _result = await this.userApi.post(USER_API_REQUESTS(USER_API_CALL.CHECK_EMAIL_EXISTS),{email:email})

    const _response = _result as INVITE_RESPONSE


    switch(_response.status) {
      case INVITE_RESPONSE_STATUS.ERROR:
        this.ui.customSnack.emit({
          panelClass: "customSnack",
          duration: 5000,
          message: 'Error'
        })
      return
      case INVITE_RESPONSE_STATUS.SUCCESS:
        
        if(_response.message == INVITE_RESPONSE_MESSAGE.USER_EXISTS) {
          // this.ui.snack.emit("Diese Email Addresse ist bereits registriert")
          this.ui.customSnack.emit({
            panelClass: "customSnack",
            duration: 5000,
            message: 'Diese Email Addresse ist bereits registriert'
          })
          this.currentStep = INVITE_STEP.EMAIL
          return
        } else {
          this.currentStep = INVITE_STEP.USER_DATA
          return
        }
      return
    }
  }

  get canChangedStep(): boolean {
    switch (this.currentStep) {
      case INVITE_STEP.EMAIL:
      return this.newAccount.email == ""
      default:
      return false
    }
  }

  get isEmailVerified(): boolean {
    return this.newAccount.email != ""
  }

  get subscriptionPlan() :ISubScriptionPLan[] {
    const allowdIds = this.overlayData?.mode == InviteUserOverlayDataMODE.SIMPLE_INVITE ? [1,2,3] : [4,5]
    return this.ui.subscriptionPlan.filter(item => allowdIds.includes(item.id))
  }

  private async loadUsers() {
    await this.poolApi.get(POOL_CALL.GET_POOL_USERS_ALL).then(
      _response => {
        this._allPoolUsers = _response as IPoolUserFlat[]
        setTimeout(()=>{
          console.log('this._allPoolUsers', this._allPoolUsers)
          this.currentStep = INVITE_STEP.EMAIL
        },20)
        // this.fixUsers();
      }
    )
  }



  private async apiInviteAccount() {


    var fgt = []
    var iRoles = [5,12]
    if(this.accountRole != 0) {
      iRoles.push(this.accountRole)
    }
    // this.areaOfExpertiseUser.sort((a,b) => a.is_primary - b.is_primary)
    fgt = _.map(_.filter(this.areaOfExpertiseUser, item => item.is_active), 'id')
    const _activeFachgebiet = this.areaOfExpertiseUser.find(item => item.is_primary)
    console.log(fgt)
    console.log(_activeFachgebiet)

    
    
    if ( !this.newAccount.firstName || !this.newAccount.lastName || this.newAccount.email == "") {
      alert("miss")
      return
    }

    let _inviteDto = {
      title: "",
      medical_specialty_designation: "",
      lastName: "",
      firstName: "",
      email: "",
      phone: "",
      pool_id: "-1",
      clinic_id: "-1",
      subscription_plan_id: this.newAccount.subscription_plan_id,
      useAccounting: false,
      facharzt_pad_next_fachgebiete_id: 45
    }

    _inviteDto.facharzt_pad_next_fachgebiete_id = this.padNextFachArztBereichSelected ? this.padNextFachArztBereichSelected.id : 45
    _inviteDto.title = this.newAccount.title ? this.newAccount.title : ""
    _inviteDto.medical_specialty_designation = this.newAccount.medical_specialty_designation ? this.newAccount.medical_specialty_designation : ""
    _inviteDto.firstName = this.newAccount.firstName!
    _inviteDto.lastName = this.newAccount.lastName!
    _inviteDto.phone = this.newAccount.phone ? this.newAccount.phone : ""
    _inviteDto.email = this.newAccount.email!
    _inviteDto.useAccounting = this.newAccount.subscription_plan_id == 2 || this.newAccount.subscription_plan_id == 3 || this.newAccount.subscription_plan_id == 5 ? true : false
   
    // // _inviteDto.subscription_plan_id =  this.overlayData && this.overlayData!.mode == InviteUserOverlayDataMODE.SIMPLE_INVITE ? 1 : 4


    Object.assign(_inviteDto, {rolesIds: iRoles})
    Object.assign(_inviteDto, {fachgebiete: fgt})
    Object.assign(_inviteDto, {activeFachgebiet: _activeFachgebiet?.id})

    console.log(_inviteDto);
    
    if(this.overlayData && this.overlayData.mode == InviteUserOverlayDataMODE.INVITE_AND_ADD_TO_POOL) {
      if(this.overlayData.poolId && this.overlayData.clinicId) {
        _inviteDto.pool_id = this.overlayData.poolId
        _inviteDto.clinic_id = this.overlayData.clinicId

        let _path = USER_API_REQUESTS(USER_API_CALL.POST_INVITE_NEW_USER_AND_ADD_TO_POOL)
        await this.userApi.post(_path, {dto: _inviteDto})
        setTimeout(() => {
          this.close()
        },250)
      } else {

        // TODO FIRE ERRO
      }

    } else {
     await this.userApi.post(USER_API_REQUESTS(USER_API_CALL.INVITE_NEW_USER), {dto: _inviteDto})
      setTimeout(() => {
        this.close()
      },250)
    }


  }

  isPrimaryNewUserExpertise(allItem:AreaOfExpertise) : boolean {
    // const _index = this.newAccount.fachgebiete.findIndex(item => item.fachgebiet_id == allItem.id)
    // if(_index != -1){
    //   return this.newAccount.fachgebiete[_index].is_primary == true ? true : false
    // } else {
    //   return false
    // }
    const _index = this.newAccount.fachgebiete?.findIndex(item => item.fachgebiet_id == allItem.id)
    if(_index != -1){
      return this.newAccount.fachgebiete![_index!].is_primary ? true : false
    } else {
      return false
    }
  }

  get newAccountHasAnyExpertise() : boolean {
    // return this.newAccount.fachgebiete.length > 0
    return this.areaOfExpertiseUser.findIndex(item =>  { return (item.is_active == true)}) != -1
  }

  userHasExpertise(allItem:AreaOfExpertise) : boolean {
    return this.areaOfExpertiseUser.findIndex(item => item.fachgebiet_id == allItem.id) != -1
  }
  
  newUserHasExpertise(allItem:AreaOfExpertise) : boolean {
    return this.newAccount.fachgebiete?.findIndex(item => item.fachgebiet_id == allItem.id) != -1
  }


  togglePrimaryForNewInvite2(pValue: AreaOfExpertiseUserI) {
    console.log(pValue);
    
    if(pValue.is_primary) {
      pValue.is_primary = false
      const index = this.areaOfExpertiseUser.findIndex(item =>  { return (item.is_active == true && item.id != pValue.id)})
      console.log(`index: ${index}`, this.areaOfExpertiseUser)
      if(index != -1) {
        this.areaOfExpertiseUser[index].is_primary = true
      }
    } else {
      for (const iterator of this.areaOfExpertiseUser) {
        iterator.is_primary = false
      }
      setTimeout(()=>{
        pValue.is_primary = true
        pValue.is_active = true
      },25)
    }
  }
  toggleActivityForNewInvite2(pValue: AreaOfExpertiseUserI) {
    console.log(pValue);
    
    if(pValue.is_active) {
      pValue.is_active = false
      if(pValue.is_primary) {
        pValue.is_primary = false
        const index = this.areaOfExpertiseUser.findIndex(item =>  { return (item.is_active == true && item.id != pValue.id)})
        console.log(`index: ${index}`, this.areaOfExpertiseUser)
        if(index != -1) {
          this.areaOfExpertiseUser[index].is_primary = true
        }
      }
    } else {
      pValue.is_active = true
      
    }
  }


  private async loadData(){
    const _data2:any = await this.userApi.get(USER_API_CALL.GET_AREAS_OF_EXPERTISE_ADMIN)

    this.areaOfExpertisAll = await this.userApi.loadAreOfExpertise()

    let index = 0
    this.areaOfExpertisAll.forEach(item => {

      this.areaOfExpertiseUser.push({
        // fachgebiet_id: item.fachgebiet_id,
        id: item.id,
        is_active: true,
        is_primary: index == 0,
        name: item.name,
        short: item.short
      })
      
      this.newAccount.fachgebiete!.push({
        fachgebiet_id: item.id,
        // id: -1,
        // is_active: true,
        is_primary: index == 0 ? true : false,
        name: item.name,
        short: item.short
      })
      index ++
    })
  }

  get inviteButtonEnabled():boolean {
    if(!this.newAccount){
      return false
    }
    if(this.newAccount.lastName != '' && this.newAccount.lastName!.length > 1
      && this.newAccount.firstName != '' && this.newAccount.firstName!.length > 1
      && this.newAccount.email != '' && this.newAccount.email.length > 2
      && this.newAccount.fachgebiete!.length > 0
      ) {
        return true
      }
    return false
    //return this.newAccount.doc_name.length > 3 && this.newAccount.email.length > 5 && (this.doxFoxAccess || this.scsAccess)
  }

  fachbereichCHanged(e:any) {
    console.log(e.value);
  }
  async fachArztCHanged(e:PadNextFachArztItem) {
    console.log(e);
    this.padNextFachArztBereichSelected = e
    this.newAccount.medical_specialty_designation = e.title
    setTimeout(()=>{
      console.log(this.padNextFachArztBereichSelected);
    },1000)
    
    // if(this.padNextFachArztBereichSelected){
    //   const _path2 = USER_API_REQUESTS(
    //     USER_API_CALL.POST_USER_FACHGEBIET_PAD
    //   );
    //   await this.userApi.post(_path2, { payload: {
    //     uid: this.currentAccount.uid,
    //     facharzt_pad_next_fachgebiete_id: this.padNextFachArztBereichSelected?.id
    //   } });
    // }
  }
  padNextFachBereichSelected: PadNextFachArztFull | undefined
  padNextFachArztBereichSelected: PadNextFachArztItem | undefined
  get padNextItemsSelected(): PadNextFachArztItem[] {
    if(this.padNextFachBereichSelected) {
      return this.padNextFachBereichSelected.items
    }
    return []
  }
  get padNextFachFull(): PadNextFachArztFull[] {
   return this.ui.padNextFachFull
  }
}
