import { Component, OnInit, Input, ElementRef, ViewChild } from '@angular/core';

import {gsap, Expo,Circ} from 'gsap';
import { Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import { MatDialog } from '@angular/material/dialog';


@Component({
  selector: 'dxfx-main-menu-item-hz',
  templateUrl: './main-menu-item-hz.component.html',
  styleUrls: ['./main-menu-item-hz.component.scss']
})
export class MainMenuItemHzComponent implements OnInit {
  @Input() btnTitle!:string;
  @Input() path!:string;
  @Input() icon!:string;
  @Input() hasSubMenu!:boolean;


  @ViewChild('_underLineAnimated', {static:true}) _underLineAnimated!:ElementRef;
  @ViewChild('_subMenuHolder', {static:false}) _subMenuHolder!:ElementRef;
  @ViewChild('_hitItem  ', {static:false}) _hitItem!:ElementRef;

  _isActive:boolean = false;
  _isMenuOpen:boolean =false;
  _showCopy :boolean =false;
  showSubItems:boolean =false;
  _isSubmenuOver:boolean =false;
  _isSubmenuOpen:boolean =false;
  closeTimeout:any
  constructor(private router:Router, private ui:UiService, private  dialog:MatDialog) { }

  ngOnInit(): void {
    this.ui.currentPage.subscribe(
      newPath => {
        if(newPath === this.path && !this._isActive){
          this._isActive = true;
          this.toggleBtn(true)
        } else if(newPath !== this.path && this._isActive ){
          this._isActive = false;
          this.toggleBtn(false)
        }
      }
    );
    // this.ui.hasNavigateTo.subscribe(
    //   route =>{
        
    //     if(route != this.path){
    //       console.log(`\nROUTE ${route}`);
    //       console.log(`path ${this.path}\n`);
    //       this.router.navigate([route]);
    //     }
    //   }
    // );
  /*   this.ui.isMenuOpen.subscribe(
      _val => {
        this._isMenuOpen = _val;
        // this.showCopy(_val);
      }
    ) */
  }
  showCopy(show:boolean){
    if(show){
      setTimeout(()=>{
        this._showCopy = true;
      },400)
    } else {
      this._showCopy = false;
    }

  }
  tapped(){
    if(this.path === 'user-role-info'){

    } else {
      if(!this._isActive) {
        this._isActive = true;
        this.toggleBtn(this._isActive)
      }

      // this.ui.navigate(this.path);
    }
     this.ui.navigate(this.path);

  }
  toggleBtn(show:boolean){
    if(show){
      this.hideUnderline()
      //TweenMax.fromTo(this._underLineAnimated.nativeElement, 0.45, { 'width':0, }, { 'width':'100%',  ease: Expo.easeOut});
    } else {
     // TweenMax.fromTo(this._underLineAnimated.nativeElement, 0.45, { 'width':'100%' }, { 'width':0,  ease: Expo.easeOut});
    }
  }
  showUnderline(){

    // TweenMax.fromTo(this._underLineAnimated.nativeElement, 0.45, { 'width':0, }, { 'width':'100%',  ease: Expo.easeOut});
    gsap.to(this._underLineAnimated.nativeElement, 0.5,
      // { 'opacity':'1', 'height': "0px"},
      { 'opacity':'1', 'height': "5px",ease: Expo.easeOut,onComplete:()=>{

      }});
      // { 'opacity':'0', 'height': "5px" , 'width':'50%', 'margin-left': '25%'},
      // { 'opacity':'1', 'height': "5px", 'width':'100%','margin-left': '0px',ease: Expo.easeOut});
  }
  hideUnderline(){

    // TweenMax.fromTo(this._underLineAnimated.nativeElement, 0.45, { 'width':'100%' }, { 'width':0,  ease: Expo.easeOut});
    gsap.to(this._underLineAnimated.nativeElement, 0.45,
      // { 'opacity':'1', 'height': "5px"},
      { 'opacity':'1', 'height': "0px",ease: Circ.easeOut,onComplete:()=>{

      }});
      // { 'opacity':'1', 'height': "5px" , 'width':'100%','margin-left': '0px'},
      // { 'opacity':'0', 'height': "5px", 'width':'50%', 'margin-left': '25%',ease: Power1.easeOut});
  }
  mouseover(){
    if(this.hasSubMenu === true ){
      this.showSub()
    } else {
      this.showUnderline()
    }
  }
  mouseout(){
    if(this.hasSubMenu === true ){
      this.hideSub()
    } else {
      this.hideUnderline()
    }

  }
  subMenuOver(){
    console.log('subMenuOver')
    if(this.hasSubMenu === true){
      this._isSubmenuOver = true
      this.showSub()
    }
  }
  subMenuOut(){
    if(this.hasSubMenu === true){
      this._isSubmenuOver = false
      this.hideSub()
    }
  }
  showSub(){
    if(this._isSubmenuOpen === false){
      this._isSubmenuOpen = true;
      TweenMax.fromTo(this._subMenuHolder.nativeElement, 0.45, { 'height':0, }, { 'height':'220px',  ease: Expo.easeOut, onComplete:()=>{
        this.showSubItems =true;
      }});
      //TweenMax.fromTo(this._hitItem.nativeElement, 0.65, { 'height':'64px', }, { 'height':'220px',  ease: Expo.easeOut});

    }

  }
  navigate(path:string){
    if(path === 'user-role-info'){

    } else {
      setTimeout(()=>{
        this.router.navigate(['verwaltung',path])
      },500)
    }
  }
  hideSub(){
    if(this.closeTimeout){
      clearTimeout(this.closeTimeout)
    }
    this.closeTimeout = setTimeout((() => {
      this._hideSub()
    }).bind(this), 50);
  }
  private _hideSub(){
    if(this._isSubmenuOpen === true && this._isSubmenuOver === false){
      this.showSubItems =false;
      //TweenMax.fromTo(this._hitItem.nativeElement, 0.65, { 'height':'220px', }, { 'height':'64px',  ease: Expo.easeOut});
      TweenMax.fromTo(this._subMenuHolder.nativeElement, 0.45, { 'height':'220px', }, { 'height':0,  ease: Expo.easeOut, onComplete:()=>{
        this._isSubmenuOpen =false;
      }});
    }
  }

}

