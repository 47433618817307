import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UserI } from 'src/app/core/models/docAccount';
import { PadNextFachArztFull, PadNextFachArztItem } from 'src/app/core/models/role.model';
import { USER_API_CALL, USER_API_REQUESTS, UserApi } from 'src/app/services/api.user.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'dxfx-padnext-fachgebiet-selection',
  template: `
   <div class="d-flex">
      <div class="p-2 w50p">
        <mat-label class='mat-caption'>Fachgebiet</mat-label>
        <mat-form-field appearance="outline" style="margin-top: -5px!important" class="select-form-field w100 ">
          <mat-select [(value)]="padNextFachBereichSelected" (selectionChange)='fachbereichCHanged($event)'>
            <mat-option *ngFor="let g of padNextFachFull" [value]="g">{{g.title}}</mat-option>      
          </mat-select>
        </mat-form-field>
      </div>
      <div class="p-2 w50p" *ngIf="padNextFachBereichSelected">
        <mat-label class='mat-caption'>Fach</mat-label>
        <mat-form-field  appearance="outline" style="margin-top: -5px!important" class="select-form-field w100 ">
          <mat-select [(value)]="padNextFachArztBereichSelected" (selectionChange)='fachArztCHanged($event)' >
            <mat-option *ngFor="let g of padNextItemsSelected" [value]="g">{{g.title}}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
  `,
  styleUrls: ['./padnext-fachgebiet-selection.component.scss']
})
export class PadnextFachgebietSelectionComponent implements OnInit {

  @Input() currentAccount!: UserI
  @Input() padNextFachBereichSelected?: PadNextFachArztFull | undefined
  @Input() padNextFachArztBereichSelected?: PadNextFachArztItem | undefined
  @Output() facharztSelected = new EventEmitter<PadNextFachArztItem>()
  constructor(public ui: UiService,
    private userApi: UserApi) { }

  ngOnInit(): void {
    console.log();
    
  }
  get padNextFachFull(): PadNextFachArztFull[] {
    return this.ui.padNextFachFull
   }
   fachbereichCHanged(e:any) {
    console.log(e.value);
  }
  async fachArztCHanged(e:any) {
    console.log(e.value);
    this.facharztSelected.emit(e.value)
    // if(this.padNextFachArztBereichSelected){
    //   const _path2 = USER_API_REQUESTS(
    //     USER_API_CALL.POST_USER_FACHGEBIET_PAD
    //   );
    //   await this.userApi.post(_path2, { payload: {
    //     uid: this.currentAccount.uid,
    //     facharzt_pad_next_fachgebiete_id: this.padNextFachArztBereichSelected?.id
    //   } });
    // }
  }
  // padNextFachBereichSelected: PadNextFachArztFull | undefined
  // padNextFachArztBereichSelected: PadNextFachArztItem | undefined
  get padNextItemsSelected(): PadNextFachArztItem[] {
    if(this.padNextFachBereichSelected) {
      return this.padNextFachBereichSelected.items
    }
    return []
  }
}
