import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { USER_APP_ACCESS_TYPE } from "src/app/core/models/docAccount";

import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";

import { UiService } from "src/app/services/ui.service";

import { UserService } from "src/app/services/user.service";

import { IUserFilterItem } from "src/app/core/models/role.model";

import { USER_SORT_MODE, USER_SORT_TYPE } from "src/app/core/models/enum.types";

@Component({
  selector: 'dxfx-user-list-header',
  template: `
    
      <dxfx-user-list-header-radio-group></dxfx-user-list-header-radio-group>
 
      <div class="d-flex _tableHeader align-items-center pl14  " style="font-size: 12px;">
        <div class="p-0 w50p m_t20 m_b10">
          <input class="formControl" placeholder="Suche..." [(ngModel)]="us.searchText" (keypress)="applyFilter($event.target)">
        </div>
      </div>
      
      
      <div class="d-flex pl14 align-items-center" style="font-size: 12px;  background-color:#DDECF2;">
       
        <div class="p-0 _bRight d-flex" style="width: 200px;">        
          <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort(SORTMODES.EMAIL)">Email</div>
          <div class="p-0 mt2 ml5">
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.EMAIL && us.userSortType === SORTYPES.ASC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.EMAIL && us.userSortType === SORTYPES.ASC">north</mat-icon>
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.EMAIL && us.userSortType === SORTYPES.DESC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.EMAIL && us.userSortType === SORTYPES.DESC">south</mat-icon>
          </div>
        </div>

        <!-- Nachname -->
        <div class="p-0 pl10 _bRight d-flex" style="width: 200px;">
          <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort(SORTMODES.LASTNAME)">Nachname</div>
          <div class="p-0 mt2 ml5">
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.LASTNAME && us.userSortType === SORTYPES.ASC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.LASTNAME && us.userSortType === SORTYPES.ASC">north</mat-icon>
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.LASTNAME && us.userSortType === SORTYPES.DESC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.LASTNAME && us.userSortType === SORTYPES.DESC">south</mat-icon>
          </div>
        </div>

        <!-- Vorname -->
        <div class="p-0 pl10 d-flex _bRight" style="width: 200px;">
          <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort(SORTMODES.FIRSTNAME)">Vorname</div>
          <div class="p-0 mt2 ml5">
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.FIRSTNAME && us.userSortType === SORTYPES.ASC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.FIRSTNAME && us.userSortType === SORTYPES.ASC">north</mat-icon>
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.FIRSTNAME && us.userSortType === SORTYPES.DESC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.FIRSTNAME && us.userSortType === SORTYPES.DESC">south</mat-icon>
          </div>
        </div>

        <div class="p-0 pl10 _bRight d-flex" style="width: 200px;">
          <div class="p-0 m_t5" >Fachbez.</div>
          <!-- style="cursor:pointer;" (click)="changeSort(SORTMODES.FIRSTNAME)" -->
          <div class="p-0 mt2 ml5">
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.FIRSTNAME && us.userSortType === SORTYPES.ASC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.FIRSTNAME && us.userSortType === SORTYPES.ASC">north</mat-icon>
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.FIRSTNAME && us.userSortType === SORTYPES.DESC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.FIRSTNAME && us.userSortType === SORTYPES.DESC">south</mat-icon>
          </div>
        </div>

        <div class="p-2 ms-auto _bRight text-center" style="width: 80px;">Klinik</div>

        <div class="p-2 _bRight text-center" style="width: 90px;"> Status</div>

        <div class="p-2 _bRight text-center" style="width: 50px;"> Plan</div>

        <div class="p-2 _bRight text-center d-flex" style="width: 130px;" *ngIf="!fullView"> 
          <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort(SORTMODES.LASTLOGIN)">LastLogin</div>
          <div class="p-0 mt2 ml5">
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.LASTLOGIN && us.userSortType === SORTYPES.ASC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.LASTLOGIN && us.userSortType === SORTYPES.ASC">north</mat-icon>
            <mat-icon class="tableFilterIcon" *ngIf="us.userSortMode === SORTMODES.LASTLOGIN && us.userSortType === SORTYPES.DESC"
              [class.tableFilterIconActive]="us.userSortMode === SORTMODES.LASTLOGIN && us.userSortType === SORTYPES.DESC">south</mat-icon>
          </div>
        </div>

        <div class="p-0 d-flex"  *ngIf="fullView">
          <div class="p-0 rotatedBox" *ngFor="let role of ui.baseUserRoles" >
            <div class="roatedbox-content">{{role.description}}</div>
          </div>
        </div>

        <div class="p-2 _bRight"  style="width: 47px;"></div>

      </div>
  `,
  styleUrls: ['./user-list-header.component.scss']
})
export class UserListHeaderComponent implements OnInit {

  SORTMODES = USER_SORT_MODE

  SORTYPES = USER_SORT_TYPE
@Input() fullView!: boolean
  // userSortType: USER_SORT_TYPE = USER_SORT_TYPE.ASC
  // userSortMode:USER_SORT_MODE = USER_SORT_MODE.EMAIL
  // searchText = "";
  // userFilter: IUserFilterItem[] = [];
  USER_APP_ACCESS_TYPES = USER_APP_ACCESS_TYPE
  constructor(
    public ui: UiService,
    public us: UserService,
    private dialog: MatDialog,
    private authS: AuthService
  ) {
    // this.userFilter.push({
    //   name: "Basic Account",
    //   roleIds: [12],
    //   accountTypes: ["BASIC"],
    //   excludeIds: [1, 2, 3, 4, 10, 11, 13, 15],
    //   isFilterActive: true,
    // });

    // this.userFilter.push({
    //   name: "Test Account",
    //   roleIds: [14],
    //   accountTypes: ["BASIC", "CLINIC"],
    //   excludeIds: [],
    //   isFilterActive: false,
    // });
    // this.userFilter.push({
    //   name: "Test Account Intern",
    //   roleIds: [15],
    //   accountTypes: ["BASIC", "CLINIC"],
    //   excludeIds: [],
    //   isFilterActive: false,
    // });
    // this.userFilter.push({
    //   name: "Clinik User",
    //   roleIds: [12, 13, 1, 10],
    //   accountTypes: ["CLINIC"],
    //   excludeIds: [15],
    //   isFilterActive: false,
    // });
    // this.userFilter.push({
    //   name: "Admin Role",
    //   roleIds: [1, 2, 3, 4, 11, 13],
    //   accountTypes: ["BASIC", "CLINIC"],
    //   excludeIds: [],
    //   isFilterActive: false,
    // });
    // this.userFilter.push({
    //   name: "Abrechnungs Role",
    //   roleIds: [10],
    //   accountTypes: ["BASIC", "CLINIC"],
    //   excludeIds: [],
    //   isFilterActive: false,
    // });
  }
  filterGroupSelected: IUserFilterItem = {
    name: "Basic Account",
    roleIds: [12],
    accountTypes: ["BASIC"],
    excludeIds: [1, 2, 3, 4, 10, 11, 13, 15],
    isFilterActive: true,
  };
  ngOnInit(): void {
    console.log();
    
  }
  changeSort(mode: USER_SORT_MODE) {
    if (this.us.userSortMode !== mode) {
      this.us.userSortMode = mode;
    } else {
      this.us.userSortType = this.us.userSortType === USER_SORT_TYPE.ASC ? USER_SORT_TYPE.DESC : USER_SORT_TYPE.ASC;
    }
  }
  // filterButtonChanged(e: any) {
  //   // console.log(e.value.name);
  //   const filter = _.snakeCase(e.value.name)//, ' ', '_');
  //   // console.log(filter.toUpperCase());
    
  //   this.us.loadAllUsersWithFilter(filter.toUpperCase())
    
  // }
  applyFilter(filterValue?: any) {
    // if ( filterValue && filterValue.value){
    //   this.filterstring = filterValue.value
    //   usrs = _.filter(this.us.users,
    //     item => item.firstName.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   || item.lastName.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   || item.email.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   )
    // }
  }
}
