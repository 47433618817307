<div class="container">
  <div class="d-flex flex-column" *ngFor="let report of authReport">
    <div class="p-2">
      <div class="d-flex fs12">
        <div class="p-2 w15p">
          {{report.dateTime | cgDate}}
        </div>
        <div class="p-2 w15p">
          {{report.logType}}
        </div>
        <div class="p-2 w20p">
          {{report.logMessage}}
        </div>
        <div class="p-2 w20p">
          {{report.email}}
        </div>
      </div>
    </div>
  </div>
</div>