import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { apiRequest, API_CALL } from '../core/api.call.enum';


@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http:HttpClient) { }






  async apiGet(call:API_CALL){
    console.log('apiGet')
    const _d = await this.http.get(apiRequest(call)).pipe();
    return _d
  }
  async getPromise(call:API_CALL){
    return this.http.get(apiRequest(call))
  }

  async apiGetP(path:string){
    console.log('apiGet')
    const _d = await this.http.get(path).pipe();
    return _d
  }

  async apiPost(path:string, payload:Object){
    // console.log('apiPost ', path )
    // console.log('apiPost ', payload )
    const _item = 
    await this.http.post(path,payload).toPromise().then(
      _data => {
        // console.log(_data)
        return _data
      }
    )
    return _item
  }

}
