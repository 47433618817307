export enum USER_SORT_MODE {
  EMAIL = 'EMAIL',
  FIRSTNAME = 'FIRSTNAME',
  LASTNAME = 'LASTNAME',
  STATUS = 'STATUS',
  PLAN = 'PLAN',
  LASTLOGIN = 'LASTLOGIN',
}
export enum USER_SORT_TYPE {
  DESC = 'DESC',
  ASC = 'ASC',
}