<mat-toolbar class="overlay-toolbar">
  <!-- <div *ngIf="initAccessItem">

  </div> -->
  {{currentAccessItem?.appAccess}}
  <span class="toolbar-spacer"></span>
  <mat-icon class="mediumIconButton"  (click)='close()'>close</mat-icon>
</mat-toolbar>


<div class="container" style="height: calc(100% - 88px);">

  <div class="d-flex flex-column">
    <div class="p-2" >
      <mat-form-field appearance="outline" class="select-form-field w100">
        <!-- <mat-label>Fachbereiche</mat-label> -->
        <mat-select (selectionChange)='selectedSectorChanged($event)'  [value]="selectedUserStateTypeId">
          <mat-option [value]="userState.id" *ngFor="let userState of allUserAccessTypes" >
            {{userState.viewValue}}
          </mat-option>
        </mat-select>
        <!-- <mat-select (selectionChange)='selectedSectorChanged($event)' [value]="selectedUserStateTypeId">
          <mat-option [value]="userState.id" *ngFor="let userState of ui.allUserAccessTypes" >
            {{userState.viewValue}}
          </mat-option>
        </mat-select> -->
      </mat-form-field>
    </div>

    <!-- <div class="p-2" *ngIf="selectedUserStateTypeId">
      <mat-form-field appearance="outline" class="select-form-field w100">

        <mat-select (selectionChange)='selectedMessageChanged($event)' [value]="selectedUserStateTypeMessageId">
          <mat-option [value]="stateMessage.id" *ngFor="let stateMessage of userStateTypeMessages" >
            {{stateMessage.message}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div> -->


  </div>
</div>


<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <dxfx-df-icon-button *ngIf="showSaveButton"
    title="Änderung speichern" icon="save"
   (clicked)="save()" color="accent"></dxfx-df-icon-button>
</mat-toolbar>
