// export interface IRole {
//   id:number;
//   role_key:string;
//   view_order_index:number;
//   name:string;
//   description?:string;
//   // requirements?:IRoleRequirement[]
//   required_roles?:number[]
//   not_allowed_with?:number[]
//   one_of_required?:number[]
// }

export interface ITemplatePool {
  id?:number;
  name?:string;
  filter_title?:string;
  svgMarker?:string;
  description?:string
  users?:ITemplatePoolAccessUser[]
}

export interface ITemplatePoolAccessUser {
  uid:string
  firstName:string
  lastName:string
  email:string
}
export interface ITemplatePoolUserAccess {
  id:number;
  template_pool_id:number;
}



export interface IUserRoleAccessType {
  id:number;
  role_access_type_id:number;
}

export interface IRoleRequirement {
  id:number;
  name:string;
  description?:string;
  // role_id:number;
  // requirement_type:ROLE_REQUIREMENT_TYPE
  // required_role_id:number;
}

export enum ROLE_REQUIREMENT_TYPE {
  ONLY_WITH = 'ONLY_WITH',
  NOT_WITH = 'NOT_WITH',
}

export interface IUserBaseRole {
  id:number;
  name:string;
  description:string;
  role_key:number
  order:number
  canSet:number
  color?:string;
  isVisible:number
  // hasAccess:boolean
}


export enum UserFilterType {
  CLINIC_USER = 'CLINIC_USER',
  ACCOUNTANT = 'ACCOUNTANT',
  TEST_USER = 'TEST_USER',
  PAID_ONLY = 'PAID_ONLY',
  ALL = 'ALL',
}

export interface UserFilterValue {
  type:UserFilterType
  viewValue:string
}


export interface ISubScriptionPLan {
  id: number
  name: string
  description?:string;
}

export interface IUserFilterItem {
  name: string
  roleIds: number[]
  accountTypes: string[]
  excludeIds: number[]
  isFilterActive: boolean
}
export interface PadNextFachArztFull {
  id: number
  title: string
  items: PadNextFachArztItem[]
}
export interface PadNextFachArztItem {
  id: number
  facharzt_pad_next_id: number
  fachgebiet_pad_next_id: number
  title: string
  fachgebietTitle: string
  wert: number
}
export interface PadnextFachGebietUser {
  facharzt_pad_next_fachgebiete_id: number
  facharzt_pad_next_id: number
  fachgebietTitle: string
  fachgebietId: number
  facharztTitle: string
  facharztWert: number
}