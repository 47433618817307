<mat-toolbar class="overlay-toolbar" >
  <mat-label style="font-weight:500!important; font-size: 16px!important;">
    {{windowTitle}}</mat-label>
    <span class="toolbar-spacer"></span>
    <mat-icon style="color:#ABBBC7; cursor: pointer;   -webkit-user-select:none;" (click)='close()'>close</mat-icon>
  </mat-toolbar>


  <div class="container-fluid h100" style="height: calc(100% - 88px); position: relative;">

    <div [ngSwitch]="currentStep" class=" h100">

      <div class="step h100" *ngSwitchCase="INVITE_STEPS.LOADING">
        <div class="d-flex align-items-center justify-content-center" style="height: 100%;">
          <div class="p-2">
            <mat-spinner diameter='25' class="blue_spinner" color='primary'></mat-spinner>
          </div>
        </div>
      </div>

      <div class="step h100" *ngSwitchCase="INVITE_STEPS.EMAIL">

        <div class="d-flex flex-column align-items-center justify-content-center h100">
          <div class="p-2 w50p">
            <mat-label class='mat-caption'>Email</mat-label>
            <input class="formControl mat-body"
            [class.formControlError]="emailError"
            (keydown.enter)="setStep(INVITE_STEPS.EMAIL)"
            [(ngModel)]='newAccount.email' (ngModelChange)="emailChanged($event)" type="text" >
          </div>
          <div class="p-2 w50p" *ngIf="emailError" style="color: #D32F2F;">
            {{emailError}}
          </div>

        </div>

      </div>

      <div class="step h100"  *ngSwitchCase="INVITE_STEPS.USER_DATA">
        <div class="d-flex flex-column h100">

          <div class="p-0 d-flex h100">
            <div class="p-0 w60p d-flex flex-column">


              <div class="p-0">

                <dxfx-padnext-fachgebiet-selection
                [currentAccount]="newAccount"
                [padNextFachBereichSelected]="padNextFachBereichSelected"
                [padNextFachArztBereichSelected]="padNextFachArztBereichSelected"
                (facharztSelected)="fachArztCHanged($event)"
                ></dxfx-padnext-fachgebiet-selection>

              </div>

              <div class="p-0 d-flex">
                  <dxfx-text-box class="p-2 w50p" [model]="newAccount.title" label="Title"></dxfx-text-box>
                <!-- <div class="p-2 w50p">
                  <mat-label class='mat-caption'>Title</mat-label>
                  <input class="formControl mat-body" [(ngModel)]='newAccount.title' type="text"  >
                </div> -->

                <dxfx-text-box class="p-2 w50p" [model]="newAccount.medical_specialty_designation" label="Facharzt-Bezeichnung"></dxfx-text-box>
                <!-- <div class="p-2 w50p">
                  <mat-label class='mat-caption'>Facharzt-Bezeichnung</mat-label>
                  <input class="formControl mat-body" [(ngModel)]='newAccount.medical_specialty_designation' type="text"  >
                </div> -->
              </div>

             

              <div class="p-0 d-flex">
                <dxfx-text-box class="p-2 w50p" [model]="newAccount.firstName" label="Vorname"></dxfx-text-box>
                <dxfx-text-box class="p-2 w50p" [model]="newAccount.lastName" label="Nachname"></dxfx-text-box>
                <!-- <div class="p-2 w50p">
                  <mat-label class='mat-caption'>Vorname</mat-label>
                  <input class="formControl mat-body" [(ngModel)]='newAccount.firstName' type="text"  >
                </div>
                <div class="p-2 w50p">
                  <mat-label class='mat-caption'>Nachname</mat-label>
                  <input class="formControl mat-body" [(ngModel)]='newAccount.lastName'type="text"  >
                </div> -->
              </div>

              <div class="p-0 d-flex">
                <dxfx-text-box class="p-2 w50p" [model]="newAccount.email" label="Email" [disabled]="true"></dxfx-text-box>
                <dxfx-text-box class="p-2 w50p" [model]="newAccount.phone" label="Tel"></dxfx-text-box>
                <!-- <div class="p-2 w50p">
                  <mat-label class='mat-caption'>Email</mat-label>
                  <input class="formControl mat-body" [(ngModel)]='newAccount.email' type="text" disabled>
                </div>
                <div class="p-2 w50p">
                  <mat-label class='mat-caption'>Tel</mat-label>
                  <input class="formControl mat-body" [(ngModel)]='newAccount.phone' type="text"  >
                </div> -->
              </div>

              <!-- <div class="p-2 mt-auto d-flex flex-column " >
                <div class="p-2"> <mat-label class='mat-caption'>Abo:</mat-label></div>
                <div class="p-2">
                  <mat-radio-group
                  aria-labelledby="example-radio-group-label"
                  class="example-radio-group"
                  [(ngModel)]="newAccount.subscription_plan_id">
                  <mat-radio-button class="example-radio-button" *ngFor="let item of subscriptionPlan" [value]="item.id">
                    {{item.name}}
                  </mat-radio-button>
                </mat-radio-group>
        
                </div>
              </div> -->
              
              <div class="p-0 mt-auto d-flex flex-column " style="background-color:#e4e4e4;">
                <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" 
                   [(ngModel)]="newAccount.subscription_plan_id" >
                     <mat-button-toggle *ngFor="let item of subscriptionPlan" [value]="item.id">{{item.name}}</mat-button-toggle>
                   </mat-button-toggle-group>
              </div>


              <div class="p-0   d-flex flex-column mt5" style="background-color:#e4e4e4;">

                <!-- <div class="d-flex">
                  <div class="p-0"> -->
                    <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" 
                   [(ngModel)]="accountRole" >
                     <mat-button-toggle *ngFor="let accountType of inviteRoles" [value]="accountType.role_id">{{accountType.name}}</mat-button-toggle>
                   </mat-button-toggle-group>
                  <!-- </div> -->
                  <!-- <div class="p-0 ms-auto">
                    <mat-radio-group aria-label="Select an option">
                      <mat-radio-button value="1">Test Account</mat-radio-button>
                      <mat-radio-button value="2">Test User intern</mat-radio-button>
                    </mat-radio-group>
                    <mat-checkbox [checked]="accountRole === 14 " (change)="accountRoleChange(14)">Test Account</mat-checkbox>
                    <mat-checkbox [checked]="accountRole === 15 " (change)="accountRoleChange(15)">Test User intern</mat-checkbox>  
                  </div> -->
                <!-- </div> -->
                
                <!-- <div class="d-flex pl14 p_t20" style="font-size: 12px; background-color:#DDECF2;"> -->
                  <!-- style="font-size: 12px;  height: 60px;  -->
                   
                <!-- <div class="p-2"> <mat-label class='mat-caption'>Rolle:</mat-label></div> -->
                <!-- <div class="p-2">
                  <mat-checkbox [checked]="accountRole === 14 " (change)="accountRoleChange(14)">Test Account</mat-checkbox>
                  <mat-checkbox [checked]="accountRole === 15 " (change)="accountRoleChange(15)">Test User intern</mat-checkbox>
                </div> -->
              </div>

            </div>

            <div class="p-0 w40p h100" >
              <div class="d-flex flex-column h100" >
                <div class="p-0 fachbereichHeader">
                  <mat-label class='mat-caption'>Fachbereiche</mat-label>
                </div>
                <div class="p-0 h100" style="background-color: rgba(0,0,0,0.05); height: 100%;overflow-y: scroll;overflow-x: hidden;">
                  <mat-list  #_options>
                    <!-- (selectionChange)="optionChanged($event)" -->
                    <mat-list-item *ngFor="let expertise of areaOfExpertiseUser" >
                      <div class="d-flex w100 align-items-center h32px">
                        <div class="p-0 pl5">{{expertise.name}}</div>
                        <div class="p-0 ms-auto  mr5 ">
                          <!-- <div class="miniButton"  [class.miniButton_active]="'1 == 1'" (click)="togglePrimaryForNewInvite(expertise, expertise.is_active)" *ngIf="newAccountHasAnyExpertise && newUserHasExpertise(expertise)">Primary</div> -->
                          <div class="miniButton" [class.miniButton_active]="expertise.is_primary" (click)="togglePrimaryForNewInvite2(expertise)" *ngIf="newAccountHasAnyExpertise ">Primary</div>
                        </div>
                        <div class="p-0 mr5 p_t5">
                          <mat-checkbox [checked]="expertise.is_active" (change)="toggleActivityForNewInvite2(expertise)"></mat-checkbox>
                          
                        </div>
                      </div>
                    </mat-list-item>
                    <!-- <mat-list-item *ngFor="let expertise of areaOfExpertisAll" >
                      <div class="d-flex w100 align-items-center h32px">
                        <div class="p-0 pl5">{{expertise.name}}</div>
                        <div class="p-0 ms-auto  mr5 ">
                          
                          <div class="miniButton" [class.miniButton_active]="isPrimaryNewUserExpertise(expertise)" (click)="togglePrimaryForNewInvite(expertise, expertise.is_active)" *ngIf="newAccountHasAnyExpertise && newUserHasExpertise(expertise)">Primary</div>
                        </div>
                        <div class="p-0 mr5 p_t5">
                          <mat-checkbox [checked]="newUserHasExpertise(expertise)" (change)="newAccountCheckBoxChanged($event,expertise)"></mat-checkbox>
                        </div>
                      </div>
                    </mat-list-item> -->

                  </mat-list>
                </div>
              </div>
            </div>
          </div>

         <!--  <div class="p-2" style="max-width: 220px;">

          </div> -->

        </div>

      </div>
    </div>



  </div>
  <mat-toolbar class="overlay-toolbar" >
    <span class="toolbar-spacer"></span>

    <div [ngSwitch]="currentStep">
      <div *ngSwitchCase="INVITE_STEPS.EMAIL">
        <dxfx-df-icon-button title="Weiter" icon="arrow_forward_ios" [disabled]="canChangedStep" (clicked)="setStep(INVITE_STEPS.EMAIL)" ></dxfx-df-icon-button>
      </div>
      <div *ngSwitchCase="INVITE_STEPS.USER_DATA">

        <div *ngIf="overlayData">
          <dxfx-df-icon-button
          *ngIf="overlayData!.mode === olModes.INVITE_AND_ADD_TO_POOL && overlayData.poolId"
          title="Benutzer zum Bereich einladen " icon="send" [disabled]="!inviteButtonEnabled" (clicked)="setStep(INVITE_STEPS.COMPLETE)" ></dxfx-df-icon-button>

          <dxfx-df-icon-button *ngIf="overlayData!.mode === olModes.SIMPLE_INVITE"
        title="Fertigstellen" icon="send" [disabled]="!inviteButtonEnabled" (clicked)="setStep(INVITE_STEPS.COMPLETE)" ></dxfx-df-icon-button>
        </div>

      </div>

    </div>

  </mat-toolbar>
  