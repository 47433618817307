import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { UiService } from 'src/app/services/ui.service';
import * as _ from "lodash";
import { UserI } from 'src/app/core/models/docAccount';
import { IClinicSector } from 'src/app/core/models/clinic.sector.model';
import { ITextEditOverlayData, LINEMODE, TEXTTYPE } from 'src/app/core/models/texteditOverlayData.model';
import { TextEditOverlayComponent } from 'src/app/overlays/text-edit-overlay/text-edit-overlay.component';
import { ClinicSectorOverlayComponent } from 'src/app/overlays/clinic-sector-overlay/clinic-sector-overlay.component';
import { CreateNewSectorOverlayComponent } from 'src/app/overlays/create-new-sector-overlay/create-new-sector-overlay.component';
import { IAccounting, IAccountingPoolFlat } from 'src/app/core/models/accounting.model';
import { AccountingDetailOverlayComponent } from 'src/app/overlays/accounting-detail-overlay/accounting-detail-overlay.component';
import { AccountingOverlayData, AccuntingOverlayMode } from 'src/app/core/models/user-clinic-select-overlay-data.model';
import { ClinicService } from 'src/app/services/clinic.service';
import { UserService } from 'src/app/services/user.service';
import { AlertOverlayComponent } from 'src/app/overlays/alert-overlay/alert-overlay.component';
import { AlertActionI } from 'src/app/core/models/alert/ialert.model';

@Component({
  selector: 'dxfx-clinic-detail',
  templateUrl: './clinic-detail.component.html',
  styleUrls: ['./clinic-detail.component.scss']
})
export class ClinicDetailComponent implements OnInit {

  clinic_id?: string
  isLoading: boolean = true;
  showSaveButton: boolean = false;
  clinic_accounting: IAccounting[] = []
  
  accountingPools: IAccounting[] = []

  TEXTTYPES = TEXTTYPE
  constructor(
    private route: ActivatedRoute,
    public ui:UiService,
    public us:UserService,
    public cs: ClinicService,
    private router: Router,
    private dialog: MatDialog
  ) { }

  ngOnInit(): void {
    console.log("ClinicDetailComponent INIT");

    this.route.params.subscribe(
      (params:any) => {
        if(params.id){
          this.initClinic(params.id)
          // this.template.initTemplateById(this.template_id)
        }
      });
    }

    private initClinic(clinic_id: string) {
      this.clinic_id = clinic_id;
       this.ui.currentPage.emit('klinik')
      this.cs.setCurrentClinic(clinic_id)
      this.isLoading = false
      this.showSaveButton = false
    }

    async updateClinic() {
      this.cs.updateClinic()
    }
    async addAccounting() {

      if(!this.clinic_id) {
        return
      }
      const overlayData: AccountingOverlayData = {
        mode: AccuntingOverlayMode.CREATE_CLINIC_ADMIN,
        clinic_id: this.clinic_id
      }
      this.dialog.open(AccountingDetailOverlayComponent, {
        width: "80%",
        maxWidth: "1200px",
        height: "70%",
        maxHeight: "1200px",
        disableClose: true,
        data:overlayData
      }).afterClosed().subscribe(
        _data => {
          this.loadAccounting()
        }
      )
    }
    openAccounting(account:IAccountingPoolFlat){
      if(!this.clinic_id) {
        return
      }
      const overlayData: AccountingOverlayData = {
        mode: AccuntingOverlayMode.ACCOUNTING_DETAIL,
        clinic_id: this.clinic_id,
        accounting_pool_id:account.id
      }
      this.dialog.open(AccountingDetailOverlayComponent, {
        width: "80%",
        maxWidth: "1200px",
        height: "70%",
        maxHeight: "1200px",
        disableClose: true,
        data:overlayData
      }).afterClosed().subscribe(
        _data => {
          this.loadAccounting()
        }
      )
    }

    async addBereich() {
      this.dialog.open(CreateNewSectorOverlayComponent, {
        width: "80%",
        maxWidth: "1200px",
        height: "70%",
        maxHeight: "1200px",
        disableClose: true,
        data: this.cs.currentClinic
      }).afterClosed().subscribe(
        _data => {
          if (_data) { 
            if(!this.clinic_id) {
              return
            }
            this.cs.loadSectors(this.clinic_id.toString())
          }
        }
      )

    }

    openBereich(sector: IClinicSector) {
      console.log('openBereich ', sector);
      this.dialog.open(ClinicSectorOverlayComponent, {
        width: "80%",
        maxWidth: "1200px",
        height: "70%",
        maxHeight: "1200px",
        disableClose: true,
        data: sector
      }).afterClosed().subscribe(
        _data => {
        //  this.loadSectors()

        }
      )
    }


    modelChangeFn(e:any){
      this.showSaveButton = true
    }
    private async loadAccounting() {
      // if (!this.clinic) return;
      // const _result = await (await this.clinicAPI.getP(API_CALL_CLINIC.GET_ACCOUNTING, this.clinic.id.toString()))
      // console.log(_result)
      // this.clinic_accounting = _result as IAccounting[]

      // this.sectors = _result as IClinicSector[]
      // this.isLoading = false
    }

    update(){
      // if (!this.clinic) return;
      // this.cs.updateClinic(this.clinic)
      // const _path = API_REQUESTS(API_CALL_CLINIC.UPDATE_ADDRESS_BY_ID, this.currentAddress!.id.toString())
      // this.clinicAPI.post(_path, { address:this.currentAddress! })
    }

    editTextFor(forType: TEXTTYPE) {
      if(!this.cs.currentClinic) {
        return
      }
      let _overlayEditData: ITextEditOverlayData = {
        lineMode: LINEMODE.SINGLE,
        text: ""
      }

      switch(forType) {
        case TEXTTYPE.HEADLINE:
          _overlayEditData.lineMode = LINEMODE.SINGLE
          _overlayEditData.text = this.cs.currentClinic.title
          break;
        case TEXTTYPE.DESCRIPTION:
          _overlayEditData.lineMode = LINEMODE.MULTI
          _overlayEditData.text = this.cs.currentClinic.description
          break;
      }


      this.dialog.open(TextEditOverlayComponent, {
        width: "800px",
        height: _overlayEditData.lineMode == LINEMODE.SINGLE ? "160px" : "320px",
        data: _overlayEditData
      }).afterClosed().subscribe(
        (newText:string) => {
          if(newText) {
            switch(forType) {
              case TEXTTYPE.HEADLINE:
                // this.cs.currentClinic.title = newText
                //this.cs.updateClinicItem(this.clinic!.id, { title: newText })
               return;
              case TEXTTYPE.DESCRIPTION:
                // this.clinic!.description = newText
                // this.cs.updateClinicItem(this.clinic!.id, { description: newText })
              return;
            }
          }
        }
      )
    }

    get users(): UserI[] {
      if ( this.us.users ) { return this.us.users}
    return []
      // if (!this.clinic) return [];
      // let _users = this.ui.users.filter(item => item.clinicId == this.clinic!.id)
      // if (_users.length > 0){
      //   return _users
      // } else {
      //   return []
      // }
      // //return this.ui.users.filter(item => item.clinicId == this.clinic.id)
    }





  deleteClinic(){
    this.dialog.open(AlertOverlayComponent, {
    width: '400px',
    data: {
      title: 'Adresse löschen?',
      bodyMessage: 'Sind Sie sicher, dass Sie diese Klinik mit allen Benutzern und Vorlagen entfernen wollen? Diese Aktion kann nicht rückgängig gemacht werden.',
        actions: [
          {title: 'Entfernen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME},
          {title: 'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
        ]
      },
    backdropClass: 'backdrop-background'
      }).afterClosed().subscribe((result) => {
        if (result != null){
          if (result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
           this.finalDeleteClinic()

          }
        }
      });
  }

  private async finalDeleteClinic() { 
    if(!this.clinic_id) {
          return
    }
    await this.cs.removeFull(this.clinic_id) 
    
    setTimeout(() => { 
      this.cs.loadClinics()
      this.router.navigateByUrl('klinik')
    },1500)
    
  }
}
