import { Component, OnInit } from '@angular/core';
import { API_CALL } from 'src/app/core/api.call.enum';
import { IAuthReporItem, IAuthReporItemFixed, IAuthReportByType, IAuthReportUserEntry, LogType } from 'src/app/core/models/template.report.model';
import { ApiService } from 'src/app/services/api.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'dxfx-user-auth-report',
  templateUrl: './user-auth-report.component.html',
  styleUrls: ['./user-auth-report.component.scss']
})
export class UserAuthReportComponent implements OnInit {

  constructor(private api:ApiService, private ui: UiService) { }
  authReport: IAuthReportUserEntry[] = []
  // authReport: IAuthReporItem[] = []
  // authReportFixed: IAuthReporItemFixed[] = []

  ngOnInit(): void {
    this.loadAuthReport()
    setTimeout(()=>{
      this.ui.currentPage.emit('user-report');
    },1000)
  }

  private async loadAuthReport() {
    (await this.api.apiGet(API_CALL.GET_AUTH_REPORT_FILTERED)).pipe().subscribe(
      _data => {
        this.authReport = _data as IAuthReportUserEntry[]
        this.authReport.sort((a, b) => b.dateTime - a.dateTime)
        console.log(this.authReport)

        // for(var i = 0; i < this.authReport.length; i ++) {
        //   var _fixedByType: IAuthReportByType[] = []
        //   const _current = this.authReport[i]
        //   const _invites = _current.items.filter(item => item.logType == LogType.INVITE)

        //   _fixedByType.push({
        //     logType: LogType.INVITE,
        //     items: _invites
        //   })


        //   const _sessions = _current.items.filter(item => item.logType == LogType.SESSION_START)

        //   _fixedByType.push({
        //     logType: LogType.SESSION_START,
        //     items: _sessions
        //   })
        //   let _new: IAuthReporItemFixed = {
        //     dateEnd: _current.dateEnd,
        //     dateStart: _current.dateStart,
        //     items: _fixedByType
        //   }
        //   this.authReportFixed.push(_new)
        // }
        console.log(this.authReport)
      }
    )
  }

}
