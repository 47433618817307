<div class="d-flex _td align-items-center " *ngIf="fullView">

    <div class="p-0 _bRight" style="width: 200px;">{{user.email}}</div>
    <div class="p-0 _bRight" style="width: 200px;">{{user.lastName}}</div>
    <div class="p-0 _bRight" style="width: 200px;">{{user.firstName}}</div>
    
      <div class="p-2 ms-auto _bRight text-center" style="width: 80px;"><mat-icon *ngIf="isClinicUser(user.uid)" style="color:green">check</mat-icon></div>
      <div class="p-2 text-center" style="width: 90px;" (click)='openStatus()'>{{state?.viewValue}}</div>

      <div class="p-2 text-center" style="width: 50px;" (click)='changeSubscription()'>{{user.subscription_plan_id}}</div>
      <div class="p-0 _bRight text-center" style="width: 45px; font-size: 12px;" *ngFor="let role of ui.baseUserRoles" >

        <mat-icon class="mediumIconButton" style="color:#32A555" *ngIf="hasRole(role.id) && canSet(role)" (click)='toggleRole(role.id)'>check</mat-icon>
        <mat-icon  style="color:#32A555" *ngIf="hasRole(role.id) && !canSet(role)" matTooltip="Rolle kann nicht geändert werden">check</mat-icon>
        <mat-icon class="mediumIconButton" *ngIf="!hasRole(role.id) && canSet(role)" (click)='toggleRole(role.id)'>close </mat-icon>
        <mat-icon  style="color:#cccccc" *ngIf="!hasRole(role.id) && !canSet(role)" matTooltip="Rolle kann nicht geändert werden">close</mat-icon>

      </div>

      <div class="p-2 _bRight" style="width: 35px">
        <!-- <mat-icon  style="font-size: 12px;  user-select: none;" >edit</mat-icon> -->
        <mat-icon class="dxfx_icon_btn_mini"(click)="onSelect()" >edit </mat-icon>
      </div>

</div>

<div class="d-flex _td align-items-center user-row" *ngIf="!fullView" >

    <div class="p-0 _bRight pl10" style="width: 200px;">{{user.email}}</div>
    <div class="p-0 _bRight pl10" style="width: 200px;">{{user.lastName}}</div>
    <div class="p-0 _bRight pl10" style="width: 200px;">{{user.firstName}}</div>
    <div class="p-0 _bRight pl10" style="width: 200px;">{{user.fachgebietPadNext?.facharztTitle}}</div>
    
    <div class="p-2 ms-auto _bRight text-center" style="width: 80px;"><mat-icon *ngIf="isClinicUser(user.uid)" style="color:green">check</mat-icon></div>
    <div class="p-2 text-center " style= "width: 90px;" (click)='openStatus()'>{{state?.viewValue}}</div>
    <!--  -->

    <div class="p-2 text-center text-select" style="width: 50px;" (click)='changeSubscription()'>{{user.subscription_plan_id}}</div>
    <div class="p-2 _bRight text-center" style="width: 130px;"> 
      <span *ngIf="user.activity">{{user.activity.dateTime | date: 'dd.MM.yyyy HH:mm'}}</span>
      <span *ngIf="!user.activity">null</span>
    </div>
    <div class="p-2 _bRight" style="width: 35px">
      <mat-icon class="dxfx_icon_btn_mini" (click)="onSelect()" >edit </mat-icon>
    </div>

</div>
