<div class="container p_t80" *ngIf="!isClinicAddMode && !isSaving">
  <div class="d-flex">
    <div class="p-2 flex-fill">
      <mat-toolbar class="overlay-toolbar">
        Kliniken
        <span class="toolbar-spacer"></span>
        <dxfx-df-icon-button
         title="Anlegen" icon="add"
        (clicked)="toggleAddMode()" ></dxfx-df-icon-button>
      </mat-toolbar>
    </div>
  </div>
</div>

<div class="container p_t80" style="background-color: rgba(0,0,0,0.05);"  *ngIf="!isClinicAddMode && !isSaving"> 
  <div class="d-flex flex-wrap p30">
    <ng-template ngFor let-item [ngForOf]="cs.clinics" >
      <mat-card class="p-2 clinic-box" (click)="navigateToClinic(item.id)">
          <div class="d-flex flex-column">
            <div class="p-2"><mat-icon>apartment</mat-icon></div>
            <div class="p-2">{{item.title}}</div>
            <div class="p-2" style="font-size: 12px; font-style: italic;">
              {{item.address_title}} <br>
              {{item.address_street}} <br>
              {{item.address_zip}} {{item.address_city}}
            </div>
          </div>
       </mat-card>
    </ng-template>
  </div>
</div>

<div class="container  p_t80 " *ngIf="isClinicAddMode && !isSaving">
  <div class="d-flex">
    <div class="p-2 flex-fill">
      <mat-toolbar class="overlay-toolbar">
        Neue Klinik anlegen
        <span class="toolbar-spacer"></span>
        <dxfx-df-icon-button
         title="Zurück" icon="close"
        (clicked)="toggleAddMode()" ></dxfx-df-icon-button>
      </mat-toolbar>
    </div>
  </div>
</div>

<div class="container"   *ngIf="isClinicAddMode && !isSaving">
   
  <div class="container-fluid m_t5" >
    <div class="d-flex flex-column m_t10" style="background-color: rgba(0,0,0,0.05)">
      <div class="p-2">
        <input class="formControl mat-body h2Size" [(ngModel)]="clinic.title" >
      </div>
      <div class="p-2 m_t10">
        <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"
          maxlength="300" [(ngModel)]="clinic.description" rows="6" class="mat-body formControl"  #multiLine>
        </textarea>
      </div>
    </div>

    <div class="container-fluid m_t5">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Addresse</mat-panel-title>
          </mat-expansion-panel-header>

          <div class="container-fluid pl0 pr0" >

            <div class="row pl0 pr0`">

              <div class="col-lg-12 col-md-12 col-sm-12 "  >
                <mat-label class='mat-caption'>Klinik Rechn. Info</mat-label>
                <input class="formControl" placeholder="Die Rechnungserstellung erfolgt im Auftrag..."  [(ngModel)]="clinic.clinicLegalMain" >
              </div>

              
              <div class="col-lg-6 col-md-6 col-sm-12 m_t10"  >
                <mat-label class='mat-caption'></mat-label>
                <input class="formControl" placeholder="..."  [(ngModel)]="clinic.clinicInvoiceProviderFirst" >
              </div>
              
              <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
                <mat-label class='mat-caption'></mat-label>
                <input class="formControl" placeholder="..."  [(ngModel)]="clinic.clinicInvoiceProviderSecond" >
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
                <mat-label class='mat-caption'></mat-label>
                <input class="formControl" placeholder="..."  [(ngModel)]="clinic.clinicInvoiceProviderThird" >
              </div>
              
              <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
                <mat-label class='mat-caption'></mat-label>
                <input class="formControl" placeholder="..."  [(ngModel)]="clinic.clinicInvoiceProviderFourth" >
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
                <mat-label class='mat-caption'>Strasse</mat-label>
                <input  class="formControl" placeholder="Strasse"  [(ngModel)]="clinic.address_street" >
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12 m_t10">
                <mat-label class='mat-caption'>Stadt</mat-label>
                <input class="formControl" placeholder="Stadt"  [(ngModel)]="clinic.address_city" >
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12 m_t10">
                <mat-label class='mat-caption'>Plz</mat-label>
                <input class="formControl" placeholder="Plz"  [(ngModel)]="clinic.address_zip" >
              </div>

            </div>
            

            
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>Kontoinhaber</mat-label>
                <input class="formControl" placeholder="Kontoinhaber"  [(ngModel)]="clinic.bank_owner" >
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>Bankinstitut</mat-label>
                <input class="formControl" placeholder="Bankinstitut"  [(ngModel)]="clinic.bank_name" >
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>IBAN</mat-label>
                <input class="formControl" placeholder="IBAN"  [(ngModel)]="clinic.iban" >
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>BIC</mat-label>
                <input class="formControl" placeholder="BIC"  [(ngModel)]="clinic.bic" >
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>Ust-IdNr.</mat-label>
                <input class="formControl" placeholder="Ust-IdNr."  [(ngModel)]="clinic.ustNr" >
              </div>
            </div>
            </div>

        </mat-expansion-panel>
      </mat-accordion>
    </div>
  </div>
  
  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <dxfx-df-icon-button
    title="Speichern" icon="save" [disabled]="!saveEnabled"
   (clicked)="saveNewClinic()" ></dxfx-df-icon-button>
  </mat-toolbar>
  
</div>


<div class="container p_t80 " *ngIf="isSaving">
  <div class="d-flex justify-content-center align-items-center" style="min-height: 400px;">
    <div class="p-2 h100">
      <mat-spinner [diameter]="25" ></mat-spinner>
    </div>
  </div>
</div>
