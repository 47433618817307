import { environment } from "src/environments/environment";

export enum API_CALL {

  USERS_ADMIN_ALL = 'USERS_ADMIN_ALL',
  USERS_ADMIN_ALL_WITH_ACCESS = 'USERS_ADMIN_ALL_WITH_ACCESS',
  ACCOUNTS_ALL = 'ACCOUNTS_ALL',
  TEMPLATE_COUNT = 'TEMPLATE_COUNT',
  TEMPLATE_FILTER_TYPES = 'TEMPLATE_FILTER_TYPES',

  ACCOUNT_UPDATE = 'ACCOUNT_UPDATE',

  GET_EMAIL_DETAIL = 'GET_EMAIL_DETAIL',
  GET_EMAIL_DETAIL_ITEMS = 'GET_EMAIL_DETAIL_ITEMS',
  UPDATE_EMAIL_TEMPLATE_ITEM = 'UPDATE_EMAIL_TEMPLATE_ITEM',


  // GET_UI_USER_STATE_TYPES = "GET_UI_USER_STATE_TYPES",
  // GET_UI_USER_STATE_TYPE_MESSAGES = "GET_UI_USER_STATE_TYPE_MESSAGES",
  // GET_UI_STATE_MESSAGE_CHANGES = "GET_UI_STATE_MESSAGE_CHANGES",

  POST_CHANGE_ACCESS_FOR_USER = "POST_CHANGE_ACCESS_FOR_USER",
  GET_ALL_ACCESS_TYPE = "GET_ALL_ACCESS_TYPE",

  GET_USER_BASE_ROLES = "GET_USER_BASE_ROLES",

  GET_USER_PER_YEAR = "GET_USER_PER_YEAR",
  GET_REPORT_BY_YEAR = "GET_REPORT_BY_YEAR",
  GET_USERSESSIONS_IN_YEAR = "GET_USERSESSIONS_IN_YEAR",
  USER_OWN_REPORT = "USER_OWN_REPORT",
  USER_PROCESS_REPORT = "USER_PROCESS_REPORT",
  INVOICES_REPORT = "INVOICES_REPORT",
  USER_OWN_DELETED_REPORT = "USER_OWN_DELETED_REPORT",
  PROCESS_DELETED_REPORT = "PROCESS_DELETED_REPORT",
  ADMIN_TEMPLATES_DELETED_REPORT = "ADMIN_TEMPLATES_DELETED_REPORT",
  ADMIN_PROCESS_DELETED_REPORT = "ADMIN_PROCESS_DELETED_REPORT",
  GET_AUTH_REPORT = "GET_AUTH_REPORT",
  GET_AUTH_REPORT_FILTERED = "GET_AUTH_REPORT_FILTERED",
  GET_USER_STATS = "GET_USER_STATS",
 
  ADD_ROLE_FOR_USER = "ADD_ROLE_FOR_USER",
  REMOVE_ROLE_FOR_USER = "REMOVE_ROLE_FOR_USER",
  
  
  GET_SUBSCRIPTION_PLAN = "GET_SUBSCRIPTION_PLAN",
  GET_PAD_NEXT_FACH_ALL = 'GET_PAD_NEXT_FACH_ALL',
  
  POST_PAD_NEXT_FACH_FOR_UID = 'POST_PAD_NEXT_FACH_FOR_UID',


}


export function apiRequest(call:API_CALL, params:string = '') {
  switch(call){

    case API_CALL.GET_PAD_NEXT_FACH_ALL:
      return `${environment.endpoints.apiBasePathV2}ui/pad-next-fach`;
    case API_CALL.POST_PAD_NEXT_FACH_FOR_UID:
      return `${environment.endpoints.apiBasePathV2}ui/pad-next-fach`;

    case API_CALL.USERS_ADMIN_ALL:
      // return `${environment.endpoints.apiBasePathV2}admin/users-admin-all`;
      return `${environment.endpoints.apiBasePathV2}admin/all-users`;
      
    case API_CALL.USERS_ADMIN_ALL_WITH_ACCESS:
      return `${environment.endpoints.apiBasePathV2}admin/users-admin-all-access`;
    case API_CALL.ACCOUNTS_ALL:
      return `${environment.endpoints.apiBasePathV2}accounts/admin-all-test`;
    case API_CALL.TEMPLATE_COUNT:
      return `${environment.endpoints.apiBasePathV2}accounts/admin-all`;
    case API_CALL.TEMPLATE_FILTER_TYPES:
      return `${environment.endpoints.apiBasePathV2}ui/template-access-types`;
    case API_CALL.ACCOUNT_UPDATE:
      return `${environment.endpoints.apiBasePathV2}accounts/admin-user-update/${params}`;

    case API_CALL.GET_EMAIL_DETAIL:
      return `${environment.endpoints.apiBasePathV2}admin/email-templates-all/`;
      case API_CALL.GET_EMAIL_DETAIL_ITEMS:
        return `${environment.endpoints.apiBasePathV2}admin/email-templates-items/${params}`;
        case API_CALL.UPDATE_EMAIL_TEMPLATE_ITEM:
          return `${environment.endpoints.apiBasePathV2}admin/email-template-item/${params}`;

    // case API_CALL.GET_UI_USER_STATE_TYPES:
    //   return `${environment.endpoints.apiBasePathV2}ui/user-state-types/`;
    // case API_CALL.GET_UI_USER_STATE_TYPE_MESSAGES:
    //   return `${environment.endpoints.apiBasePathV2}ui/user-state-messages/`;

    case API_CALL.POST_CHANGE_ACCESS_FOR_USER:
      // return `${environment.endpoints.apiBasePathV2}admin/access/change`;
      return `${environment.endpoints.apiBasePathV2}admin/access/change`;
    case API_CALL.GET_ALL_ACCESS_TYPE:
      return `${environment.endpoints.apiBasePathV2}admin/access/user-access-types`;
    
    case API_CALL.GET_USER_BASE_ROLES:
      return `${environment.endpoints.apiBasePathV2}admin/access/base-roles`;
    // case API_CALL.GET_UI_STATE_MESSAGE_CHANGES:
    //   return `${environment.endpoints.apiBasePathV2}ui/user-state-changes`;

    // case API_CALL.SIGN_IN:
    //   return `${environment.endpoints.apiBasePathV2}accounts/sign-in/`;


    case API_CALL.GET_USERSESSIONS_IN_YEAR:
      return `${environment.endpoints.apiBasePathV2}admin/user-sessions/`;
    case API_CALL.GET_REPORT_BY_YEAR:
      return `${environment.endpoints.apiBasePathV2}admin/template-report-by-year/${params}`;
    case API_CALL.USER_OWN_REPORT:
      return `${environment.endpoints.apiBasePathV2}admin/user-own-templates/${params}`;
    case API_CALL.USER_PROCESS_REPORT:
      return `${environment.endpoints.apiBasePathV2}admin/user-processes/${params}`;
    case API_CALL.INVOICES_REPORT:
      return `${environment.endpoints.apiBasePathV2}admin/created-invoices/${params}`;

    case API_CALL.GET_USER_PER_YEAR:
      return `${environment.endpoints.apiBasePathV2}admin/user-report/${params}`;
    case API_CALL.USER_OWN_DELETED_REPORT:
      return `${environment.endpoints.apiBasePathV2}admin/deleted-templates/${params}`;
    case API_CALL.PROCESS_DELETED_REPORT:
      return `${environment.endpoints.apiBasePathV2}admin/deleted-processes/${params}`;
    case API_CALL.ADMIN_TEMPLATES_DELETED_REPORT:
      return `${environment.endpoints.apiBasePathV2}admin/deleted-templates-by-admin/${params}`;
    case API_CALL.ADMIN_PROCESS_DELETED_REPORT:
      return `${environment.endpoints.apiBasePathV2}admin/deleted-processes-by-admin/${params}`;


    case API_CALL.GET_USER_STATS:
      return `${environment.endpoints.apiBasePathV2}admin/user-stats/`;
    case API_CALL.GET_AUTH_REPORT:
      return `${environment.endpoints.apiBasePathV2}admin/auth-report/`;
    case API_CALL.GET_AUTH_REPORT_FILTERED:
      return `${environment.endpoints.apiBasePathV2}admin/auth-report-filtered/`;
    case API_CALL.REMOVE_ROLE_FOR_USER:
      return `${environment.endpoints.apiBasePathV2}admin/access/remove-access-for`;
    case API_CALL.ADD_ROLE_FOR_USER:
      return `${environment.endpoints.apiBasePathV2}admin/access/access-for/`;
    case API_CALL.GET_SUBSCRIPTION_PLAN:
      return `${environment.endpoints.apiBasePathV2}admin/subscription-plan/`;
      
  }
}

