import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ITextEditOverlayData, LINEMODE } from 'src/app/core/models/texteditOverlayData.model';

@Component({
  selector: 'dxfx-text-edit-overlay',
  templateUrl: './text-edit-overlay.component.html',
  styleUrls: ['./text-edit-overlay.component.scss']
})
export class TextEditOverlayComponent implements OnInit {

  lineMode = LINEMODE.SINGLE;
  text:string = "";
  LINEMODES = LINEMODE

  constructor(
    private dialogRef:MatDialogRef<TextEditOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data:ITextEditOverlayData
  ) {
    this.lineMode = data.lineMode;
    this.text = data.text;
  }

  ngOnInit(): void {
    console.log('#INIT TextEditOverlayComponent');
  }

  close(): void {
    this.dialogRef.close();
  }
  save(): void {
    this.dialogRef.close(this.text);
  }

}
