export interface AlertI {
  title:string;
  bodyMessage:string;
  actions: AlertActionI[]
}

export interface AlertActionI {
  title:string
  color:AlertActionI.BUTTON_COLOR_TYPE
  actionType:AlertActionI.BUTTON_ACTION_TYPE
}

export namespace AlertActionI {
  export type BUTTON_COLOR_TYPE = 'WARN' | 'ACCENT' | 'PRIMARY' | 'BASIC';
  export const BUTTON_COLOR_TYPE = {
    WARN: 'WARN' as BUTTON_COLOR_TYPE,
    ACCENT: 'ACCENT' as BUTTON_COLOR_TYPE,
    PRIMARY: 'PRIMARY' as BUTTON_COLOR_TYPE,
    BASIC: 'BASIC' as BUTTON_COLOR_TYPE,
  }
}

export namespace AlertActionI {
  export type BUTTON_ACTION_TYPE = 'OK' | 'CANCEL' | 'RESUME' ;
  export const BUTTON_ACTION_TYPE = {
    OK: 'OK' as BUTTON_ACTION_TYPE,
    CANCEL: 'CANCEL' as BUTTON_ACTION_TYPE,
    RESUME: 'RESUME' as BUTTON_ACTION_TYPE,
  }
}
