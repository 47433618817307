
<mat-toolbar class="overlay-toolbar">
  Bereich anlegen
	<span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container-fluid m_t5" style="height: calc(100% - 100px)" >

  <div class="d-flex flex-column m_t10" style="background-color: rgba(0,0,0,0.05)">
    <div class="p-2">
      <input class="formControl mat-body h2Size" [(ngModel)]="sector.title" >
    </div>
    <div class="p-2 m_t10">
      <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"
        maxlength="300" [(ngModel)]="sector.description" rows="2" class="mat-body formControl"  #multiLine>
        </textarea>
    </div>
  </div>

  <div class="d-flex m_t10" style="background-color: rgba(0,0,0,0.05)">
    <div class="p-2">
      <h3>Adress Daten</h3>
    </div>
    <!-- <div class="p-2 ms-auto">
      <dxfx-df-icon-button title="Aus Klinik einfügen" icon="add" (clicked)="onAddressCopy()" ></dxfx-df-icon-button>
    </div> -->
  </div>

  <div class="row">
    <div class="col-lg-12 col-md-12 col-sm-12 "  >
      <mat-label class='mat-caption'>Klinik Rechn. Info</mat-label>
      <input class="formControl" placeholder="Die Rechnungserstellung erfolgt im Auftrag..."  [(ngModel)]="address.clinicLegalMain" >
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 m_t10" >
      <mat-label class='mat-caption'></mat-label>
      <input class="formControl" placeholder="..."  [(ngModel)]="address.clinicInvoiceProviderFirst" >
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 m_t10" >
      <mat-label class='mat-caption'></mat-label>
      <input class="formControl" placeholder="..."  [(ngModel)]="address.clinicInvoiceProviderSecond" >
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
      <mat-label class='mat-caption'></mat-label>
      <input class="formControl" placeholder="..."  [(ngModel)]="address.clinicInvoiceProviderThird" >
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
      <mat-label class='mat-caption'></mat-label>
      <input class="formControl" placeholder="..."  [(ngModel)]="address.clinicInvoiceProviderFourth" >
    </div>


    <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
      <mat-label class='mat-caption'>Klinik Address Name</mat-label>
      <input class="formControl" placeholder="Name"  [(ngModel)]="address.klinikName" >
    </div>
    <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
      <mat-label class='mat-caption'>Behandelnde(r) Arzt/Ärztin</mat-label>
      <input class="formControl" placeholder="Name"  [(ngModel)]="address.behandelnder_arzt" >
    </div>



    <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
      <mat-label class='mat-caption'>Strasse</mat-label>
      <input  class="formControl" placeholder="Strasse"  [(ngModel)]="address.streetName" >
    </div>
    <div class="col-lg-3 col-md-3 col-sm-12 m_t10">
      <mat-label class='mat-caption'>Stadt</mat-label>
      <input class="formControl" placeholder="Stadt"  [(ngModel)]="address.city" >
    </div>
    <div class="col-lg-2 col-md-2 col-sm-12 m_t10">
      <mat-label class='mat-caption'>Plz</mat-label>
      <input class="formControl" placeholder="Plz"  [(ngModel)]="address.zip" >
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Kontoinhaber</mat-label>
      <input class="formControl" placeholder="Kontoinhaber"  [(ngModel)]="address.kontoinhaber" >
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Bankinstitut</mat-label>
      <input class="formControl" placeholder="Bankinstitut"  [(ngModel)]="address.bank_name" >
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>IBAN</mat-label>
      <input class="formControl" placeholder="IBAN"  [(ngModel)]="address.iban" >
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>BIC</mat-label>
      <input class="formControl" placeholder="BIC"  [(ngModel)]="address.bic" >
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Ust-IdNr.</mat-label>
      <input class="formControl" placeholder="Ust-IdNr."  [(ngModel)]="address.ust_nr" >
    </div>

    <!-- <div class="col-lg-12 col-md-12 col-sm-12 "  >
      <div class="d-flex flex-column">
        <div class="p-2">
         
        </div>
        <div class="p-2">
          
        </div>
  
      </div>
    </div> -->
    <!-- <div class="col-lg-12 col-md-12 col-sm-12 "  >
      <div class="d-flex">
        <div class="p-2 flex-fill">
          <mat-label class='mat-caption'>Strasse</mat-label>
          <input  class="formControl" placeholder="Strasse"  [(ngModel)]="address.streetName" >
        </div>
        <div class="p-2 " style="width: 240px">
          <mat-label class='mat-caption'>Stadt</mat-label>
          <input class="formControl" placeholder="Stadt"  [(ngModel)]="address.city" >
        </div>
        <div class="p-2" style="width: 130px">
          <mat-label class='mat-caption'>Plz</mat-label>
          <input class="formControl" placeholder="Plz"  [(ngModel)]="address.zip" >
        </div>
  
      </div>
    </div> -->
  </div>
  




</div>



<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <dxfx-df-icon-button
  title="Speichern" icon="save" [disabled]="!saveEnabled"
 (clicked)="save()" ></dxfx-df-icon-button>
</mat-toolbar>
<!-- 
<div class="row pl10 p_t15 pr10">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <mat-label class='mat-caption'>Name der Praxis/Klinik</mat-label>
    <input class="formControl mat-body"
    [class.formControlError]="address.klinikName === '' || address.klinikName === null"
    [(ngModel)]="address.klinikName" >
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12">
    <mat-label class='mat-caption'>Abteilung</mat-label>
    <input class="formControl mat-body" [(ngModel)]="address.abteilung">
  </div>
</div>

<div class="row pl10 p_t15 pr10">

  <div class="col-lg-6 col-md-6 col-sm-12">
    <mat-label class='mat-caption'>Straße und Hausnummer</mat-label>
    <input class="formControl mat-body"
    [class.formControlError]="address.streetName === '' || address.streetName === null"  [(ngModel)]="address.streetName">
  </div>

  <div class="col-lg-2 col-md-2 col-sm-6">
    <mat-label class='mat-caption'>PLZ</mat-label>
    <input class="formControl mat-body" [class.formControlError]="address.zip === '' || address.zip === null"  [(ngModel)]="address.zip" >
  </div>

  <div class="col-lg-4 col-md-4 col-sm-6">
    <mat-label class='mat-caption'>Stadt</mat-label>
    <input class="formControl mat-body" [class.formControlError]="address.city === '' || address.city === null"  [(ngModel)]="address.city" >
  </div>

</div>


<div class="row pl10 p_t15 pr10">

  <div class="col-lg-6 col-md-6 col-sm-12">
    <mat-label class='mat-caption'>Kontoinhaber/in</mat-label>
    <input class="formControl mat-body" [(ngModel)]="address.kontoinhaber" >
  </div>

  <div class="col-lg-6 col-md-6 col-sm-12">
    <mat-label class='mat-caption'>USt-IdNr.</mat-label>
    <input class="formControl mat-body" [(ngModel)]="address.ust_nr" >
  </div>

</div>

<div class="row pl10 p_t15 pr10 p_b20">
  <div class="col-lg-6 col-md-6 col-sm-12">
    <mat-label class='mat-caption'>Bankinstitut</mat-label>
    <input class="formControl mat-body" [(ngModel)]="address.bank_name" >
  </div>

  <div class="col-lg-3 col-md-3 col-sm-6">
    <mat-label class='mat-caption'>IBAN</mat-label>
    <input class="formControl mat-body" [(ngModel)]="address.iban" >
  </div>

  <div class="col-lg-3 col-md-3 col-sm-6">
    <mat-label class='mat-caption'>BIC</mat-label>
    <input class="formControl mat-body" [(ngModel)]="address.bic" >
  </div>

</div> -->