import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { USER_APP_ACCESS_TYPE } from "src/app/core/models/docAccount";

import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";

import { UiService } from "src/app/services/ui.service";

import { UserService } from "src/app/services/user.service";

import { IUserFilterItem } from "src/app/core/models/role.model";

// import { USER_SORT_MODE, USER_SORT_TYPE } from "src/app/core/models/enum.types";

@Component({
  selector: 'dxfx-user-list-header-radio-group',
  template: `
   <div class="d-flex pl14 p_t20" style="font-size: 12px; background-color:#DDECF2;">
   <!-- style="font-size: 12px;  height: 60px;  -->
    <mat-button-toggle-group name="favoriteColor" aria-label="Favorite Color" 
    (change)="us.filterButtonChanged($event)"
    [(ngModel)]="us.filterGroupSelected">
      <mat-button-toggle *ngFor="let role of us.userFilter" [value]="role">{{role.name}}</mat-button-toggle>
      
    </mat-button-toggle-group>
        <!-- <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          (change)="filterButtonChanged($event)"
          [(ngModel)]="filterGroupSelected">
          <mat-radio-button class="example-radio-button" *ngFor="let role of userFilter" [value]="role">
            {{role.name}}
          </mat-radio-button>
        </mat-radio-group> -->
     
      </div>
  `,
  styleUrls: ['./user-list-header-radio-group.component.scss']
})
export class UserListHeaderRadioGroupComponent implements OnInit {
  // SORTMODES = USER_SORT_MODE

  // SORTYPES = USER_SORT_TYPE

  // userSortType: USER_SORT_TYPE = USER_SORT_TYPE.ASC
  // userSortMode:USER_SORT_MODE = USER_SORT_MODE.EMAIL
  
  USER_APP_ACCESS_TYPES = USER_APP_ACCESS_TYPE
  constructor(
    public ui: UiService,
    public us: UserService,
    private dialog: MatDialog,
    private authS: AuthService
  ) {
   
  }
 
  ngOnInit(): void {
    console.log();
    
  }
  // changeSort(mode: USER_SORT_MODE) {
  //   if (this.userSortMode !== mode) {
  //     this.userSortMode = mode;
  //   } else {
  //     this.userSortType = this.userSortType === USER_SORT_TYPE.ASC ? USER_SORT_TYPE.DESC : USER_SORT_TYPE.ASC;
  //   }
  // }
 
  applyFilter(filterValue?: any) {
    // if ( filterValue && filterValue.value){
    //   this.filterstring = filterValue.value
    //   usrs = _.filter(this.us.users,
    //     item => item.firstName.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   || item.lastName.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   || item.email.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   )
    // }
  }

  

}
