import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'dxfx-df-icon-button',
  templateUrl: './df-icon-button.component.html',
  styleUrls: ['./df-icon-button.component.scss']
})
export class DfIconButtonComponent {
  @Input() disabled:boolean = false
  @Input() color:string = 'base'

  @Input()
  title!: string;
  @Input() icon!:
  string
  @Output() clicked = new EventEmitter();
  constructor() {
    if(this.color == null){}
  }

  // ngOnInit(): void {
  //   //console.log(ÏNIT)
  // }
  tapped(){
    this.clicked.emit()
  }

}
