import { Component, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { AuthService } from './services/auth.service';
import { ISnackData, UiService } from './services/ui.service';

@Component({
  selector: 'dxfx-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{

  constructor(
    private snackBar: MatSnackBar,
    private router:Router,
    public auth: AuthService,
    public ui: UiService
    ){}


  ngOnInit(): void {
    console.log('AppComponent')
    this.ui.hasNavigateTo.subscribe(
      route =>{
        this.router.navigate([route]);
      }
    );
    this.ui.snack.subscribe(
      (_message: string) => {
        if (_message) {
          this.snack(_message)
        }
      }
    )
    this.ui.customSnack.subscribe(
      (message) => {
        if (message){
          this.snackCustom(message);
        }
      }
    );
    this.auth.loginEmitter.subscribe(_loggedIn => {
      if(_loggedIn == true) {
        this.ui.initUiData()
      }
    })
  }
  title = 'admin';

  async signOut(){
     await this.auth.signOut()
  }
  nav(path:string){
    this.router.navigateByUrl(path)
  }
  snackCustom(item:ISnackData) {
    this.snackBar.open(item.message, undefined, {
      duration: item.duration,
      panelClass: item.panelClass
    });
  }
  snack(message:string) {
    this.snackBar.open(message, undefined, {
      duration: 3500,
    });
  }
}
