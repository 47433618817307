export interface IUserStateType {
  id: number;
  state_enum: string
  state_view_value: string
}


export interface IUserStateTypeMessage {
  id: number;
  user_state_type_id: number;
  message: string
  visibility: STATE_MESSAGE_VISIBILITY
}

export interface IStateMessageAvalableChanges {
  id: number;
  from_user_state_type_message_id: number;
  to_user_state_type_message_id: number;
}

export interface IUserAccessType {
  id:number;
  appAccess:APP_ACCESS_ENUM;
  viewValue: string;
  visibility: STATE_MESSAGE_VISIBILITY
  canChangeTo: number[]
  color?: string
}

export enum STATE_MESSAGE_VISIBILITY {
  CLINIC_USER = "CLINIC_USER",
  NON_CLINIC_USER = "NON_CLINIC_USER",
  BOTH = "BOTH",
}

export enum APP_ACCESS_ENUM {
  ACTIVE = "ACTIVE",
  BLOCKED = "BLOCKED",
  INVITED = "INVITED",
  NEED_TO_BE_CHECKED = "NEED_TO_BE_CHECKED",
}
