import { EventEmitter, Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { PoolService } from './pool.service';
import { IPoolUserFlat } from '../core/models/poolUser.model';
import { UserI } from '../core/models/docAccount';
import { API_CALL, apiRequest } from '../core/api.call.enum';
import { POOL_CALL } from '../core/models/pool.call.enum';
import { IUserFilterItem } from '../core/models/role.model';
import * as _ from 'lodash';
import { USER_SORT_MODE, USER_SORT_TYPE } from '../core/models/enum.types';
@Injectable({
  providedIn: 'root'
})
export class UserService {

  loadedEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
  private _allUsers:UserI[] = []
  private _allPoolUsers: IPoolUserFlat[] = []
  private _lastFilter: string | undefined;
  constructor(
    private api:ApiService,
    private poolApi: PoolService) {
      // this.loadAllUsers()

      this.userFilter.push({
        name: "Basic Account",
        roleIds: [12],
        accountTypes: ["BASIC"],
        excludeIds: [1, 2, 3, 4, 10, 11, 13, 15],
        isFilterActive: true,
      });
  
      this.userFilter.push({
        name: "Test Account",
        roleIds: [14],
        accountTypes: ["BASIC", "CLINIC"],
        excludeIds: [],
        isFilterActive: false,
      });
      this.userFilter.push({
        name: "Test Account Intern",
        roleIds: [15],
        accountTypes: ["BASIC", "CLINIC"],
        excludeIds: [],
        isFilterActive: false,
      });
      this.userFilter.push({
        name: "Clinik User",
        roleIds: [12, 13, 1, 10],
        accountTypes: ["CLINIC"],
        excludeIds: [15],
        isFilterActive: false,
      });
      this.userFilter.push({
        name: "Admin Role",
        roleIds: [1, 2, 3, 4, 11, 13],
        accountTypes: ["BASIC", "CLINIC"],
        excludeIds: [],
        isFilterActive: false,
      });
      this.userFilter.push({
        name: "Abrechnungs Role",
        roleIds: [10],
        accountTypes: ["BASIC", "CLINIC"],
        excludeIds: [],
        isFilterActive: false,
      });
  }


  get users(): UserI[] {
    return this._allUsers
  }


  get allPoolUsers(): IPoolUserFlat[] {
    return this._allPoolUsers
  }


  async loadAllUsers() {
    if(this._lastFilter) {
      this.loadAllUsersWithFilter(this._lastFilter)
    }
    // this.loadedEmitter.emit(true);
    // this._allUsers = [];
    // (await this.api.apiGet(API_CALL.USERS_ADMIN_ALL)).pipe().subscribe(
    //   _data => {
  
    //     console.log("\n\nuserservice >> _data > ", _data)
    //     this._allUsers = _data as UserI[]
    //     console.log("_allUsers> ", this._allUsers)
    //     this.loadedEmitter.emit(false);
    //   }
    // )
  }


  async loadAllUsersWithFilter(filter: string) {
    this._lastFilter = filter;
    this.loadedEmitter.emit(true);
    this._allUsers = [];
    
    var path = apiRequest(API_CALL.USERS_ADMIN_ALL);
    (await this.api.apiGetP(`${path}?filter=${filter}`)).pipe().subscribe(
      _data => {
  
        // console.log("\n\nuserservice >> _data > ", _data)
        this._allUsers = _data as UserI[]
        console.log("_allUsers> ", this._allUsers)
        this.loadedEmitter.emit(false);
      }
    )
  }


  setUser(user:UserI) {
    const index = this._allUsers.findIndex(item => item.uid == user.uid)
    if(index != -1) {
      this._allUsers.splice(index, 1)
    }
    this._allUsers.push(user)
  }

  private async loadAllPoolUsers() {
    await this.poolApi.get(POOL_CALL.GET_POOL_USERS_ALL).then(
      _response => {
        this._allPoolUsers = _response as IPoolUserFlat[]
        console.log('this._allPoolUsers', this.allPoolUsers);
  
        // this.fixUsers();
      }
    )
  }
  userFilter: IUserFilterItem[] = [];
  filterButtonChanged(e: any) {
    console.log(e.value.name);
    const filter = _.snakeCase(e.value.name)//, ' ', '_');
    console.log(filter.toUpperCase());
    
    this.loadAllUsersWithFilter(filter.toUpperCase())
    
  }

  filterGroupSelected: IUserFilterItem = {
    name: "Basic Account",
    roleIds: [12],
    accountTypes: ["BASIC"],
    excludeIds: [1, 2, 3, 4, 10, 11, 13, 15],
    isFilterActive: true,
  };

  get filteredUsers(): UserI[] {
    // return this.us.users;
    // const _rolIds = _.map(this.filterGroupSelected, 'id');
    var usrs: UserI[] = [];
    var usrs2: UserI[] = [];

    usrs = _.filter(
      this.users,
      (item) =>
        item.firstName
          .toLowerCase()
          .includes(this.searchText.trim().toLowerCase()) ||
        item.lastName
          .toLowerCase()
          .includes(this.searchText.trim().toLowerCase()) ||
        item.email.toLowerCase().includes(this.searchText.trim().toLowerCase())
    );
    usrs2 = _.filter(
      usrs,
      (user) =>
        _.intersection(user.roles, this.filterGroupSelected.roleIds).length >
          0 &&
        _.intersection(user.roles, this.filterGroupSelected.excludeIds)
          .length == 0 &&
        this.filterGroupSelected.accountTypes.includes(
          user.accountType.toString()
        )
    );
   
    let sortString = 'email';
    switch (this.userSortMode) {
      case USER_SORT_MODE.EMAIL:
        sortString = 'email';
        break
      case USER_SORT_MODE.FIRSTNAME:
        sortString = 'firstName';
        break
      case USER_SORT_MODE.LASTNAME:
        sortString = 'lastName';
        break
    }
    return _.orderBy(usrs2, [
      sortString
    ], [this.userSortType === USER_SORT_TYPE.ASC ? 'asc' : 'desc' ]);
    // return _.orderBy( this.us.users, [
    //   sortString
    // ], [this.userSortType === USER_SORT_TYPE.ASC ? 'asc' : 'desc' ]);
    //return usrs2;
  }
  SORTMODES = USER_SORT_MODE
  SORTYPES = USER_SORT_TYPE

  userSortType: USER_SORT_TYPE = USER_SORT_TYPE.ASC
  userSortMode:USER_SORT_MODE = USER_SORT_MODE.EMAIL
  searchText = ""
}
