import { Component, Inject, OnInit } from '@angular/core';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { UserI } from 'src/app/core/models/docAccount';
import { POOL_CALL } from 'src/app/core/models/pool.call.enum';
import { IPoolUser, IPoolUserFlat } from 'src/app/core/models/poolUser.model';
import { ADD_USER_TO_POOL_OVERLAY_DATA } from 'src/app/core/models/user-clinic-select-overlay-data.model';
import { PoolService } from 'src/app/services/pool.service';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';

interface IUserTemp {
  uid: string;
  email: string;
  firstName?:string
  lastName?:string
  isInPool?:string
  selected:boolean
}

@Component({
  selector: 'dxfx-add-user-to-pool-overlay',
  templateUrl: './add-user-to-pool-overlay.component.html',
  styleUrls: ['./add-user-to-pool-overlay.component.scss']
})
export class AddUserToPoolOverlayComponent implements OnInit {
  poolUsersColumns = ["email", "lastName", "firstName",  "action"]
  poolUserDataSource : any

  // private _users:UserI[] = []
  private _selectedUsers:UserI[] = []

  private _alreadyIncluded:IPoolUser[] = []

  private _allPoolUsers: IPoolUserFlat[] = []

  availableUsers: IUserTemp[] = []
  selectedPoolId?:string

  constructor(
    public ui:UiService,
    public us:UserService,
    private poolApi: PoolService,
    public dialogRef: MatDialogRef<AddUserToPoolOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ADD_USER_TO_POOL_OVERLAY_DATA
 ) {
  this.selectedPoolId = data.selected_pool_id
  this._alreadyIncluded = data.alreadyIncluded
 }

  ngOnInit(): void {
    console.log('init');
    this.loadUsers()
  }


  // get users(): UserI[] {
  //   if ( this.ui.users ) {
  //     return this.ui.users
  //   }
  //   return []
  // }

  // selectionChanged(e:MatCheckboxChange, u:IUserTemp){

  //   if( e.checked) {
  //     // this._selectedUsers.push(u)
  //   } else {
  //     // const _index = this._selectedUsers.findIndex(item => item.uid == u.uid)
  //     // if(_index != -1){
  //     //   this._selectedUsers.splice(_index, 1)
  //     // }
  //   }
  //   //console.log('<', this._selectedUsers);
  //   // console.log('<', this.availableUsers);
  // }

  save(){
    let _selected:string[] = []
    this.availableUsers.forEach(item => {
      if (item.selected === true) {
        _selected.push(item.uid)
      }
    })
    // console.log('_selected ', _selected);

    this.dialogRef.close(_selected)
  }
  close(){
    this.dialogRef.close([])
  }




  private async loadUsers() {
    await this.poolApi.get(POOL_CALL.GET_POOL_USERS_ALL).then(
      _response => {
        this._allPoolUsers = _response as IPoolUserFlat[]

        this.fixUsers();
      }
    )
  }

  private fixUsers() {
    this.availableUsers = []
    const _users = this.us.users

    for(var i = 0; i < _users.length; i ++) {
      const _user = _users[i];

      if(this._alreadyIncluded.findIndex(item => item.uid == _user.uid) == -1){

        let _userTemp: IUserTemp = {
          uid: _user.uid,
          email: _user.email!,
          selected: false,
          firstName: _user.firstName,
          lastName: _user.lastName,
        }

        const _index = this._allPoolUsers.findIndex(item => item.uid == _user.uid && item.pool_id != this.selectedPoolId)

        if ( _index != -1) {
          _userTemp.isInPool = `Bereits in ${this._allPoolUsers[_index].title}`

        }

        this.availableUsers.push(_userTemp)

      }

    }
    this.poolUserDataSource = new MatTableDataSource<IUserTemp>(this.availableUsers)
  }

  applyFilter(filterValue?:any) {
    if ( filterValue && filterValue.value){
      // console.log('filter' , filterValue.value);
      this.poolUserDataSource.filter = filterValue.value.trim().toLowerCase();
    }


    // this.poolUserDataSource.filter = filterValue.trim().toLowerCase();
    // if(filterValue.length == 0) {
    //   this.availableUsers;
    //   return
    // }
    // this.availableUsers = this.availableUsers.filter(item => item.name.toLowerCase().includes(filterValue.toLowerCase()));
    // /* this.filteredTemplates.filter(item => item.name.startsWith(filterValue)); */
  }
}
