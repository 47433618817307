import { Component, OnDestroy, OnInit } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { UserI, USER_STATE_ENUM, USER_APP_ACCESS_TYPE } from "src/app/core/models/docAccount";
import { ApiService } from "src/app/services/api.service";
import * as _ from "lodash";
import { AuthService } from "src/app/services/auth.service";
import { Router } from "@angular/router";
import { UiService } from "src/app/services/ui.service";
import {
  InviteUserOverlayData,
  InviteUserOverlayDataMODE,
  SettingsUsersOvlerayDataI,
  SETTINGS_USER_OVERLAY_MODE,
} from "src/app/core/models/user-clinic-select-overlay-data.model";
import { UserDetailOverlayComponent } from "src/app/overlays/user-detail-overlay/user-detail-overlay.component";
import { InviteUserOverlayComponent } from "src/app/overlays/invite-user-overlay/invite-user-overlay.component";
import { ChangeClinicSectorForUserOverlayData } from "src/app/core/models/overlay.data.model";
import { ChangeUserAccessComponent } from "src/app/overlays/change-user-access/change-user-access.component";
import { DatePipe } from "@angular/common";
import { UserService } from "src/app/services/user.service";
import {
  APP_ACCESS_ENUM,
  IUserAccessType,
} from "src/app/core/models/user.states.model";
import { IUserFilterItem } from "src/app/core/models/role.model";
import { Subscription } from "rxjs";
import { USER_SORT_MODE, USER_SORT_TYPE } from "src/app/core/models/enum.types";
// enum USER_SORT_MODE {
//   EMAIL_DESC = 'EMAIL_DESC',
//   EMAIL_ASC = 'EMAIL_ASC',
//   FIRSTNAME_DESC = 'FIRSTNAME_DESC',
//   FIRSTNAME_ASC = 'FIRSTNAME_ASC',
//   LASTNAME_DESC = 'LASTNAME_DESC',
//   LASTNAME_ASC = 'LASTNAME_ASC',
//   STATUS_DESC = 'STATUS_DESC',
//   STATUS_ASC = 'STATUS_ASC',
//   PLAN_DESC = 'PLAN_DESC',
//   PLAN_ASC = 'PLAN_ASC',
//   LASTLOGIN_DESC = 'LASTLOGIN_DESC',
//   LASTLOGIN_ASC = 'LASTLOGIN_ASC',
// }

@Component({
  selector: "dxfx-users",
  templateUrl: "./users.component.html",
  styleUrls: ["./users.component.scss"],
  providers: [DatePipe],
})
export class UsersComponent implements OnInit, OnDestroy {
  // summe = 0;
  filterGroupSelected: IUserFilterItem = {
    name: "Basic Account",
    roleIds: [12],
    accountTypes: ["BASIC"],
    excludeIds: [1, 2, 3, 4, 10, 11, 13, 15],
    isFilterActive: true,
  };

   isloading = true;
  showAdmins = true;
  showGoaAdmin = true;

  USER_STATE_ENUMS = USER_STATE_ENUM;
  accessTypes = APP_ACCESS_ENUM;

  searchText = "";
  dataSourceManager: any;
  tableMode = "none";
  filterstring = "";
  onlyClinicUser = false;
  fullView = false;
  currentPool: any;
  loadedSubscription: Subscription | undefined
  userFilter: IUserFilterItem[] = [];
  USER_APP_ACCESS_TYPES = USER_APP_ACCESS_TYPE
  constructor(
    public ui: UiService,
    public us: UserService,
    private dialog: MatDialog,
    private authS: AuthService
  ) {
    // this.userFilter.push({
    //   name: "Basic Account",
    //   roleIds: [12],
    //   accountTypes: ["BASIC"],
    //   excludeIds: [1, 2, 3, 4, 10, 11, 13, 15],
    //   isFilterActive: true,
    // });

    // this.userFilter.push({
    //   name: "Test Account",
    //   roleIds: [14],
    //   accountTypes: ["BASIC", "CLINIC"],
    //   excludeIds: [],
    //   isFilterActive: false,
    // });
    // this.userFilter.push({
    //   name: "Test Account Intern",
    //   roleIds: [15],
    //   accountTypes: ["BASIC", "CLINIC"],
    //   excludeIds: [],
    //   isFilterActive: false,
    // });
    // this.userFilter.push({
    //   name: "Clinik User",
    //   roleIds: [12, 13, 1, 10],
    //   accountTypes: ["CLINIC"],
    //   excludeIds: [15],
    //   isFilterActive: false,
    // });
    // this.userFilter.push({
    //   name: "Admin Role",
    //   roleIds: [1, 2, 3, 4, 11, 13],
    //   accountTypes: ["BASIC", "CLINIC"],
    //   excludeIds: [],
    //   isFilterActive: false,
    // });
    // this.userFilter.push({
    //   name: "Abrechnungs Role",
    //   roleIds: [10],
    //   accountTypes: ["BASIC", "CLINIC"],
    //   excludeIds: [],
    //   isFilterActive: false,
    // });
  }

  // SORTMODES = USER_SORT_MODE
  // SORTYPES = USER_SORT_TYPE

  // userSortType: USER_SORT_TYPE = USER_SORT_TYPE.ASC
  // userSortMode:USER_SORT_MODE = USER_SORT_MODE.EMAIL

  ngOnDestroy(): void {
    if (this.loadedSubscription) { 
      this.loadedSubscription.unsubscribe()
    }
  }
  get hasFAC():boolean {
    return this.authS.NAVIGATION.includes('USER_DELETE')
  }
  // changeSort(mode: USER_SORT_MODE) {
  //   if (this.userSortMode !== mode) {
  //     this.userSortMode = mode;
  //   } else {
  //     this.userSortType = this.userSortType === USER_SORT_TYPE.ASC ? USER_SORT_TYPE.DESC : USER_SORT_TYPE.ASC;
  //   }
  // }

  ngOnInit(): void {
    console.log("USERS");
    this.us.loadAllUsersWithFilter('BASIC_ACCOUNT');
    // this.filterGroupSelected = this.userFilter[0];
    setTimeout(() => {
      this.ui.currentPage.emit("users");
    }, 800);

    this.loadedSubscription = this.us.loadedEmitter.subscribe(
      (x:boolean) => {
        this.isloading = x
      }
    )
  }

  switchView() { 
    if(this.authS.NAVIGATION.includes('USER_DELETE')) {

      this.fullView = !this.fullView
    } else {
      this.fullView = false;
    }
  }

  applyFilter(filterValue?: any) {
    // if ( filterValue && filterValue.value){
    //   this.filterstring = filterValue.value
    //   usrs = _.filter(this.us.users,
    //     item => item.firstName.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   || item.lastName.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   || item.email.toLowerCase().includes(this.filterstring.trim().toLowerCase())
    //   )
    // }
  }

  state(user: UserI): IUserAccessType | null {
    const index = this.ui.allUserAccessTypes.findIndex(
      (item) => item.id == user.user_access_type_id
    );
    if (index != -1) {
      return this.ui.allUserAccessTypes[index];
    }
    return null;
  }

  async changeUserAccess(e: any, user: UserI) {
    e.stopPropagation();
    e.preventDefault();
    const oldata: ChangeClinicSectorForUserOverlayData = {
      isClinicMode: false,
      baseUser: user,
    };
    this.dialog
      .open(ChangeUserAccessComponent, {
        width: "400px",
        data: oldata,
        backdropClass: "backdrop-background",
      })
      .afterClosed()
      .subscribe((result) => {
        if (result != null && result == "reload") {
          this.us.loadAllUsers();
        }
      });
  }

  inviteNewUser() {
    const _olData: InviteUserOverlayData = {
      mode: InviteUserOverlayDataMODE.SIMPLE_INVITE,
    };
    this.dialog
      .open(InviteUserOverlayComponent, {
        width: "80%",
        maxWidth: "1400px",
        height: "560px",
        backdropClass: "backdrop-background",
        data: _olData,
      })
      .afterClosed()
      .subscribe((data) => {
        this.us.loadAllUsers();
      });
  }

  select(e: UserI) {
    const _overlayData: SettingsUsersOvlerayDataI = {
      mode: SETTINGS_USER_OVERLAY_MODE.EDIT_MODE,
      // managedAccount: _newInVite,
      user: e,
    };
    this.dialog
      .open(UserDetailOverlayComponent, {
        width: "80%",
        maxWidth: "1400px",
        minHeight: "360px",
        backdropClass: "backdrop-background",
        data: _overlayData,
      })
      .afterClosed()
      .subscribe((data) => {
        if (data) {
          this.us.loadAllUsers();
        }
      });
  }

  userPoolFilterChanged(e: any) {}

  clinicName(p: UserI): string {
    var _out = "";
    return _out;
  }

  // filteredUsers: UserI[] = []
  
  get summe(): number {
    if (this.us.filteredUsers.length > 0) {
      return this.us.filteredUsers.length * 99
    }
    return 0
  }
  get summeRegistered(): number {
    if (this.us.filteredUsers.length > 0) {
      let regisgteredUsers = this.us.filteredUsers.filter(item => item.activity)
      if (regisgteredUsers.length > 0) {
        
        return regisgteredUsers.length * 99
      }
    }
    return 0
  }
  filterButtonChanged(e: any) {
    console.log(e.value.name);
    const filter = _.snakeCase(e.value.name)//, ' ', '_');
    console.log(filter.toUpperCase());
    
    this.us.loadAllUsersWithFilter(filter.toUpperCase())
    
  }

  filterIncludesRole(role: any): boolean {
    return this.filterGroupSelected == role.id;
  }
  download() {
    // this.filteredUsers.forEach((elment: UserI) => {
    //   console.log(elment);
      
    // })
    this.downloadFile(this.us.filteredUsers, 'jsontocsv');
    // this.downloadFile(this.jsonData, 'jsontocsv');
}
  downloadFile(data: UserI[], filename = 'data') {
    let csvData = this.ConvertToCSV(data,
        [ 'uid',
          'email', 
         'lastName',
         'firstName', 
         'subscription_plan_id']);
    console.log(csvData)
    let blob = new Blob(['\ufeff' + csvData],
        { type: 'text/csv;charset=utf-8;' });
    let dwldLink = document.createElement("a");
    let url = URL.createObjectURL(blob);
    let isSafariBrowser = navigator.userAgent.indexOf('Safari') !=
        -1 && navigator.userAgent.indexOf('Chrome') == -1;
    if (isSafariBrowser) {

        // If Safari open in new window to
        // save file with random filename.
        dwldLink.setAttribute("target", "_blank");
    }
    dwldLink.setAttribute("href", url);
    dwldLink.setAttribute("download", filename + ".csv");
    dwldLink.style.visibility = "hidden";
    document.body.appendChild(dwldLink);
    dwldLink.click();
    document.body.removeChild(dwldLink);
}

ConvertToCSV(objArray: UserI[], headerList: any) {
    let array = typeof objArray !=
        'object' ? JSON.parse(objArray) : objArray;
    let str = '';
    let row = 'S.No,';

    for (let index in headerList) {
        row += headerList[index] + ',';
    }
    row = row.slice(0, -1);
    str += row + '\r\n';
    for (let i = 0; i < array.length; i++) {
        let line = (i + 1) + '';
        for (let index in headerList) {
            let head = headerList[index];
            line += ',' + array[i][head];
        }
        str += line + '\r\n';
    }
    return str;
}
jsonData = [
  {
      name: "Anil Singh",
      age: 33,
      average: 98,
      approved: true,
      description: "I am active blogger and Author."
  },
  {
      name: 'Reena Singh',
      age: 28,
      average: 99,
      approved: true,
      description: "I am active HR."
  },
  {
      name: 'Aradhya',
      age: 4,
      average: 99,
      approved: true,
      description: "I am engle."
  },
];
}
//HsrmyG3ez2b#

