import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { API_CALL, apiRequest } from 'src/app/core/api.call.enum';
import { UserI } from 'src/app/core/models/docAccount';
import { ChangeClinicSectorForUserOverlayData } from 'src/app/core/models/overlay.data.model';
import { IUserBaseRole } from 'src/app/core/models/role.model';
import { IUserAccessType } from 'src/app/core/models/user.states.model';
import { ChangeSubscriptionPlanComponent } from 'src/app/overlays/change-subscription-plan/change-subscription-plan.component';
import { ChangeUserAccessComponent } from 'src/app/overlays/change-user-access/change-user-access.component';
import { ApiService } from 'src/app/services/api.service';
import { AuthService } from 'src/app/services/auth.service';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'dxfx-user-list-row',
  templateUrl: './user-list-row.component.html',
  styleUrls: ['./user-list-row.component.scss']
})
export class UserListRowComponent implements OnInit {
  currentRole?:any
  @Input() fullView: boolean = false
  @Input() user!: UserI
  @Output() selected: EventEmitter<UserI> = new EventEmitter<UserI>()
  constructor( 
    public ui:UiService,
    public us:UserService,
    private datePipe: DatePipe,
    private api:ApiService, private dialog:MatDialog, private authS: AuthService) { }

  ngOnInit(): void {
    console.log("init");
    // this.currentRole = this.ui.baseUserRoles.find(item => item.role_key == this.user.roleKey)
    
  }
  openStatus() {
    const oldata: ChangeClinicSectorForUserOverlayData = {
      isClinicMode: true,
      baseUser: this.user
    }
    this.dialog.open(ChangeUserAccessComponent, {
      width: '400px',
      data: oldata,
      backdropClass: 'backdrop-background'
    }).afterClosed().subscribe((result) => {
      if (result != null && result == "reload") {
        this.us.loadAllUsers()
      }
    });


  }
  changeSubscription() {

    this.dialog.open(ChangeSubscriptionPlanComponent, {
      width: '400px',
      data: this.user,
      backdropClass: 'backdrop-background'
    }).afterClosed().subscribe((result) => {
      if (result != null && result == "reload") {
        this.us.loadAllUsers()
      }
    });


  }
  getRoleColorBorder(role:IUserBaseRole) : string {
    if(this.hasRole(role.id) && this.canSet(role) ) {
      return `#${role.color}`
    } else {
      return `#${role.color}`
    }
    
  }
  getRoleColor(role:IUserBaseRole) : string {
    if(this.hasRole(role.id) && this.canSet(role) ) {
      return `#${role.color}`
    } else  if(this.hasRole(role.id) && !this.canSet(role) )  {
      return `#${role.color}`
    } else {
      return `#${role.color}`
    }
  }
  onSelect() {
    this.selected.emit(this.user)
  }
  get state() : IUserAccessType | null {
    const index = this.ui.allUserAccessTypes.findIndex(item => item.id == this.user.user_access_type_id)
    if (index != -1) {
      return this.ui.allUserAccessTypes[index]
    }
    return null
  }
  async toggleRole(roleId: number) {

    const index = this.ui.baseUserRoles.findIndex(item => item.id == roleId)
    if(index != -1) {
      const role = this.ui.baseUserRoles[index]
      if(!role.canSet) { return }
      const dto = {
        roleId: roleId,
        uid: this.user.uid
      }
      if(this.hasRole(roleId)){
        const p = apiRequest(API_CALL.REMOVE_ROLE_FOR_USER)
        this.api.apiPost(p,dto)
        console.log("remove role");
      } else {
        console.log("add role");
        const p = apiRequest(API_CALL.ADD_ROLE_FOR_USER)
        this.api.apiPost(p,dto)
        this.user.roles!.push(roleId  )
          
      }
   
      console.log(dto);
    }

    
  }
  hasRole(role:number) :boolean {
    if(!this.user.roles) {
      return false
    }
    return this.user.roles!.includes(role)
  }
  canSet(role:IUserBaseRole) :boolean {
    const index = this.ui.baseUserRoles.findIndex(item => item.id == role.id)
    if(index != -1) {
      return this.ui.baseUserRoles[index].canSet == 1
      
    }
    return false
  }

  async changeUserAccess(e:any, user:UserI){
    e.stopPropagation();
    e.preventDefault();

    const oldata: ChangeClinicSectorForUserOverlayData = {
      isClinicMode: false,
      baseUser: user
    }
    this.dialog.open(ChangeUserAccessComponent, {
      width: '400px',
      data: oldata,
      backdropClass: 'backdrop-background'
    }).afterClosed().subscribe((result) => {
      if (result != null && result == "reload") {
        this.us.loadAllUsers()
      }
    });


  }
 /*  changeBaseRole(user:UserI, role: any) {
    // console.log(role);
    this.saveRole(role.base_role_key)
  } */
  private async  saveRole (role: number) {
    const _dto = {
      uid: this.user.uid,
      user_access_type_id: role,
      // user_access_type_id: this.selectedUserStateTypeId,
      // user_state_type_message_id: this.selectedUserStateTypeMessageId,
    }
    console.log('_dto', _dto);
    // return
    const _path = apiRequest(API_CALL.POST_CHANGE_ACCESS_FOR_USER)
    const _result = await this.api.apiPost(_path,{
      dto: _dto
    })
    // console.log(_result)
    this.us.setUser(_result as UserI)
    // this.dialogRef.close("reload")
  }
  isClinicUser(uid: string) :boolean {
    return this.us.allPoolUsers.findIndex(item => item.uid == uid) != -1
  }
  openSub(e:any){
    e.stopPropagation();
    e.preventDefault();
  }
  selectedSectorChanged(e:any) {
    console.log('e', e.value);
    this.saveRole(e.value.base_role_key)
   
  }
  
}
