import '@angular/common/locales/global/de';
import { NgModule, LOCALE_ID } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './pages/app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { SignInComponent } from './pages/sign-in/sign-in.component';
import { authInterceptorProviders } from './core/auth.interceptor';
import { MaterialModule } from './core/material/material.module';
import { HttpClientModule } from '@angular/common/http';
// import { AngularFireModule } from '@angular/fire/compat';
// import { environment } from 'src/environments/environment';
import { AngularFireAuthModule } from '@angular/fire/compat/auth';
import { AuthService } from './services/auth.service';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { DxfxIconButtonComponent } from './widgets/dxfx-icon-button/dxfx-icon-button.component';
import { EmailDetailComponent } from './pages/email-detail/email-detail.component';
import { EmailTemplateOverviewComponent } from './pages/email-template-overview/email-template-overview.component';
import { MainMenuItemHzComponent } from './widgets/main-menu-item-hz/main-menu-item-hz.component';

import { TemplatePoolComponent } from './pages/template-pool/template-pool.component';
import { UsersComponent } from './pages/users/users.component';
import { UiService } from './services/ui.service';
import { ClinicCompComponent } from './widgets/clinic-comp/clinic-comp.component';
import { SelectUserForClinicComponent } from './widgets/select-user-for-clinic/select-user-for-clinic.component';

import { UserDetailOverlayComponent } from './overlays/user-detail-overlay/user-detail-overlay.component';
import { DfIconButtonComponent } from './widgets/df-icon-button/df-icon-button.component';
import { AddressOverlayComponent } from './overlays/address-overlay/address-overlay.component';
import { ClinicComponent } from './pages/clinic/clinic.component';
import { ClinicDetailComponent } from './pages/clinic-detail/clinic-detail.component';
import { TextEditOverlayComponent } from './overlays/text-edit-overlay/text-edit-overlay.component';
import { ClinicSectorOverlayComponent } from './overlays/clinic-sector-overlay/clinic-sector-overlay.component';
import { AddUserToPoolOverlayComponent } from './overlays/add-user-to-pool-overlay/add-user-to-pool-overlay.component';
import { InviteUserOverlayComponent } from './overlays/invite-user-overlay/invite-user-overlay.component';
import { AlertOverlayComponent } from './overlays/alert-overlay/alert-overlay.component';
import { CreateNewSectorOverlayComponent } from './overlays/create-new-sector-overlay/create-new-sector-overlay.component';
import { ChangeClinicSectorForUserComponent } from './overlays/change-clinic-sector-for-user/change-clinic-sector-for-user.component';
import { ChangeUserAccessComponent } from './overlays/change-user-access/change-user-access.component';
import { TemplateReportsComponent } from './pages/template-reports/template-reports.component';
import { TemplateChartComponent } from './pages/template-reports/template-chart/template-chart.component';

import { AccountingDetailOverlayComponent } from './overlays/accounting-detail-overlay/accounting-detail-overlay.component';
import { UserListRowComponent } from './widgets/user-list-row/user-list-row.component';
import { ChangeSubscriptionPlanComponent } from './overlays/change-subscription-plan/change-subscription-plan.component';
import { ClinicAddressComponent } from './widgets/clinic-address/clinic-address.component';
import { CgdatePipe } from './cgdate.pipe';
import { UserAuthReportComponent } from './pages/user-auth-report/user-auth-report.component';
import { CgcurrencyPipe } from './services/cgcurrency.pipe';
import { DatePipe } from '@angular/common';
import { UserListHeaderComponent } from './pages/users/user-list-header/user-list-header.component';
import { UserListHeaderRadioGroupComponent } from './pages/users/user-list-header-radio-group/user-list-header-radio-group.component';
import { TextBoxComponent } from './widgets/text-box/text-box.component';
import { PadnextFachgebietSelectionComponent } from './widgets/padnext-fachgebiet-selection/padnext-fachgebiet-selection.component';


@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    SignInComponent,

    DxfxIconButtonComponent,
    EmailDetailComponent,
    EmailTemplateOverviewComponent,
    MainMenuItemHzComponent,

    TemplatePoolComponent,
    UsersComponent,
    ClinicCompComponent,
    SelectUserForClinicComponent,
    UserDetailOverlayComponent,
    DfIconButtonComponent,
    AddressOverlayComponent,
    ClinicComponent,
    ClinicDetailComponent,
    TextEditOverlayComponent,
    ClinicSectorOverlayComponent,
    AddUserToPoolOverlayComponent,
    InviteUserOverlayComponent,
    AlertOverlayComponent,
    CreateNewSectorOverlayComponent,
    ChangeClinicSectorForUserComponent,
    ChangeUserAccessComponent,
    TemplateReportsComponent,
    TemplateChartComponent,
    AccountingDetailOverlayComponent,
    UserListRowComponent,
    ChangeSubscriptionPlanComponent,
    ClinicAddressComponent,
    CgdatePipe,
    UserAuthReportComponent,
    CgcurrencyPipe,
    UserListHeaderComponent,
    UserListHeaderRadioGroupComponent,
    TextBoxComponent,
    PadnextFachgebietSelectionComponent,
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    MaterialModule,
    HttpClientModule,
    // AngularFireModule.initializeApp(environment.firebase),
    AngularFireAuthModule,
    ReactiveFormsModule,
    FormsModule
  ],
  providers: [
    DatePipe, 
    CgcurrencyPipe, 
    { provide: LOCALE_ID,useValue: 'de'},
    AuthService ,authInterceptorProviders, UiService],

  bootstrap: [AppComponent]
})
export class AppModule { }
