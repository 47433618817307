import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { API_REQUESTS, ClinicApi } from 'src/app/core/clinic.api';
import { API_CALL_CLINIC } from 'src/app/core/models/clinic.call.enum';
import { IAddress } from 'src/app/core/models/clinic.model';

@Component({
  selector: 'dxfx-address-overlay',
  templateUrl: './address-overlay.component.html',
  styleUrls: ['./address-overlay.component.scss']
})
export class AddressOverlayComponent implements OnInit {

  __pool_id =  ""
  constructor(
    private dialogRef:MatDialogRef<AddressOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data:string,
    private clinicAPI: ClinicApi) {
      this.__pool_id = data;
    }

  ngOnInit(): void {
    console.log('AddressOverlayComponent');

  }
  address:IAddress = {
    id:-1,

    abteilung:"",
    arzt_role:"",
    bank_name:"",
    behandelnder_arzt:"",
    bic:"",
    city:"",
    facharzt_bezeichnung:"",
    iban:"",
    klinikName:"",
    kontoinhaber:"",
    streetName:"",
    streetNumber:"",
    ust_nr:"",
    zip:"",
    isMainAddress:false,
    isClinic: true,
    clinicLegalMain:"",
    clinicInvoiceProviderFirst:"",
    clinicInvoiceProviderSecond:"",
    clinicInvoiceProviderThird:"",
    clinicInvoiceProviderFourth:""
  }

  close(){
    this.dialogRef.close()
  }
  async save() {
    const _path = API_REQUESTS(API_CALL_CLINIC.CREATE_ADDRESS)
    await this.clinicAPI.post(_path,{ payload: this.address, pool_id: this.__pool_id})
    this.dialogRef.close()
  }
}
