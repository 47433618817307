import {HTTP_INTERCEPTORS,  HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { TokenstorageService } from "../services/tokenstorage.service";
const TOKEN_HEADER_KEY = 'x-access-token';
@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(private token:TokenstorageService){

  }
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let authReq = req;
    //const token = this.token.getToken();
    //authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token.token) })
     if(req.url.startsWith('https://api.ipify') || req.url.includes('/lost-password')  || req.url.includes('/sign-in')){
     authReq = req.clone({  })
     } else {
      const token = this.token.getToken();

      if (token != null) {
        authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) })
      }
     }

    return next.handle(authReq);
  }
  //   let authReq = req;
  //   const token = this.token.getToken();
  //   if(token){
  //     authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) })
  //   }
  //    if(req.url.startsWith('https://api.ipify') || req.url.includes('/accounts') || req.url.includes('/lost-password')){
  //    authReq = req.clone({  })
  //    } else {
  //     const token = this.token.getToken();

  //     if (token != null) {
  //       authReq = req.clone({ headers: req.headers.set(TOKEN_HEADER_KEY, token) })
  //     }
  //    }

  //   return next.handle(authReq);
  // }

}

export const authInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }
];
