import { Component, Input, OnInit } from '@angular/core';
import { IAddress } from 'src/app/core/models/clinic.model';

@Component({
  selector: 'dxfx-clinic-address',
  templateUrl: './clinic-address.component.html',
  styleUrls: ['./clinic-address.component.scss']
})
export class ClinicAddressComponent implements OnInit {

  @Input() address: IAddress | undefined
  showSaveButton = false
  currentAddress?:IAddress
  constructor() { }

  ngOnInit(): void {
  
  this.currentAddress = this.address
  }


  modelChangeFn(e:any){
    this.showSaveButton = true
  }
}
