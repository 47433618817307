import { Component, Inject, OnInit, ViewChild } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material/dialog";
import { MatList } from "@angular/material/list";
import {
  AreaOfExpertise,
  AreaOfExpertiseUserI,
  ManagedAccountI,
  SettingsUsersOvlerayDataI,
  SETTINGS_USER_OVERLAY_MODE,
} from "src/app/core/models/user-clinic-select-overlay-data.model";
import {
  UserApi,
  USER_API_CALL,
  USER_API_REQUESTS,
} from "src/app/services/api.user.service";
import * as _ from "lodash";
import {
  ACCOUNT_TYPE_BASE,
  USER_STATE_ENUM,
} from "src/app/core/models/docAccount";
import { ISubScriptionPLan, PadNextFachArztFull, PadNextFachArztItem } from "src/app/core/models/role.model";
import { UiService } from "src/app/services/ui.service";
import { UserI } from "../../core/models/docAccount";
import { AlertOverlayComponent } from "../alert-overlay/alert-overlay.component";
import { AlertActionI } from "src/app/core/models/alert/ialert.model";
@Component({
  selector: "dxfx-user-detail-overlay",
  templateUrl: "./user-detail-overlay.component.html",
  styleUrls: ["./user-detail-overlay.component.scss"],
})
export class UserDetailOverlayComponent implements OnInit {
  @ViewChild("_options") _options: MatList | undefined;
  areaOfExpertiseUser: AreaOfExpertiseUserI[] = [];
  finalAreaOfExpertiseUser: AreaOfExpertiseUserI[] = [];
  areaOfExpertisAll: AreaOfExpertise[] = [];
  currentAccount: UserI; //ManagedAccountI;
  overlayMode: SETTINGS_USER_OVERLAY_MODE;

  selectedOptions: ISubScriptionPLan[] = [];
  currentPlan: any;
  isSaving = false;
  isLoading = false;
  showErrorMessage: boolean = false;
  errorMessage = null;
  newAccount: ManagedAccountI = {
    email: "",
    firstName: "",
    lastName: "",
    title: "",
    medical_specialty_designation: "",
    fachgebiete: [],
  };

  doxFoxAccess = true;
  scsAccess = false;
  currenPlanId = 1;
  //  showAdminButton = false
  showSaveButton = false;
  USER_STATE_ENUMS = USER_STATE_ENUM;
  constructor(
    private dialog: MatDialog,
    public ui: UiService,
    private userApi: UserApi,
    private dialogRef: MatDialogRef<UserDetailOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: SettingsUsersOvlerayDataI
  ) {
    this.overlayMode = data.mode;
    this.currentAccount = data.user;
  }

  ngOnInit(): void {
    console.log('user details overlay init');
    console.log( this.currentAccount);
    
    this.selectedOptions = [this.ui.subscriptionPlan[0]];
    this.loadData();
  }

  close() {
    this.dialogRef.close();
  }

  get windowTitle(): string {
    return this.overlayMode == SETTINGS_USER_OVERLAY_MODE.INVITE_MODE
      ? "Benutzer einladen"
      : "Benutzer bearbeiten";
  }

  onSubscriptionChanged(e: any) {
    console.log(e);
    this.currenPlanId = e.value;
    this.showSaveButton = true;
  }

  get subscriptionPlan(): ISubScriptionPLan[] {
    let allowd =
      this.currentAccount.accountType == ACCOUNT_TYPE_BASE.CLINIC
        ? [4, 5]
        : [1, 2, 3];
    return this.ui.subscriptionPlan.filter((item) => allowd.includes(item.id));
  }

  async checkBoxChanged(e: any, v: AreaOfExpertise) {
    //console.log('e ', e.checked)
    // console.log(v)
    const _index = this.areaOfExpertiseUser.findIndex(
      (item) => item.fachgebiet_id == v.id
    );
    if (e.checked) {
      if (_index == -1) {
        console.log("ADD");

        // let is_primary = this.isPrimaryUserExpertise(this.areaOfExpertiseUser[_index])
        const _path = USER_API_REQUESTS(
          USER_API_CALL.POST_AREAS_OF_EXPERTISE_BY_UID,
          this.currentAccount.uid
        );
        await this.userApi.post(_path, {
          id: v.id,
          is_primary: !this.hasAnyExpertise,
        });

        this.areaOfExpertiseUser.push({
          fachgebiet_id: v.id,
          id: -1,
          is_active: true,
          is_primary: !this.hasAnyExpertise,
          name: v.name,
          short: v.short,
        });
      }
    } else {
      if (_index != -1) {
        console.log("REMOVE");
        const _removeId = this.areaOfExpertiseUser[_index].id;
        // console.log('_removeId' , _removeId);
        await this.userApi.getP(
          USER_API_CALL.DELETE_AREAS_OF_EXPERTISE_BY_UID,
          _removeId.toString()
        );
        this.areaOfExpertiseUser.splice(_index, 1);
      }
    }
    //console.log('v ', v)
  }

  newAccountCheckBoxChanged(e: any, v: AreaOfExpertise) {
    // console.log('e ', e.checked)
    const _index = this.newAccount.fachgebiete.findIndex(
      (item) => item.fachgebiet_id == v.id
    );
    if (e.checked) {
      if (_index == -1) {
        console.log("ADD");
        this.newAccount.fachgebiete.push({
          fachgebiet_id: v.id,
          // id: -1,
          // is_active: true,
          is_primary: !this.newAccountHasAnyExpertise,
          name: v.name,
          short: v.short,
        });
      }
    } else {
      if (_index != -1) {
        console.log("REMOVE");
      }
    }
    console.log(this.newAccount);
  }
  get saveEnabled(): boolean {
    return true;
  }
  get inviteButtonEnabled(): boolean {
    if (!this.newAccount) {
      return false;
    }
    if (
      this.newAccount.lastName != "" &&
      this.newAccount.lastName!.length > 1 &&
      this.newAccount.firstName != "" &&
      this.newAccount.firstName!.length > 1 &&
      this.newAccount.email != "" &&
      this.newAccount.email.length > 2 &&
      this.newAccount.fachgebiete.length > 0
    ) {
      return true;
    }
    return false;
    //return this.newAccount.doc_name.length > 3 && this.newAccount.email.length > 5 && (this.doxFoxAccess || this.scsAccess)
  }

  private async loadData() {
    if (this.currentAccount && this.currentAccount.uid != "-1") {
      // console.log(this.currentAccount);
      if(this.currentAccount.fachgebietPadNext) {
        
        const index1 = this.padNextFachFull.findIndex(item => item.id == this.currentAccount.fachgebietPadNext?.fachgebietId)
        if(index1 != -1) {
          this.padNextFachBereichSelected = this.padNextFachFull[index1]

          if(this.padNextFachBereichSelected) {

            const index2 = this.padNextFachBereichSelected.items.findIndex(item => item.id == this.currentAccount.fachgebietPadNext?.facharzt_pad_next_fachgebiete_id)
            if(index2 != -1) {
              this.padNextFachArztBereichSelected = this.padNextFachBereichSelected.items[index2]
            }
          }
        }
      }
      const _data: any = await this.userApi.getP(
        USER_API_CALL.GET_AREAS_OF_EXPERTISE_BY_UID,
        this.currentAccount.uid!.toString()
      );
      this.areaOfExpertiseUser = _data as AreaOfExpertiseUserI[];
      this.finalAreaOfExpertiseUser = _.cloneDeep(this.areaOfExpertiseUser);
      console.log("areaOfExpertiseUser ", this.areaOfExpertiseUser);

      if (this.currentAccount.subscription_plan_id) {
        const index = this.ui.subscriptionPlan.findIndex(
          (item) => item.id == this.currentAccount.subscription_plan_id
        );
        this.currenPlanId = this.currentAccount.subscription_plan_id;
        if (index != -1) {
          this.currentPlan = this.currentAccount.subscription_plan_id;
        }
      }
    }
    const _data2: any = await this.userApi.get(
      USER_API_CALL.GET_AREAS_OF_EXPERTISE_ADMIN
    );
    //console.log(_data2)
    this.areaOfExpertisAll = _data2 as AreaOfExpertise[];
  }

  save() {}
  //   await this.apiUtil.apiPost(apiRequest(API_CALL.POST_AREAS_OF_EXPERTISE_BY_UID,this._uid),{id:_item.id})
  private _isSelected(val: AreaOfExpertise): boolean {
    return (
      this.finalAreaOfExpertiseUser.findIndex(
        (item) => item.fachgebiet_id == val.id
      ) != -1
    );
  }
  private _existsByUser(val: AreaOfExpertise): boolean {
    return (
      this.areaOfExpertiseUser.findIndex(
        (item) => item.fachgebiet_id == val.id
      ) != -1
    );
  }

  async saveChanged() {
    console.log("");
    console.log(this.currentAccount);
    const _path = USER_API_REQUESTS(
      USER_API_CALL.MANAGER_ACCOUNT_UPDATE,
      this.currentAccount.uid
    );
    await this.userApi.post(_path, { payload: this.currentAccount });

    setTimeout(() => {
      this.close();
    }, 100);
  }

  removeUser() { 
      this.dialog.open(AlertOverlayComponent, {
        width: '400px',
        data: {
          title: 'User Endgültig löschen?',
          bodyMessage: 'Sind Sie sicher, dass Sie diesen User entfernen wollen? Diese Aktion kann nicht rückgängig gemacht werden.',
            actions: [
              {title: 'Entfernen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME},
              {title: 'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
            ]
          },
        backdropClass: 'backdrop-background'
      }).afterClosed().subscribe((result) => {
        if (result != null){
          if (result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
            this.finalRemoveUser();
          }
        }
      });
  }

  private async finalRemoveUser() { 
    await this.userApi.getP(USER_API_CALL.REMOVE_USER_BY_ID, this.currentAccount.uid)
    this.dialogRef.close({reload: true});
  }
  

  
  startInviteForCG() {}
  modelChangeFn(e: any) {
    console.log("ngModelChange", e);
    this.showSaveButton = true;
  }
  // docNameChanged(e: any) {
  //   /*  this.newInvite.doc_name = e.target.value
  //   if(this.errorMessage) {this.errorMessage = null} */
  // }
  // emailTfTapped() {
  //   if (this.showErrorMessage === true) {
  //     this.showErrorMessage = false;
  //     // this.newInvite.email = ''
  //   }
  //   if (this.errorMessage) {
  //     this.errorMessage = null;
  //   }
  // }
  // emailChanged(e: any) {
  //   /*  if(this.showErrorMessage === true){
  //    this.showErrorMessage = false;
  //  }
  //  if(this.errorMessage) {this.errorMessage = null}
  //  this.newInvite.email = e.target.value */
  // }
  cbChanged = async (item: string) => {
    switch (item) {
      case "DOXFOX":
        this.doxFoxAccess = !this.doxFoxAccess;
        break;
      case "SCS":
        this.scsAccess = !this.scsAccess;
        break;
    }
  };
  togglePrimary(allItem: AreaOfExpertise, is_active: boolean) {
    if (this.finalAreaOfExpertiseUser.length == 1) {
      this.finalAreaOfExpertiseUser[0].is_primary = true;
      return;
    }

    for (var i = 0; i < this.areaOfExpertiseUser.length; i++) {
      if (this.areaOfExpertiseUser[i].fachgebiet_id == allItem.id) {
        if (!is_active) {
          this.areaOfExpertiseUser[i].is_primary = true;
          this._togglePrimary(this.areaOfExpertiseUser[i].id);
          console.log("YES", this.areaOfExpertiseUser[i]);
        } else {
        }
      } else {
        if (!is_active) {
          this.areaOfExpertiseUser[i].is_primary = false;
        }
      }
    }
    
    console.log("this.finalAreaOfExpertiseUser", this.finalAreaOfExpertiseUser);
    console.log("this.areaOfExpertiseUser", this.areaOfExpertiseUser);
  }
  togglePrimaryForNewInvite(allItem: AreaOfExpertise, is_active: boolean) {
    // if(this.newAccount.fachgebiete.length == 1 ) { this.finalAreaOfExpertiseUser[0].is_primary = true;  return; }
    for (var i = 0; i < this.newAccount.fachgebiete.length; i++) {
      if (this.newAccount.fachgebiete[i].fachgebiet_id == allItem.id) {
        if (!is_active) {
          this.newAccount.fachgebiete[i].is_primary = true;
        }
      } else {
        if (!is_active) {
          this.newAccount.fachgebiete[i].is_primary = false;
        }
      }
    }
    console.log(this.newAccount);
  }
  private async _togglePrimary(id: number) {
    const _path = USER_API_REQUESTS(
      USER_API_CALL.TOGGLE_PRIMARY_AREA_OF_EXPERTISE_BY_ID,
      id.toString()
    );
    await this.userApi.post(_path, { uid: this.currentAccount.uid });
  }
  optionChanged(e: any) {
    // console.log(e)
    // console.log(this._options.selectedOptions.selected)
    /*  console.log(this._options.selectedOptions.selected.length) */
  }
  isPrimaryUserExpertise(allItem: AreaOfExpertise): boolean {
    const _index = this.areaOfExpertiseUser.findIndex(
      (item) => item.fachgebiet_id == allItem.id
    );
    if (_index != -1) {
      return this.areaOfExpertiseUser[_index].is_primary;
    }
    return false;
  }
  isPrimaryNewUserExpertise(allItem: AreaOfExpertise): boolean {
    const _index = this.newAccount.fachgebiete.findIndex(
      (item) => item.fachgebiet_id == allItem.id
    );
    if (_index != -1) {
      return this.newAccount.fachgebiete[_index].is_primary ? true : false;
    } else {
      return false;
    }
  }

  userHasExpertise(allItem: AreaOfExpertise): boolean {
    return (
      this.areaOfExpertiseUser.findIndex(
        (item) => item.fachgebiet_id == allItem.id
      ) != -1
    );
  }
  newUserHasExpertise(allItem: AreaOfExpertise): boolean {
    return (
      this.newAccount.fachgebiete.findIndex(
        (item) => item.fachgebiet_id == allItem.id
      ) != -1
    );
  }

  get hasAnyExpertise(): boolean {
    return this.areaOfExpertiseUser.length > 0;
  }
  get newAccountHasAnyExpertise(): boolean {
    return this.newAccount.fachgebiete.length > 0;
  }

  // async blockUser() {
  //   this.currentAccount.state_enum = USER_STATE_ENUM.BLOCKED_BY_ADMIN;
  //   // await this.userApi.getP(USER_API_CALL.DELETE_AREAS_OF_EXPERTISE_BY_UID,_removeId.toString())
  //   // await this.userApi.getP(USER_API_CALL.GET_BLOCK_USER_BY_ID, this.currentAccount!.uid!)
  // }
  // async unBlockUser() {
  //   this.currentAccount.state_enum = USER_STATE_ENUM.REGISTERED;
  //   // await this.userApi.getP(USER_API_CALL.DELETE_AREAS_OF_EXPERTISE_BY_UID,_removeId.toString())
  //   // await this.userApi.getP(USER_API_CALL.GET_UNBLOCK_USER_BY_ID, this.currentAccount!.uid!)
  // }

  get showSS_stuff(): boolean {
    return this._deleteCount > 6
  }
  initDeleteMode() {
    this._deleteCount ++;
  }
  private _deleteCount = 0

  fachbereichCHanged(e:any) {
    console.log(e.value);
  }
  async fachArztCHanged(e:any) {
    console.log(e.value);
    // if(this.padNextFachArztBereichSelected){
    //   const _path2 = USER_API_REQUESTS(
    //     USER_API_CALL.POST_USER_FACHGEBIET_PAD
    //   );
    //   await this.userApi.post(_path2, { payload: {
    //     uid: this.currentAccount.uid,
    //     facharzt_pad_next_fachgebiete_id: this.padNextFachArztBereichSelected?.id
    //   } });
    // }
  }
  padNextFachBereichSelected: PadNextFachArztFull | undefined
  padNextFachArztBereichSelected: PadNextFachArztItem | undefined
  get padNextItemsSelected(): PadNextFachArztItem[] {
    if(this.padNextFachBereichSelected) {
      return this.padNextFachBereichSelected.items
    }
    return []
  }
  get padNextFachFull(): PadNextFachArztFull[] {
   return this.ui.padNextFachFull
  }
  
}
