<header  *ngIf="auth.loggedIn" style="position: static;">

    <mat-toolbar>

      <dxfx-main-menu-item-hz btnTitle='User' path='users' *ngIf="auth.NAVIGATION.includes('USERS')" 
        icon='/assets/icon/group_FILL1_wght400_GRAD0_opsz24.svg'></dxfx-main-menu-item-hz>

      <dxfx-main-menu-item-hz btnTitle='E-Mail Templates' path='email-templates' *ngIf="auth.NAVIGATION.includes('MAILING')"
      icon='/assets/icon/local_post_office_FILL1_wght400_GRAD0_opsz24.svg'></dxfx-main-menu-item-hz>

      <dxfx-main-menu-item-hz btnTitle='Kliniken' path='klinik' *ngIf="auth.NAVIGATION.includes('ADMIN_CLINICS')"
      icon='/assets/icon/folder_shared_FILL0_wght400_GRAD0_opsz24.svg'></dxfx-main-menu-item-hz>

      <dxfx-main-menu-item-hz btnTitle='Report' path='report' *ngIf="auth.NAVIGATION.includes('REPORT')"
      icon='/assets/icon/folder_shared_FILL0_wght400_GRAD0_opsz24.svg'></dxfx-main-menu-item-hz>
      
      <dxfx-main-menu-item-hz btnTitle='user-Report' path='user-report' *ngIf="auth.NAVIGATION.includes('REPORT')"
      icon='/assets/icon/group_FILL1_wght400_GRAD0_opsz24.svg'></dxfx-main-menu-item-hz>


      <span class="toolbar-spacer"></span>
      <button mat-raised-button (click)="signOut()">Abmelden</button>
      <!-- <dxfx-icon-button color="warn" title="Abmelden" icon="check" (clicked)="signOut()"></dxfx-icon-button> -->
      </mat-toolbar>
      <mat-toolbar style="background-color: #63A7FF; height: 5px;"></mat-toolbar>
    </header>

<div class="router" >
  <router-outlet></router-outlet>
</div>

