<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <mat-icon class="mediumIconButton"  (click)='close()'>close</mat-icon>
</mat-toolbar>


<div class="container" style="height: calc(100% - 88px);">

  <div class="d-flex flex-column">
    <div class="p-2" >
      <mat-form-field appearance="outline" class="select-form-field w100">
        <!-- <mat-label>Fachbereiche</mat-label> -->
        <mat-select (selectionChange)='selectedSectorChanged($event)' [value]="selected_pool_id">
          <mat-option [value]="sector.pool_id" *ngFor="let sector of sectors" >
            {{sector.title}}
          </mat-option>
        </mat-select>
      </mat-form-field>
    </div>
  </div>
</div>


<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <dxfx-df-icon-button *ngIf="showSaveButton"
    title="Änderung speichern" icon="save"
   (clicked)="save()" color="accent"></dxfx-df-icon-button>
</mat-toolbar>
