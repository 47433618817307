import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})

export class AuthGuard implements CanActivate {
  constructor(private authS:AuthService, private router:Router){
    // console.log('C AUTHGUARD');
  }
  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
      // console.log(route.data["access"])
      if(this.authS.loggedIn === false) {
        this.router.navigateByUrl('sign-in')
        return false;
      } else {


      }
      if(this.authS.NAVIGATION.includes(route.data["access"]) ) {
        return true
      }
    return false;
  }

}
