import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { POOL_CALL } from '../core/models/pool.call.enum';

@Injectable({
  providedIn: 'root'
})
export class PoolService {

  constructor(private http:HttpClient) { }


  async get(call:POOL_CALL, id?:string) {
    const _path = POOL_REQUEST(call,id)
    if (_path == "ERROR_MISSING_PARAMS") {
      return _path
    } else {
      const _d = this.http.get(_path).toPromise();
      return _d
    }

  }
  async post(call:POOL_CALL, payload:Object){
    const _r =  await this.http.post(POOL_REQUEST(call),payload).toPromise()
    return _r;
  }

}


export function POOL_REQUEST(call:POOL_CALL, params?:string) : string {
  switch(call) {

    case POOL_CALL.GET_USERS_BY_POOL_ID:
      if(!params) { return "ERROR_MISSING_PARAMS" }
      return `${environment.endpoints.apiBasePathV2}pools/users/${params}`;

    case POOL_CALL.ADD_USER_TO_POOL:
      return `${environment.endpoints.apiBasePathV2}pools/add-user/`;

    // case POOL_CALL.DELTE_USER_BY_USERPOOL_ID:
    //   if(!params) { return "ERROR_MISSING_PARAMS" }
    //   return `${environment.endpoints.apiBasePathV2}pools/delete-user/${params}`;

    case POOL_CALL.GET_POOL_USERS_ALL:
      return `${environment.endpoints.apiBasePathV2}pools/pool-users-all/`;

    case POOL_CALL.POST_CHANGE_POOL_FOR_UID:
      return `${environment.endpoints.apiBasePathV2}pools/change/`;

  }

}
