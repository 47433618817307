<div class="container-fluid" >
  <div class="d-flex" style="height: 100%;">
    <div class="p-2" style="width: 50%;">
    
      <div class="container contentItem" *ngFor="let contentItem of templateItems">
        <div class="d-flex flex-column">  
          <div class="p-2" >
              <mat-label class='mat-caption'>{{contentItem.title}}</mat-label>
              <textarea class="formControl mat-body" rows="3" [(ngModel)]='contentItem.text' type="text" ></textarea>
          </div>
          <div class="p-2">
            <button mat-flat-button (click)="saveContentItem(contentItem)">SAVE</button>
          </div>
        </div>
      </div>
    </div>
    <div class="p-2" style="width: 50%; height: 100%;">
      <iframe [srcdoc]="HTML_TEMPLATE" frameborder="1" #_iFrame style="width: 800px; height:800px"></iframe>
    </div>
  </div>
</div>