import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ClinicApi } from 'src/app/core/clinic.api';
import { API_CALL_CLINIC } from 'src/app/core/models/clinic.call.enum';
import { IClinicSector } from 'src/app/core/models/clinic.sector.model';
import { ChangeClinicSectorForUserOverlayData } from 'src/app/core/models/overlay.data.model';
import { POOL_CALL } from 'src/app/core/models/pool.call.enum';
import { IPoolUser } from 'src/app/core/models/poolUser.model';
import { PoolService } from 'src/app/services/pool.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'dxfx-change-clinic-sector-for-user',
  templateUrl: './change-clinic-sector-for-user.component.html',
  styleUrls: ['./change-clinic-sector-for-user.component.scss']
})
export class ChangeClinicSectorForUserComponent implements OnInit {

  private poolUser?: IPoolUser
  private currentUserSector?: IClinicSector;
  selected_pool_id?: string
  sectors: IClinicSector[] = []

  constructor(
    public ui:UiService,
    private poolApi: PoolService,
    private clinicAPI: ClinicApi,
    public dialogRef: MatDialogRef<ChangeClinicSectorForUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeClinicSectorForUserOverlayData

  ) {
   
    
  }

  ngOnInit(): void {
    console.log('ChangeClinicSectorForUserOverlayData INIT');
    this.poolUser = this.data.forPoolUser
    this.currentUserSector = this.data.currentUserSector
    // this.selected_pool_id = this.data.currentUserSector.pool_id
 console.log(this.poolUser);
    // this.selectedSector = this.data.currentUserSector
    // console.log('this.selectedSector',this.selectedSector);
    this.loadSectors()
  }

  private async loadSectors() {
    if (!this.currentUserSector) return;
    const _result = await (await this.clinicAPI.getP(API_CALL_CLINIC.GET_CLINIC_SECTORS_BY_CLINIC_ID, this.currentUserSector.clinic_id))
    this.sectors = _result as IClinicSector[]
    // this.isLoading = false
    // console.log('', this.sectors);

  }

  get showSaveButton(): boolean {
    if ( this.selected_pool_id != this.currentUserSector?.pool_id) {
      return true
    }
    return false
  }

  selectedSectorChanged(e:any) {
    // console.log('e', e.value);
    this.selected_pool_id = e.value
    // console.log('this.selectedSector \n', this.selected_pool_id);
  }


  async save(){
    // console.log('User ', this.poolUser);

    // console.log('this.selectedSector \n', this.selectedSector);

    if ( !this.poolUser ) { return }
    const dto = {
      user_pool_id: this.poolUser.user_pool_id,
      uid: this.poolUser.uid,
      pool_id: this.selected_pool_id
    }

    // console.log('DTO ', dto);
    await this.poolApi.post(POOL_CALL.POST_CHANGE_POOL_FOR_UID, {dto:dto})
    this.dialogRef.close("reload")

  }

  close(): void {
    this.dialogRef.close();
  }
}
