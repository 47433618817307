<div style="height:100%; overflow: hidden;">
  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <mat-icon class="mediumIconButton"  (click)='close()'>close</mat-icon>
  </mat-toolbar>

  <div class="container-fluid" style="height: calc(100% - 88px);">

    <div class="d-flex justify-content-between flex-column h100" >

      <div class="p-2 " *ngIf='lineMode === LINEMODES.SINGLE' >
        <input class="formControl"
        #singleLine [(ngModel)]="text"
        (keydown.enter)="save()">
      </div>

      <div class="p-0 p_t5 p_b5 flex-fill" *ngIf='lineMode === LINEMODES.MULTI' >
        <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"
        maxlength="300" [(ngModel)]="text" rows="2" class="mat-body formControl"  #multiLine>
        </textarea>

      </div>
    </div>
  </div>

  <mat-toolbar class="overlay-toolbar">
    <div class="d-flex w100">
      <div class="p-2 ms-auto">
        <dxfx-df-icon-button
        title= "Speichern"
        icon="save"
        color="primary"
        (clicked)="save()"
        >
        </dxfx-df-icon-button>
      </div>
    </div>

  </mat-toolbar>
</div>
