export enum API_CALL_CLINIC {
  GET_ALL,
  GET_SINGLE,
  UPDATE,
  CREATE,
  ADDRESSES_BY_POOL_ID,
  CREATE_ADDRESS,
  UPDATE_ADDRESS_BY_ID,
  REMOVE_ADDRESS_BY_ID,

  GET_CLINIC_SECTORS_BY_CLINIC_ID,

  // GET_ACCOUNTING_BY_CLINIC_ID,

  UPDATE_ITEM_BY_ID,

  UPDATE_SECTOR_ITEM_BY_ID,

  POST_CREATE_CLINIC_ADMIN,
  POST_CREATE_SECTOR,

  POST_DELETE_SECTOR,

  CREATE_ACCOUNTING,

  GET_CLINIC_ADMINS,

  GET_REMOVE_CLINIC_FULL,

  // GET_ACCOUNTING,
  // GET_ACCOUNTING_POOLS_FLAT,
  // GET_ACCOUNTING_POOLS_FULL,
  // GET_SINGLE_ACCOUNTING_POOL_FULL,
}
