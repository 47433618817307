export enum INVITE_STEP {
  LOADING,
  EMAIL,
  USER_DATA,
  COMPLETE
}


export interface INVITE_RESPONSE {
    status:INVITE_RESPONSE_STATUS
    message: INVITE_RESPONSE_MESSAGE
}

export enum INVITE_RESPONSE_STATUS {
  ERROR = "ERROR",
  SUCCESS  = "SUCCESS"
}
export enum INVITE_RESPONSE_MESSAGE {
  NO_USER = "NO_USER",
  USER_EXISTS = "USER_EXISTS",
  MISSING_PAYLOAD = "MISSING_PAYLOAD"
}
