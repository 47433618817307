<div class="container-fluid p_t60">

  <div class="d-flex">

    <div class="p-0 flex-fill">

  
      <div class="d-flex pl14 align-items-center statHead" style="height: 32px!important;">

        <div class="p-0 flex-fill _bRight" >
          <div class="d-flex h32" >
            <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort('email')">Name</div>
            <div class="p-0 mt2 ml5">
              <mat-icon class="tableFilterIcon"[class.tableFilterIconActive]="userSortMode === 'email' ">north</mat-icon>
              <!-- <mat-icon class="tableFilterIcon" *ngIf="userSortMode === SORTMODES.EMAIL && userSortType === SORTYPES.DESC"
                [class.tableFilterIconActive]="userSortMode === SORTMODES.EMAIL && userSortType === SORTYPES.DESC">south</mat-icon> -->
            </div>
          </div>
        </div>


        <div class="p-0  m-auto _bRight statColWidthSM " >
          <div class="d-flex justify-content-center h32" >
            <div class="p-0 m_t5 " style="cursor:pointer;" (click)="changeSort('invoices')">Invoices</div>
            <div class="p-0 mt2 ml5">
              <mat-icon class="tableFilterIcon" [class.tableFilterIconActive]="userSortMode === 'invoices' ">north</mat-icon>
            </div>
          </div>
        </div>
        <div class="p-0 _bRight statColWidthSM tlr">
          <div class="d-flex justify-content-center h32" >
            <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort('processes')">Leistungen</div>
            <div class="p-0 mt2 ml5">
              <mat-icon class="tableFilterIcon" [class.tableFilterIconActive]="userSortMode === 'processes' ">north</mat-icon>
            </div>
          </div>
        </div>
        <div class="p-0 _bRight statColWidthSM">
          <div class="d-flex  justify-content-center h32" >
            <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort('sReports')">Op Berichte</div>
            <div class="p-0 mt2 ml5">
              <mat-icon class="tableFilterIcon" [class.tableFilterIconActive]="userSortMode === 'sReports' ">north</mat-icon>
            </div>
          </div>
        </div>
        <div class="p-0 _bRight statColWidthSM">
          <div class="d-flex justify-content-center h32" >
            <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort('templatesCloned')">Templ. cloned</div>
            <div class="p-0 mt2 ml5">
              <mat-icon class="tableFilterIcon" [class.tableFilterIconActive]="userSortMode === 'templatesCloned' ">north</mat-icon>
            </div>
          </div>
        </div>
        <div class="p-0 _bRight statColWidthSM ">
          <div class="d-flex justify-content-center h32" >
            <div class="p-0 m_t5" style="cursor:pointer;" (click)="changeSort('templatesCreated')">Templ. created</div>
            <div class="p-0 mt2 ml5">
              <mat-icon class="tableFilterIcon" [class.tableFilterIconActive]="userSortMode === 'templatesCreated' ">north</mat-icon>
            </div>
          </div>
        </div>




      </div>


      <div class="d-flex pl14 align-items-center stats-row" *ngFor="let stat of userStats">
        <div class="p-0  flex-fill ms-auto">{{stat.email}}</div>
        <div class="p-0 statColWidthSM tlc">{{stat.invoiceCount}}</div>
        <div class="p-0 statColWidthSM tlc">{{stat.processCreated}}</div>
        <div class="p-0 statColWidthSM tlc">{{stat.surgeryReportCreated}}</div>
        <div class="p-0 statColWidthSM tlc">{{stat.templateCloned}}</div>
        <div class="p-0 statColWidthSM tlc">{{stat.templateCreated}}</div>
        
      </div>
    </div>


    <div class="p-0 " style="width: 670px;">

      <div class="d-flex">
        <div class="p-2">
          <dxfx-template-chart
          [year]=currentYear
          chartTitle="Anzahl User Sessions"
          [apiCall]="calls.GET_USERSESSIONS_IN_YEAR"
          ></dxfx-template-chart>
        </div>
      </div>
      <div class="d-flex">
        <div class="p-2">
          <dxfx-template-chart
          [year]=currentYear
          chartTitle="Anzahl Neue Registrierungen"
          [apiCall]="calls.GET_USER_PER_YEAR"
          ></dxfx-template-chart>
        </div>
      </div>
      <div class="d-flex">

        <div class="p-2">
          <dxfx-template-chart
          [year]=currentYear
          [apiCall]="calls.INVOICES_REPORT"
          chartTitle="Anzahl erstellte Rechnungen"
          ></dxfx-template-chart>
        </div>
      </div>
    </div>

  </div>


  

</div>

   <!-- <div class="d-flex">
    <div class="p-0 chartBoxBig">
      <div class="d-flex flex-column" *ngFor="let log of authReportFixed">
        <div class="p-0">
          <div class="d-flex">
            <div class="p-2">{{log.dateStart | date: 'EEE d. HH:mm'}} - {{log.dateEnd | date: 'HH:mm'}}</div>
            <div class="p-2 ms-auto">{{log.dateEnd | date: 'dd.MM.yyyy HH:mm'}}</div>
          </div>
        </div>
        <div class="p-0 _br" >
          <div class="d-flex" >
            <div class="p-2" *ngFor="let logType of log.items">{{logType.logType}} : {{logType.items.length}}
              <div class="p-2">
                <div class="d-flex flex-column">
                  
                  <div class="p-2" *ngFor="let item of logType.items">{{item.email}}</div>
                </div>
              </div>
            </div>
           
          </div>
        </div>

      </div>
    </div>
  </div>  -->

  <!-- <div class="d-flex">
    <div class="p-2">
      <dxfx-template-chart
      [year]=currentYear
      chartTitle="Anzahl Neue Registrierungen"
      [apiCall]="calls.GET_USER_PER_YEAR"
      ></dxfx-template-chart>
    </div> -->
      <!-- [apiCall]="calls.USER_OWN_REPORT" -->

    <!-- [reportSvgItems]="userOwnSvg" -->


<!--     <div class="p-2">
      <dxfx-template-chart
      [year]=currentYear
    chartTitle="Erstellte Leistungen"
    [apiCall]="calls.USER_PROCESS_REPORT"
    ></dxfx-template-chart>
  </div> -->

  <!-- -->
  <!-- </div> -->

  <!-- <div class="d-flex">

    <div class="p-2">
      <dxfx-template-chart
      [year]=currentYear
      [apiCall]="calls.INVOICES_REPORT"
      chartTitle="Anzahl erstellte Rechnungen"
      ></dxfx-template-chart>
    </div> -->
    <!-- [reportSvgItems]="invoicesSvg" -->
<!-- -->

  <!-- </div> -->

  <!-- <div class="d-flex"> -->
<!--     <div class="p-2">
      <dxfx-template-chart
      [year]=currentYear
      chartTitle="Anzahl gelöschte User Vorlagen"
      [apiCall]="calls.USER_OWN_DELETED_REPORT"
      ></dxfx-template-chart>
    </div> -->


    <!-- <div class="p-2"> -->
<!--       <dxfx-template-chart
      [year]=currentYear
    chartTitle="Anzahl gelöschte User Leistungen"
    [apiCall]="calls.PROCESS_DELETED_REPORT"
    ></dxfx-template-chart>
    </div> -->
<!-- 
  </div> -->


  <!-- <div class="d-flex">
    <div class="p-2"> -->
   <!--    <dxfx-template-chart
      [year]=currentYear
      chartTitle="Anzahl gelöschte Admin Vorlagen"
      [apiCall]="calls.ADMIN_TEMPLATES_DELETED_REPORT"
      ></dxfx-template-chart> -->
    <!-- </div>


    <div class="p-2"> -->
     <!--  <dxfx-template-chart
    [year]=currentYear
    chartTitle="Anzahl gelöschte Admin Leistungen"
    [apiCall]="calls.ADMIN_PROCESS_DELETED_REPORT"
    ></dxfx-template-chart> -->
    <!-- </div>

  </div> -->


<!-- <div class="container">

  <div class="d-flex flex-column"  *ngFor="let report of reports">
  <div class="p-0">
    <div class="d-flex">
      <div class="p-2">
      Year: {{report.year}}
      </div>
      <div class="p-2">
        Deleted Templates: {{report.deletedTemplates}}
        </div>
        <div class="p-2">
        Deleted Processes: {{report.deletedProcesses}}
        </div>
        <div class="p-2">
        Public Templates: {{report.publicTempltes}}
        </div>
    </div>
  </div>

  <div class="p-0">
    <div class="d-flex">

      <div class="p-2 d-flex flex-column" *ngFor="let month of report.byYear">
        <div class="p-2">{{months[month.month]}}</div>
        <div class="p-2">t: {{month.countTemplates}}</div>
        <div class="p-2">p: {{month.countProcesses}}</div>
        <div class="p-2">r: {{month.countInvoices}}</div>

      </div>

    </div>
  </div>

  </div>

</div> -->
