
<mat-toolbar class="overlay-toolbar">
	<span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>
<div class="container-fluid m_t5" *ngIf="address" style="background-color: rgba(0,0,0,0.05)">

  <div class="row pl10 p_t10">
    <div class="col-6">
      <h2>Praxis/Klinik</h2>
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">

    <div class="col-lg-12 col-md-12 col-sm-12">
      <mat-label class='mat-caption'>Klinik Rechn. Info</mat-label>
      <input class="formControl mat-body"
      [class.formControlError]="address.clinicLegalMain === '' || address.clinicLegalMain === null"
      [(ngModel)]="address.clinicLegalMain" >
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Name der Praxis/Klinik</mat-label>
      <input class="formControl mat-body"
      [class.formControlError]="address.klinikName === '' || address.klinikName === null"
      [(ngModel)]="address.klinikName" >
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <!-- <mat-label class='mat-caption'>Name der Praxis/Klinik</mat-label>
      <input class="formControl mat-body"
      [class.formControlError]="address.klinikName === '' || address.klinikName === null"
      [(ngModel)]="address.klinikName" > -->

      <mat-label class='mat-caption'>Behandelnde(r) Arzt/Ärztin</mat-label>
      <input class="formControl mat-body formControlDisabled" [(ngModel)]="address.behandelnder_arzt">
    </div>

    <!-- <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Abteilung</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.abteilung">
    </div> -->
  </div>

  <!-- <div class="row pl10 p_t15 pr10">
    <div class="col-lg-5 col-md-5 col-sm-12">
      <mat-label class='mat-caption'>Behandelnde(r) Arzt/Ärztin</mat-label>
      <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.docName">
    </div>
    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Facharztbezeichnung</mat-label>
      <input class="formControl mat-body formControlDisabled" disabled [(ngModel)]="account.doc_description">
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>Funktion</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.arzt_role">
    </div>
  </div> -->

  <div class="row  p_t15 ">

    <div class="col-lg-12 col-md-12 col-sm-12">
      <div class="d-flex">
        <div class="p-2 flex-fill">
          <mat-label class='mat-caption'>Straße und Hausnummer</mat-label>
          <input class="formControl mat-body"
          [class.formControlError]="address.streetName === '' || address.streetName === null"  [(ngModel)]="address.streetName">
        </div>
        <div class="p-2 " style="width: 240px">
          <mat-label class='mat-caption'>Stadt</mat-label>
          <input class="formControl mat-body" [class.formControlError]="address.city === '' || address.city === null"  [(ngModel)]="address.city" >
        </div>
        <div class="p-2" style="width: 130px">
          <mat-label class='mat-caption'>PLZ</mat-label>
        <input class="formControl mat-body" [class.formControlError]="address.zip === '' || address.zip === null"  [(ngModel)]="address.zip" >
        </div>
      </div>

    </div>

    <!-- <div class="col-lg-2 col-md-2 col-sm-6">
      <mat-label class='mat-caption'>PLZ</mat-label>
      <input class="formControl mat-body" [class.formControlError]="address.zip === '' || address.zip === null"  [(ngModel)]="address.zip" >
    </div>

    <div class="col-lg-4 col-md-4 col-sm-6">
      <mat-label class='mat-caption'>Stadt</mat-label>
      <input class="formControl mat-body" [class.formControlError]="address.city === '' || address.city === null"  [(ngModel)]="address.city" >
    </div> -->

  </div>


  <div class="row">
    <div class="col-lg-6 col-md-6 col-sm-12 "  >
      <div class="d-flex flex-column">
        <div class="p-2">
          <mat-label class='mat-caption'></mat-label>
          <input class="formControl" placeholder="..."  [(ngModel)]="address.clinicInvoiceProviderFirst" >
        </div>
        <div class="p-2">
          <mat-label class='mat-caption'></mat-label>
          <input class="formControl" placeholder="..."  [(ngModel)]="address.clinicInvoiceProviderThird" >
        </div>
      </div>
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12 "  >
      <div class="d-flex flex-column">
        <div class="p-2">
          <mat-label class='mat-caption'></mat-label>
          <input class="formControl" placeholder="..."  [(ngModel)]="address.clinicInvoiceProviderSecond" >
        </div>
        <div class="p-2">
          <mat-label class='mat-caption'></mat-label>
          <input class="formControl" placeholder="..."  [(ngModel)]="address.clinicInvoiceProviderFourth" >
        </div>
      </div>
    </div>
  </div>

  <div class="row pl10 p_t25">
    <div class="col-6">
      <h2>Bankverbindung</h2>
      <button mat-flat-button (click)="save()">Speichern</button>
    </div>
  </div>

  <div class="row pl10 p_t15 pr10">

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Kontoinhaber/in</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.kontoinhaber" >
    </div>

    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>USt-IdNr.</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.ust_nr" >
    </div>

  </div>

  <div class="row pl10 p_t15 pr10 p_b20">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <mat-label class='mat-caption'>Bankinstitut</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.bank_name" >
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>IBAN</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.iban" >
    </div>

    <div class="col-lg-3 col-md-3 col-sm-6">
      <mat-label class='mat-caption'>BIC</mat-label>
      <input class="formControl mat-body" [(ngModel)]="address.bic" >
    </div>

  </div>

</div>

<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <button mat-flat-button (click)="save()">Speichern</button>
  <!-- <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon> -->
</mat-toolbar>
