import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { IClinic } from 'src/app/core/models/clinic.model';
import { ClinicService } from 'src/app/services/clinic.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'dxfx-clinic',
  templateUrl: './clinic.component.html',
  styleUrls: ['./clinic.component.scss']
})
export class ClinicComponent implements OnInit {

  isClinicAddMode: boolean = false
  isSaving: boolean = false

  clinic: IClinic = {
    id:"-1",
    title: "Neuer Klinik Name",
    description: "Beschreibung zur Klinik",
  
    address_title: "",
    address_street: "",
    address_city: "",
    address_zip: "",
    bank_owner: "",
    bank_name: "",
    iban: "",
    bic: "",
    ustNr: "",
    clinicLegalMain: "Die Rechnungserstellung erfolgt im Auftrag von ...",
    clinicInvoiceProviderFirst: "Die Leistung wurde erbracht durch:",
    clinicInvoiceProviderSecond: "Klinik für .....",
    clinicInvoiceProviderThird: "Wahlarzt für den Bereich ....",
    clinicInvoiceProviderFourth: "Prof. Dr. med. .....",
  }

  constructor(
    public ui:UiService,
    public cs: ClinicService,
    private router: Router
    ) { }

  ngOnInit(): void {
    console.log('ClinicComponent INIT');
    setTimeout(()=>{
      this.ui.currentPage.emit('klinik')
    },100)

  }

  navigateToClinic(id: string){
    this.router.navigate(["klinik-detail", id])
  }
  toggleAddMode() {
    this.isClinicAddMode = !this.isClinicAddMode
    this.clinic = {
      id:"-1",
    title: "Neuer Klinik Name",
    description: "Beschreibung zur Klinik",
  
    address_title: "",
    address_street: "",
    address_city: "",
    address_zip: "",
    bank_owner: "",
    bank_name: "",
    iban: "",
    bic: "",
    ustNr: "",
    clinicLegalMain: "Die Rechnungserstellung erfolgt im Auftrag von ...",
    clinicInvoiceProviderFirst: "Die Leistung wurde erbracht durch:",
    clinicInvoiceProviderSecond: "Klinik für .....",
    clinicInvoiceProviderThird: "Wahlarzt für den Bereich ....",
    clinicInvoiceProviderFourth: "Prof. Dr. med. .....",
    }
  }
  async saveNewClinic() {
    this.isSaving = true
    await this.cs.createClinic(this.clinic)
    setTimeout(()=>{
      this.isClinicAddMode = false
      this.isSaving = false
      this.cs.loadClinics()
    }, 1500)
  }

  get saveEnabled():boolean {
    if (this.clinic.title != "Neuer Klinik Name" && this.clinic.title.length > 4) {
      return true
    }
    return false
  }
}
