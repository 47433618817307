import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { API_REQUESTS, ClinicApi } from 'src/app/core/clinic.api';
import { AlertActionI } from 'src/app/core/models/alert/ialert.model';
import { API_CALL_CLINIC } from 'src/app/core/models/clinic.call.enum';
import { IAddress } from 'src/app/core/models/clinic.model';
import { IClinicSector } from 'src/app/core/models/clinic.sector.model';
import { ChangeClinicSectorForUserOverlayData } from 'src/app/core/models/overlay.data.model';
import { POOL_CALL } from 'src/app/core/models/pool.call.enum';
import { IPoolUser } from 'src/app/core/models/poolUser.model';
import { ITextEditOverlayData, LINEMODE, TEXTTYPE } from 'src/app/core/models/texteditOverlayData.model';
import { ADD_USER_TO_POOL_OVERLAY_DATA, InviteUserOverlayData, InviteUserOverlayDataMODE } from 'src/app/core/models/user-clinic-select-overlay-data.model';
import { UserApi } from 'src/app/services/api.user.service';
import { PoolService } from 'src/app/services/pool.service';
import { UiService } from 'src/app/services/ui.service';
import { AddUserToPoolOverlayComponent } from '../add-user-to-pool-overlay/add-user-to-pool-overlay.component';
import { AddressOverlayComponent } from '../address-overlay/address-overlay.component';
import { AlertOverlayComponent } from '../alert-overlay/alert-overlay.component';
import { ChangeClinicSectorForUserComponent } from '../change-clinic-sector-for-user/change-clinic-sector-for-user.component';
import { ChangeUserAccessComponent } from '../change-user-access/change-user-access.component';
import { InviteUserOverlayComponent } from '../invite-user-overlay/invite-user-overlay.component';
import { TextEditOverlayComponent } from '../text-edit-overlay/text-edit-overlay.component';
import { UserService } from 'src/app/services/user.service';


@Component({
  selector: 'dxfx-clinic-sector-overlay',
  templateUrl: './clinic-sector-overlay.component.html',
  styleUrls: ['./clinic-sector-overlay.component.scss']
})
export class ClinicSectorOverlayComponent implements OnInit {

  TEXTTYPES = TEXTTYPE

  sector?:IClinicSector

  currentAddress?:IAddress

  addresses:IAddress[] = []

  poolUsers: IPoolUser[] = []

  isLoadingUsers = true
  showSaveButton = false

  poolUsersColumns = ["firstName", "lastName", "email", "status","action"]
  poolUserDataSource : any
  constructor(
    private dialog: MatDialog,
    private clinicAPI: ClinicApi,
    private ui: UiService,
    private us: UserService,
    private poolApi: PoolService,
    private userApi : UserApi,
    private dialogRef:MatDialogRef<ClinicSectorOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data:IClinicSector
  ) {
    this.sector = data;
  }

  ngOnInit(): void {
    console.log('# INIT ClinicSectorOverlayComponent');
    console.log('SECTOR: ', this.sector);
    setTimeout(() => {
      this.loadAddresses()
      this.loadUsers()
    },1000)
  }


  close(): void {
    this.dialogRef.close();
  }

  editTextFor(forType: TEXTTYPE) {
    let _overlayEditData: ITextEditOverlayData = {
      lineMode: LINEMODE.SINGLE,
      text: ""
    }

    switch(forType) {
      case TEXTTYPE.HEADLINE:
        _overlayEditData.lineMode = LINEMODE.SINGLE
        _overlayEditData.text = this.sector!.title
        break;
      case TEXTTYPE.DESCRIPTION:
        _overlayEditData.lineMode = LINEMODE.MULTI
        _overlayEditData.text = this.sector!.description
        break;
    }


    this.dialog.open(TextEditOverlayComponent, {
      width: "800px",
      height: _overlayEditData.lineMode == LINEMODE.SINGLE ? "160px" : "320px",
      data: _overlayEditData
    }).afterClosed().subscribe(
      (newText:string) => {
        if(newText) {
          switch(forType) {
            case TEXTTYPE.HEADLINE:
              this.sector!.title = newText
              this.updateSectorItem(this.sector!.pool_id, { title: newText })
             return;
            case TEXTTYPE.DESCRIPTION:
              this.sector!.description = newText
              this.updateSectorItem(this.sector!.pool_id, { description: newText })
            return;
          }
        }
      }
    )
  }


  async updateSectorItem(id:string, payload:object) {
    const _path = API_REQUESTS(API_CALL_CLINIC.UPDATE_SECTOR_ITEM_BY_ID, id.toString())
    await this.clinicAPI.post(_path, { dto: payload }).then(
      _data => {

      }
    )
  }
  private async loadAddresses(){
    if (!this.sector) return;
    this.addresses = []
    const _result = await (await this.clinicAPI.getP(API_CALL_CLINIC.ADDRESSES_BY_POOL_ID, this.sector.pool_id))
    this.addresses = _result as IAddress[]
    console.log('addresses ', this.addresses);

   setTimeout(()=> {
    // if (this.currentAddress == null){
    //   this.currentAddress = this.addresses[0]
    // }
    this.currentAddress = this.addresses[0]
    //console.log('currentAddress ', this.currentAddress);
   },160)

  }

  addAddress(){
    if (!this.sector) return;
    this.dialog.open(AddressOverlayComponent, {
      width: "70%",
      maxWidth: "1200px",
      data: this.sector.pool_id
    }).afterClosed().subscribe(
      data=> {
        this.loadAddresses()
      }
    )
  }
  
  updateAddress() {
    const _path = API_REQUESTS(API_CALL_CLINIC.UPDATE_ADDRESS_BY_ID, this.currentAddress!.id.toString())
    this.clinicAPI.post(_path, { address:this.currentAddress! })
  }
  modelChangeFn(e:any){
    this.showSaveButton = true
  }
  addressIndexChanged(e:any){
    console.log('', e);
    this.showSaveButton = false
    // [(ngModel)]="currentAddress"
    this.currentAddress = e.value
  }


  private async loadUsers() {
    if (!this.sector) return;
    await this.poolApi.get(POOL_CALL.GET_USERS_BY_POOL_ID, this.sector.pool_id).then(
      _response => {
        this.poolUsers = _response as IPoolUser[]
        console.log('poolUsers', this.poolUsers);

        this.isLoadingUsers = false
        this.poolUserDataSource = new MatTableDataSource<IPoolUser>(this.poolUsers)
      }
    )
  }

  selectUser(e:any, row:IPoolUser) {
    e.stopPropagation();
    e.preventDefault();
    console.log('selectUser ', row );
  }

  addUser(){
    let _overlayData: ADD_USER_TO_POOL_OVERLAY_DATA = {
      alreadyIncluded: this.poolUsers,
      selected_pool_id: this.sector!.pool_id
    }
    this.dialog.open(AddUserToPoolOverlayComponent,{
      width: "80%",
      maxWidth: "1200px",
      height: "70%",
      maxHeight: "1200px",
      data: _overlayData
    }).afterClosed().subscribe(
      (_selectedUsers:string[]) => {
        if(_selectedUsers.length > 0){
          this.isLoadingUsers = true
           this.updateUsers(_selectedUsers)
        }
      }
    )
  }
  inviteNewUser(){
    const _olData: InviteUserOverlayData = {
      mode: InviteUserOverlayDataMODE.INVITE_AND_ADD_TO_POOL,
      poolId: this.sector!.pool_id!,
      clinicId: this.sector!.clinic_id
      
    }
    this.dialog.open(InviteUserOverlayComponent,{
      width:"70%",
      maxWidth:"1000px",
      height:"560px",
      backdropClass:"backdrop-background",
      data: _olData
    //  data:_overlayData

    }).afterClosed().subscribe(
      data=>{
        // this.loadAllUsers()
        this.us.loadAllUsers()
        this.loadUsers()
      }
    )
    // const _newInVite:ManagedAccountI = {
    //   uid: "-1",
    //   title: "",
    //   medical_specialty_designation: "",
    //   firstName:  "",
    //   lastName:  "",
    //   email:  "",
    //   fachgebiete: []
    // }
    // const _overlayData: SettingsUsersOvlerayDataI = {
    //   mode: SETTINGS_USER_OVERLAY_MODE.INVITE_MODE,
    //   managedAccount: _newInVite
    // }
    // this.dialog.open(UserDetailOverlayComponent,{
    //   width:"70%",
    //   maxWidth:"1000px",
    //   height:"360px",
    //   backdropClass:"backdrop-background",
    //   data:_overlayData

    // }).afterClosed().subscribe(
    //   data=>{
    //     // this.loadAllUsers()
    //   }
    // )
  }
  private async updateUsers(_selectedUsers:string[]){

    for(var i = 0; i < _selectedUsers.length; i ++){
      await this.poolApi.post(POOL_CALL.ADD_USER_TO_POOL, { pool_id: this.sector!.pool_id, uid: _selectedUsers[i]})
      // await this.userApi.getP(USER_API_CALL.GET_UNBLOCK_USER_BY_ID, _selectedUsers[i])
    }
    setTimeout(()=>{
      this.loadUsers()
    },600)

  }

  async changeUserAccess(e:any, user:IPoolUser){
    e.stopPropagation();
    e.preventDefault();

    const oldata: ChangeClinicSectorForUserOverlayData = {
      forPoolUser: user,
      currentUserSector: this.sector!,
      isClinicMode: true

    }
    this.dialog.open(ChangeUserAccessComponent, {
      width: '400px',
      data: oldata,
      backdropClass: 'backdrop-background'
    }).afterClosed().subscribe((result) => {
      if (result != null && result == "reload") {
        this.loadUsers()
      }
    });

    // this.dialog.open(AlertOverlayComponent, {
    //   width: '400px',
    //   data: {
    //     title: 'Benutzer entfernen?',
    //     bodyMessage: 'Sind Sie sicher, dass Sie diesen Benutzer aus dem Klinikbereich entfernen wollen?.',
    //       actions: [
    //         {title: 'Entfernen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME},
    //         {title: 'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
    //       ]
    //     },
    //   backdropClass: 'backdrop-background'
    // }).afterClosed().subscribe((result) => {
    //   if (result != null){
    //     if (result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
    //       this.finalRemoveUserFromPool(user);
    //     }
    //   }
    // });
  }


  async changeUserPool(e:any, user:IPoolUser){
    e.stopPropagation();
    e.preventDefault();


    const oldata: ChangeClinicSectorForUserOverlayData = {
      forPoolUser: user,
      currentUserSector: this.sector!,
      isClinicMode: true
    }
    this.dialog.open(ChangeClinicSectorForUserComponent, {
      width: '400px',
      data: oldata,
      backdropClass: 'backdrop-background'
    }).afterClosed().subscribe((result) => {
      if (result != null && result == "reload") {
        this.loadUsers()
      }
    });


  }


  private async finalRemoveUserFromPool(user:IPoolUser){

    // this.isLoadingUsers = true
    // await this.poolApi.get(POOL_CALL.DELTE_USER_BY_USERPOOL_ID, user.user_pool_id.toString())
    // await this.userApi.getP(USER_API_CALL.GET_BLOCK_USER_BY_ID, user.uid)
    // setTimeout(()=>{
    //   this.loadUsers()
    // },600)
  }


  deleteAddress() {
    if (this.currentAddress) {
      // console.log('currentAddress', this.currentAddress);
      this.dialog.open(AlertOverlayComponent, {
        width: '400px',
        data: {
          title: 'Adresse löschen?',
          bodyMessage: 'Sind Sie sicher, dass Sie diese Adresse entfernen wollen? Diese Aktion kann nicht rückgängig gemacht werden.',
            actions: [
              {title: 'Entfernen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME},
              {title: 'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
            ]
          },
        backdropClass: 'backdrop-background'
      }).afterClosed().subscribe((result) => {
        if (result != null){
          if (result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
            if (this.currentAddress) {
              this.finalDeleteAddress(this.currentAddress!.id!);
            }

          }
        }
      });
    }
  }

  private async finalDeleteAddress(id:number) {
    // console.log('finalDeleteAddress', id);
    // const _path = API_REQUESTS(API_CALL_CLINIC.DELETE_ADDRESS_BY_ID)
    await this.clinicAPI.getP(API_CALL_CLINIC.REMOVE_ADDRESS_BY_ID, id.toString())
    // await this.clinicAPI.getP(API_CALL_CLINIC.REMOVE_ADDRESS_BY_ID, id.toString())
    setTimeout(() => {
      this.loadAddresses()
    },100)
  }

  deleteSector() {

    if( this.addresses.length != 0 || this.poolUsers.length != 0)  {
      if( this.poolUsers.length > 0) {
        this.dialog.open(AlertOverlayComponent, {
          width: '400px',
          data: {
            title: 'Fehler',
            bodyMessage: 'Der Bereich kann nicht gelöscht werden. Bereicht enthält noch Aktive Benutzer.',
              actions: [

              ]
            },
          backdropClass: 'backdrop-background'
        })
      } else if ( this.addresses.length > 0) {
        this.dialog.open(AlertOverlayComponent, {
          width: '400px',
          data: {
            title: 'Fehler',
            bodyMessage: 'Der Bereich kann nicht gelöscht werden. Bereicht enthält noch Aktive Adressen.',
              actions: [

              ]
            },
          backdropClass: 'backdrop-background'
        })
      }

    } else {
      this.dialog.open(AlertOverlayComponent, {
        width: '400px',
        data: {
          title: 'Bereich löschen?',
          bodyMessage: 'Sind Sie sicher, dass Sie diesen Bereich entfernen wollen? Diese Aktion kann nicht rückgängig gemacht werden.',
            actions: [
              {title: 'Entfernen', color: AlertActionI.BUTTON_COLOR_TYPE.WARN, actionType: AlertActionI.BUTTON_ACTION_TYPE.RESUME},
              {title: 'Abbrechen', color: AlertActionI.BUTTON_COLOR_TYPE.BASIC, actionType: AlertActionI.BUTTON_ACTION_TYPE.CANCEL}
            ]
          },
        backdropClass: 'backdrop-background'
      }).afterClosed().subscribe((result) => {
        if (result != null){
          if (result === AlertActionI.BUTTON_ACTION_TYPE.RESUME){
            this.finalDeleteSector();
          }
        }
      });
    }





  }
  private async finalDeleteSector() {

    console.log('this.sector', this.sector);

    if (this.sector) {
      const _poolId = this.sector.pool_id
      const _sectorId = this.sector.clinic_sector_id

      const _path = API_REQUESTS(API_CALL_CLINIC.POST_DELETE_SECTOR)
      await this.clinicAPI.post(_path, {dto: {
        pool_id: _poolId,
        sector_id: _sectorId
      }})
      // await this.clinicAPI.getP(API_CALL_CLINIC.REMOVE_ADDRESS_BY_ID, id.toString())
      this.close();

    } else {
      this.close()
    }


  }
}
