<mat-card id="login-card" class="p0">
  <div class="container-fluid p0 h100">
    <div class="d-flex flex-column p0 h100">
      <div class="p-0">
        <mat-toolbar>
          LOGIN
        </mat-toolbar>
      </div>
      <div class="p-2">
        <div class="d-flex flex-column">
          <div class="p-0">
            
            <mat-label class='mat-caption'>Email</mat-label>
            <input class="formControl mat-body" [(ngModel)]='email' type="text"  >

            <!-- <mat-form-field class="w100" appearance="outline">
              <mat-label>Email</mat-label>
              <input matInput [(ngModel)]='email'>
            </mat-form-field> -->
          </div>
          <div class="p-0">
            <mat-label class='mat-caption'>Password</mat-label>
            <input class="formControl mat-body" [(ngModel)]='password' type="password"  >
            <!-- <mat-form-field class="w100" appearance="outline">
              <mat-label>Password</mat-label>
              <input [(ngModel)]='password' matInput type="password">
            </mat-form-field> -->
          </div>

        </div>
      </div>
      <div class="p-0 mt-auto">
       <div class="d-flex justify-content-center">
        <!-- <div class="p-2">
          <button mat-raised-button (click)="dashboard()">DASH</button>
        </div> -->
        <div class="p-2">
          <button mat-flat-button (click)="signIn()">LOGIN</button>
        </div>
       </div>
      </div>
    </div>
  </div>
</mat-card>