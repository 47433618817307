import { Component, Input, OnInit } from '@angular/core';
import { apiRequest, API_CALL } from 'src/app/core/api.call.enum';
import { ITemplateReportMonthItem, IUserSessionRepItem, IYearReportItemSVG } from 'src/app/core/models/template.report.model';
import { ApiService } from 'src/app/services/api.service';
import * as _ from "lodash"
import { CgdatePipe } from 'src/app/cgdate.pipe';
@Component({
  selector: 'dxfx-template-chart',
  templateUrl: './template-chart.component.html',
  styleUrls: ['./template-chart.component.scss'],
  providers: [CgdatePipe]
})
export class TemplateChartComponent implements OnInit {

  @Input() apiCall!:API_CALL
  @Input() chartTitle: string = ""
  @Input() year!:number

  private _userOwn: ITemplateReportMonthItem[] = []
  private _userOwnLast: ITemplateReportMonthItem[] = []
  private _userSessions: IUserSessionRepItem[] = []
  get reportSvgItems(): IYearReportItemSVG[] {
    return this._reportSvgItems
  }

  private _reportSvgItems: IYearReportItemSVG[] = []
  constructor(
    private datePipe: CgdatePipe,
    private api:ApiService
  ) { }

  ngOnInit(): void {
    console.log("TemplateChartComponent INIT")
    setTimeout(()=>{
      this.initInvoices(this.year)
    },1000)
  }


  private async initInvoices(year: number) {
    this._reportSvgItems = []

    /* if(this.apiCall == API_CALL.GET_USERSESSIONS_IN_YEAR) {

    } else {
      
    }
     */
    if(this.apiCall == API_CALL.GET_USERSESSIONS_IN_YEAR) {
      (await this.api.apiGetP(apiRequest(this.apiCall))).pipe().subscribe(
        _data => {
          this._userSessions = _data as IUserSessionRepItem[]
          // this._userSessions.sort((a,b) => a.)
          this.fixSessions()
          console.log(this._userSessions);
          
        }
      )
    } else {
      const path = apiRequest(this.apiCall,year.toString());
      (await this.api.apiGetP(path)).pipe().subscribe(
        _data => {
          // console.log(_data);
          
          this._userOwn = _data as ITemplateReportMonthItem[]
          this._userOwn = this._userOwn.sort((a,b) => a.month - b.month)
          this.initInvoicesLast(year - 1)
  
        }
      )
    }

    // const path = apiRequest(this.apiCall,year.toString());
    // (await this.api.apiGetP(path)).pipe().subscribe(
    //   _data => {
    //     // console.log(_data);
        
    //     this._userOwn = _data as ITemplateReportMonthItem[]
    //     this._userOwn = this._userOwn.sort((a,b) => a.month - b.month)
    //     this.initInvoicesLast(year - 1)

    //   }
    // )
  }
  private async initInvoicesLast(year: number) {


    const path = apiRequest(this.apiCall,year.toString());
    (await this.api.apiGetP(path)).pipe().subscribe(
      _data => {
        this._userOwnLast = _data as ITemplateReportMonthItem[]
        this._userOwnLast.sort((a,b) => a.month - b.month)
        this.fix()

      }
    )
  }
  private async fix() {
    this._reportSvgItems = []
    let maxCurrent = _.maxBy(this._userOwn, function(o) {return o.count})!.count
    let maxLast = _.maxBy(this._userOwnLast, function(o) {return o.count})!.count
    let maxValue = maxCurrent >= maxLast ? maxCurrent : maxLast
    let maxPercentFact = 150 / maxValue
    var _startX = 10

    for(var i = 0; i < this._userOwn.length; i ++) {

      let current = this._userOwn[i]

      let _index = this._userOwnLast.findIndex(item => item.month == current.month)
      if (_index != -1) {
        let currentLast = this._userOwnLast[_index]

        let _x = _startX
        let _x2 = _startX + 24
        let _h1 = (current.count * maxPercentFact) < 5 ? 5 :  (current.count * maxPercentFact)
        let _h2 = (currentLast.count * maxPercentFact) < 5 ? 5 : (currentLast.count * maxPercentFact)

        let _l1 = (current.count).toString().length
        let _textX = _l1 == 3 ? 4 : _l1 == 1 ? 8 : 6

        let _l2 = (currentLast.count).toString().length
        let _textX2 = _l2 == 3 ? 4 : _l2 == 1 ? 8 : 6

        let _newMonth: IYearReportItemSVG = {
          id: `${i.toString()}-${this.apiCall.toString()}`,
          idCurrent: `${i}-1-${this.apiCall.toString()}`,
          idLast: `${i}-2-${this.apiCall.toString()}`,
          month: current.month.toString(),
          countTemplates: current.count,
          countTemplatesLastYear: currentLast.count,
          height: _h1.toString(),
          heightLastYear: _h2.toString(),
          x: (_x).toString(),
          textX: (_x + _textX).toString(),
          textXLastYear: (_x2 + _textX2).toString(),
          xLastYear: (_x2).toString(),
          y: ( (150 - _h1) + 50 ).toString(),
          textY: ( (150 - _h1) + 44 ).toString(),
          yLastYear:((150 - _h2) + 50).toString(),
          textYLastYear:((150 - _h2) + 44).toString(),
        }

        this._reportSvgItems.push(_newMonth)
        _startX += 54
      }


    }
  }

  private async fixSessions() {
    this._reportSvgItems = []
    let maxCurrent = _.maxBy(this._userSessions, function(o) {return o.currentYear})!.currentYear
    let maxLast = _.maxBy(this._userSessions, function(o) {return o.lastYear})!.lastYear
    let maxValue = maxCurrent >= maxLast ? maxCurrent : maxLast
    let maxPercentFact = 150 / maxValue
    var _startX = 10

    for(var i = 0; i < this._userSessions.length; i ++) {

      let currentMonth = this._userSessions[i].month
      let current = this._userSessions[i].currentYear
      let currentLast = this._userSessions[i].lastYear
      let _x = _startX
      let _x2 = _startX + 24
      let _h1 = (current * maxPercentFact) < 5 ? 5 :  (current * maxPercentFact)
      let _h2 = (currentLast * maxPercentFact) < 5 ? 5 : (currentLast * maxPercentFact)

      let _l1 = (current).toString().length
      let _textX = _l1 == 3 ? 4 : _l1 == 1 ? 8 : 6

      let _l2 = (currentLast).toString().length
      let _textX2 = _l2 == 3 ? 4 : _l2 == 1 ? 8 : 6

      let _newMonth: IYearReportItemSVG = {
        id: `${i.toString()}-${this.apiCall.toString()}`,
        idCurrent: `${i}-1-${this.apiCall.toString()}`,
        idLast: `${i}-2-${this.apiCall.toString()}`,
        month: currentMonth.toString(),
        countTemplates: current,
        countTemplatesLastYear: currentLast,
        height: _h1.toString(),
        heightLastYear: _h2.toString(),
        x: (_x).toString(),
        textX: (_x + _textX).toString(),
        textXLastYear: (_x2 + _textX2).toString(),
        xLastYear: (_x2).toString(),
        y: ( (150 - _h1) + 50 ).toString(),
        textY: ( (150 - _h1) + 44 ).toString(),
        yLastYear:((150 - _h2) + 50).toString(),
        textYLastYear:((150 - _h2) + 44).toString(),
      }

      this._reportSvgItems.push(_newMonth)
      _startX += 54
      // let _index = this._userOwnLast.findIndex(item => item.month == current.month)
      // if (_index != -1) {
      //   let currentLast = this._userOwnLast[_index]

      //   let _x = _startX
      //   let _x2 = _startX + 24
      //   let _h1 = (current.count * maxPercentFact) < 5 ? 5 :  (current.count * maxPercentFact)
      //   let _h2 = (currentLast.count * maxPercentFact) < 5 ? 5 : (currentLast.count * maxPercentFact)

      //   let _l1 = (current.count).toString().length
      //   let _textX = _l1 == 3 ? 4 : _l1 == 1 ? 8 : 6

      //   let _l2 = (currentLast.count).toString().length
      //   let _textX2 = _l2 == 3 ? 4 : _l2 == 1 ? 8 : 6

      //   let _newMonth: IYearReportItemSVG = {
      //     id: `${i.toString()}-${this.apiCall.toString()}`,
      //     idCurrent: `${i}-1-${this.apiCall.toString()}`,
      //     idLast: `${i}-2-${this.apiCall.toString()}`,
      //     month: current.month.toString(),
      //     countTemplates: current.count,
      //     countTemplatesLastYear: currentLast.count,
      //     height: _h1.toString(),
      //     heightLastYear: _h2.toString(),
      //     x: (_x).toString(),
      //     textX: (_x + _textX).toString(),
      //     textXLastYear: (_x2 + _textX2).toString(),
      //     xLastYear: (_x2).toString(),
      //     y: ( (150 - _h1) + 50 ).toString(),
      //     textY: ( (150 - _h1) + 44 ).toString(),
      //     yLastYear:((150 - _h2) + 50).toString(),
      //     textYLastYear:((150 - _h2) + 44).toString(),
      //   }

      //   this._reportSvgItems.push(_newMonth)
      //   _startX += 54
      // }


    }
  }

  get countLastYear(): number {
    let _count = 0
    this.reportSvgItems.forEach(item => {
      _count += item.countTemplatesLastYear
    })
    return _count
  }
  get countCurrentYear(): number {
    let _count = 0
    this.reportSvgItems.forEach(item => {
      _count += item.countTemplates
    })
    return _count
  }
  getToolTip(month: string): string {
    var out = ""//<div class="container"></div>'
    if(month) {
      var us1 = []
      var us2 = []
      var us3 = []
      var us4 = []

      let currentIndex = this._userSessions.findIndex((item) => item.month.toString() == month)
      if(currentIndex != -1) {

        let all = this._userSessions[currentIndex].usersCurrent.sort((a,b) => b.count!.length - a.count!.length)
        
        var counter = 1
        if(all.length > 3) {
          counter = 3
        } else {
          counter = all.length - 1
        }

        for(var i = 0; i < counter; i ++) {
          let current = all[i]
          if(current.count && current.count.length > 0) {
            current.count.forEach((ele) => {
              out += `${current.email} \t\t\t\t\t\t ${this.datePipe.transform(new Date(ele.dateTime))} \n`
              // out += `<div class="container"><span style="width: 200px">${current.email}</span><span style="width: 200px"> ${this.datePipe.transform(new Date(ele.dateTime))}</span></div> \n`
              
              
            })
          }
          
        }
      }
    }
    return out
  }
}
