import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { API_REQUESTS, ClinicApi } from 'src/app/core/clinic.api';
import { API_CALL_CLINIC } from 'src/app/core/models/clinic.call.enum';
import { IAddress, IClinic } from 'src/app/core/models/clinic.model';
import { IClinicSector } from 'src/app/core/models/clinic.sector.model';

@Component({
  selector: 'dxfx-create-new-sector-overlay',
  templateUrl: './create-new-sector-overlay.component.html',
  styleUrls: ['./create-new-sector-overlay.component.scss']
})
export class CreateNewSectorOverlayComponent implements OnInit {


  clinic?:IClinic

  __pool_id =  ""
  constructor(
    private dialogRef:MatDialogRef<CreateNewSectorOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: IClinic,
    private clinicAPI: ClinicApi) {
      // this.__pool_id = data;
      this.clinic = data
      console.log('this.clinic', this.clinic);

    }

  ngOnInit(): void {
    console.log('CreateNewSectorOverlayComponent');
    if(this.clinic){
      this.address.clinicLegalMain = this.clinic.clinicLegalMain
      this.address.clinicInvoiceProviderFirst = this.clinic.clinicInvoiceProviderFirst
      this.address.clinicInvoiceProviderSecond = this.clinic.clinicInvoiceProviderSecond
      this.address.clinicInvoiceProviderThird = this.clinic.clinicInvoiceProviderThird
      this.address.clinicInvoiceProviderFourth = this.clinic.clinicInvoiceProviderFourth
      this.address.zip = this.clinic.address_zip
      this.address.city = this.clinic.address_city
      this.address.ust_nr = this.clinic.ustNr

      this.address.streetName = this.clinic.address_street
      this.address.kontoinhaber = this.clinic.bank_owner
      this.address.bank_name = this.clinic.bank_name
      this.address.klinikName = this.clinic.title
      this.address.iban = this.clinic.iban
      this.address.bic = this.clinic.bic
      
    }
  }

  sector:IClinicSector = {
    title: "Neuer Fachbereich",
    description: "Beschreibung zum Fachbereich",
    clinic_sector_id: -1,
    pool_id: "-1",
    clinic_id: "-1"
  }
  address:IAddress = {
    id:-1,

    abteilung:"",
    arzt_role:"",
    bank_name:"",
    behandelnder_arzt:"",
    bic:"",
    city:"",
    facharzt_bezeichnung:"",
    iban:"",
    klinikName:"",
    kontoinhaber:"",
    streetName:"",
    streetNumber:"",
    ust_nr:"",
    zip:"",
    isMainAddress:false,
    isClinic: true,
    clinicLegalMain: "Die Rechnungserstellung erfolgt im Auftrag von ...",
    clinicInvoiceProviderFirst:  "",
    clinicInvoiceProviderSecond:  "",
    clinicInvoiceProviderThird:  "",
    clinicInvoiceProviderFourth: "",

  }

  close(){
    this.dialogRef.close()
  }
  // onAddressCopy(){
  //   if ( this.clinic ) {
  //     this.address.klinikName = this.clinic.title
  //     this.address.bank_name = this.clinic.bank_name
  //     this.address.kontoinhaber = this.clinic.bank_owner
  //     this.address.streetName = this.clinic.address_street
  //     this.address.zip = this.clinic.address_zip
  //     this.address.city = this.clinic.address_city
  //     this.address.iban = this.clinic.iban
  //     this.address.bic = this.clinic.bic
  //     this.address.ust_nr = this.clinic.ustNr
  //   }
  // }

get saveEnabled(): boolean {

  if (this.clinic && this.address.streetName != "" && this.address.city != "" &&
  this.address.zip != "" && this.address.klinikName != "") {
    return true
  } else {
    return false
  }



}

async save() {
  const _path = API_REQUESTS(API_CALL_CLINIC.POST_CREATE_SECTOR)

  var _dto = {
    clinic_id: this.clinic!.id,
    title: this.sector.title,
    description: this.sector.description,
    address: this.address
  }

      await this.clinicAPI.post(_path,
        {
          dto: _dto
        }
      )

    // const _path = API_REQUESTS(API_CALL_CLINIC.CREATE_ADDRESS)
    // await this.clinicAPI.post(_path,{ payload: this.address, pool_id: this.__pool_id})
    this.dialogRef.close({reload:true})
  }
}



