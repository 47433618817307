// export const environment = {
//   production: true,
//   firebase : {
//     apiKey: "AIzaSyBdE8P91vEMVUwSykLjb1HvtOuiozhM7JU",
//     authDomain: "doxfox-41ab0.firebaseapp.com",
//     databaseURL: "https://doxfox-41ab0.firebaseio.com",
//     projectId: "doxfox-41ab0",
//     storageBucket: "doxfox-41ab0.appspot.com",
//     messagingSenderId: "702289604880",
//     appId: "1:702289604880:web:2d398ed1e45510b3a63595",
//     measurementId: "G-96R4020SKD"
//   },
//   endpoints:{
//     apiBasePath:"",
//     apiBasePathV2: "https://dxfx-express-w6ex57k2ja-ey.a.run.app/",
//   },
//   cryptCode: '',

// };


export const environment = {
  production: true,
  firebase : {
    apiKey: "AIzaSyApJx9K6CSIclKz-PdkwxfQBymn7kpoPXY",
    authDomain: "dxfx-dev.firebaseapp.com",
    databaseURL: "https://dxfx-dev-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "dxfx-dev",
    storageBucket: "dxfx-dev.appspot.com",
    messagingSenderId: "632669652214",
    appId: "1:632669652214:web:4853191731fa8ecde098d7"
  },
  endpoints:{
    apiBasePath:"",
    apiBasePathV2: "https://dxfx-express-api-rebuild-bd6vktduma-ey.a.run.app/",
    // apiBasePathV2: "https://dev-api.d0xf0x.de/",
  },
  cryptCode: '',
  EncryptKey:  "sa9jdas9d$#;asd8",
  EncryptIV:   "sa9jdas9d$#;asd8",
  SOCKET_ENDPOINT: 'https://dxfx-express-api-rebuild-bd6vktduma-ey.a.run.app'
};
