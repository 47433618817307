import { Component, OnInit } from '@angular/core';
import { apiRequest, API_CALL } from 'src/app/core/api.call.enum';
import { IAuthReporItem, IAuthReporItemFixed, IAuthReportByType, ITemplateReportFull, ITemplateReportMonthItem, IUserStatItem, IYearReportItemSVG, LogType } from 'src/app/core/models/template.report.model';
import { ApiService } from 'src/app/services/api.service';
import * as _ from "lodash"
import { UiService } from 'src/app/services/ui.service';
@Component({
  selector: 'dxfx-template-reports',
  templateUrl: './template-reports.component.html',
  styleUrls: ['./template-reports.component.scss']
})
export class TemplateReportsComponent implements OnInit {


  calls = API_CALL

  // maxHeight = 150
  // startY = 50
  // yFact = 50 / 150
  months = ["Jan","Feb","Mär","Apr","Mai","Jun","Jul","Aug","Sep","Okt","Nov","Dez"]
  _reports: ITemplateReportFull[] = []
  // _userOwn: ITemplateReportMonthItem[] = []
  // userOwnCurrent = 0
  // userOwnLast = 0
  currentYear = new Date().getFullYear()

  userOwnSvg: IYearReportItemSVG[] = []
  processesSvg: IYearReportItemSVG[] = []
  invoicesSvg: IYearReportItemSVG[] = []
  deletedUserOwnSvg: IYearReportItemSVG[] = []
  deletedProcessesSvg: IYearReportItemSVG[] = []
  deletedAdminTemplatesSvg: IYearReportItemSVG[] = []
  deletedAdminProcessesSvg: IYearReportItemSVG[] = []

  _userStats: IUserStatItem[] = []
  // authReport: IAuthReporItem[] = []
  // authReportFixed: IAuthReporItemFixed[] = []
  constructor(private api:ApiService, private ui: UiService) { }

  ngOnInit(): void {
    console.log('REPORTS');
    this.loadUserStats()
    setTimeout(()=>{
      this.ui.currentPage.emit('report');
    },1000)
    // this.authReport = []
    // this.loadAuthReport()

    // this.authReportFixed = []
    // this.load(2023)
    // this.userOwn(this.currentYear)
    // this.initProcesses(this.currentYear)
    // this.initInvoices(this.currentYear)
    // this.userOwnDeleted(this.currentYear)
    // this.processesDeleted(this.currentYear)
    // this.adminProcessesDeleted(this.currentYear)
    // this.adminTtemplatesDeleted(this.currentYear)

  }
 
 
  // authReport: IAuthReporItem[] = []
  // authReportFixed: IAuthReporItemFixed[] = []


  private async loadUserStats() {
    (await this.api.apiGet(API_CALL.GET_USER_STATS)).pipe().subscribe(
      _data => {
        console.log(_data)
        this._userStats = _data as IUserStatItem[];
      }
    )
  }

  // private async loadAuthReport() {
  //   (await this.api.apiGet(API_CALL.GET_AUTH_REPORT)).pipe().subscribe(
  //     _data => {
  //       console.log(_data)
  //       this.authReport = _data as IAuthReporItem[]
  //       this.authReport.sort((a, b) => a.dateStart - b.dateStart)

  //       for(var i = 0; i < this.authReport.length; i ++) {
  //         var _fixedByType: IAuthReportByType[] = []
  //         const _current = this.authReport[i]
  //         const _invites = _current.items.filter(item => item.logType == LogType.INVITE)

  //         _fixedByType.push({
  //           logType: LogType.INVITE,
  //           items: _invites
  //         })


  //         const _sessions = _current.items.filter(item => item.logType == LogType.SESSION_START)

  //         _fixedByType.push({
  //           logType: LogType.SESSION_START,
  //           items: _sessions
  //         })
  //         let _new: IAuthReporItemFixed = {
  //           dateEnd: _current.dateEnd,
  //           dateStart: _current.dateStart,
  //           items: _fixedByType
  //         }
  //         this.authReportFixed.push(_new)
  //       }
  //       console.log(this.authReport)
  //     }
  //   )
  // }


  get reports(): ITemplateReportFull[]{
    return this._reports.sort((a,b) => b.year - a.year)
  }

  changeSort(p: string) {
    this.userSortMode = p;
  }


  userSortMode = 'email'


  get userStats(): IUserStatItem[] {
    if(this.userSortMode === 'email') {
      this._userStats = _.orderBy(this._userStats, ['email'], ['desc'])
      return _.orderBy(this._userStats, ['email'], ['desc'])//this._userStats;
    } else if(this.userSortMode === 'invoices') {
      return this._userStats.sort((a,b) => b.invoiceCount - a.invoiceCount)
    } else if(this.userSortMode === 'processes') {
      return this._userStats.sort((a,b) => b.processCreated - a.processCreated)
    } else if(this.userSortMode === 'sReports') {
      return this._userStats.sort((a,b) => b.surgeryReportCreated - a.surgeryReportCreated)
    } else if(this.userSortMode === 'templatesCloned') {
      return this._userStats.sort((a,b) => b.templateCloned - a.templateCloned)
    } else if(this.userSortMode === 'templatesCreated') {
      return this._userStats.sort((a,b) => b.templateCreated - a.templateCreated)
    } else {
      return []
    }
    // return this._userStats
  }
}
