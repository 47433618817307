
<mat-toolbar class="overlay-toolbar">
    Abrechnung anlegen
	<span class="toolbar-spacer"></span>
  <mat-icon class="dxfx_icon_btn_medium ml5" (click)='close()'>close</mat-icon>
</mat-toolbar>

<div class="container-fluid m_t5" *ngIf="viewMode === viewModes.CREATE_ACCOUNTING">

    <div class="d-flex flex-column m_t10" style="background-color: rgba(0,0,0,0.05)">

        <div class="p-2">
          <input class="formControl mat-body h2Size"
          [(ngModel)]="accountingTitle" >
        </div>
    
        <!-- <div class="p-2 m_t10">
          <textarea style="width:100%; height: 100%; background-color:rgba(0,0,0,0);  resize: vertical !important;"
            maxlength="300" [(ngModel)]="sector.description" rows="2" class="mat-body formControl"  #multiLine>
            </textarea>
        </div> -->
      </div>
    </div>

<div class="container-fluid m_t5" *ngIf="viewMode === viewModes.ACCOUNTING_DETAIL">

  <div class="d-flex flex-column m_t10" style="background-color: rgba(0,0,0,0.05)">

      <div class="p-2">
        <input class="formControl mat-body h2Size"
        [(ngModel)]="accountingTitle" >
      </div>
  
    
  </div>
</div>


<div class="container-fluid m_t5" *ngIf="viewMode === viewModes.CREATE_CLINIC_ADMIN">

  <div class="d-flex flex-column m_t10" >

      <div class="p-2">
        <input class="formControl mat-body "
        [(ngModel)]="email" >
      </div>
  
    
  </div>
  <div class="d-flex  m_t10" >

      <div class="p-2">
        <input class="formControl mat-body "
        [(ngModel)]="firstName" >
      </div>
      <div class="p-2">
        <input class="formControl mat-body "
        [(ngModel)]="lastName" >
      </div>
  
    
  </div>
</div>
<mat-toolbar class="overlay-toolbar">
  <span class="toolbar-spacer"></span>
  <dxfx-df-icon-button
  title="Speichern" icon="save" [disabled]="!saveEnabled"
 (clicked)="save()" ></dxfx-df-icon-button>
</mat-toolbar>
