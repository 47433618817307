import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { DashboardComponent } from './dashboard/dashboard.component';
import { SignInComponent } from './sign-in/sign-in.component';
// import { AngularFireAuthGuard, hasCustomClaim, redirectUnauthorizedTo, redirectLoggedInTo, customClaims } from '@angular/fire/compat/auth-guard';
import { AuthGuard } from '../guard/auth.guard';
import { EmailDetailComponent } from './email-detail/email-detail.component';
import { EmailTemplateOverviewComponent } from './email-template-overview/email-template-overview.component';

import { UsersComponent } from './users/users.component';
import { ClinicComponent } from './clinic/clinic.component';
import { ClinicDetailComponent } from './clinic-detail/clinic-detail.component';
import { TemplateReportsComponent } from './template-reports/template-reports.component';
import { UserAuthReportComponent } from './user-auth-report/user-auth-report.component';
// const adminOnly = () => hasCustomClaim('admin');
// // const dashboard = () => customClaims('admin');
// const redirectUnauthorizedToLogin = () => redirectUnauthorizedTo(['sign-in']);
// const redirectLoggedInToDashboard = () => redirectLoggedInTo(['dashboard']);



//const editorOnly = () => pipe(customClaims, map(claims => claims.admin === 'admin'));

const routes: Routes = [
  {
    path: '', redirectTo: '/users', pathMatch: 'full'
  },
  {
    path: 'dashboard', component: DashboardComponent, canActivate: [AuthGuard], data: { access:'DASHBOARD' },
  },
  {
    path: 'report', component: TemplateReportsComponent, canActivate: [AuthGuard], data:{ access:'REPORT' },
  },
  {
    path: 'user-report', component: UserAuthReportComponent, canActivate: [AuthGuard], data: { access: 'REPORT' },
  },
  {
    path: 'klinik', component: ClinicComponent, canActivate: [AuthGuard], data:{ access:'ADMIN_CLINICS' },
  },

  {
    path: 'klinik-detail/:id', component: ClinicDetailComponent, canActivate: [AuthGuard], data:{ access: 'ADMIN_CLINICS' },
  },

  {
    path: 'users', component: UsersComponent, canActivate: [AuthGuard], data: {access: 'USERS'},
  },
  {
    path: 'email-templates', component: EmailTemplateOverviewComponent, canActivate: [AuthGuard], data: { access:'MAILING' },
  },
  // {
  //   path: 'template-pools', component: TemplatePoolComponent, canActivate: [AuthGuard], data: { klaus:'fetzt' },
  // },
  {
    path: 'email-detail', component: EmailDetailComponent, canActivate: [AuthGuard], data: { access:'MAILING' },
  },
  {
    path: 'sign-in', component:SignInComponent, //canActivate: [AngularFireAuthGuard], data: { authGuardPipe: redirectUnauthorizedToLogin },
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
