<div class="chartBox">
  <svg width="660px" height="290px" viewBox="0 0 660 290" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">

    <g [attr.id]="apiCall" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <text id="Jan" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="24" y="220">Jan</tspan>
        </text>
        <text id="Feb" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="78" y="220">Feb</tspan>
        </text>
        <text id="Mär" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="132" y="220">Mär</tspan>
        </text>
        <text id="Apr" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="186" y="220">Apr</tspan>
        </text>
        <text id="Mai" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="240" y="220">Mai</tspan>
        </text>
        <text id="Jun" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="294" y="220">Jun</tspan>
        </text>
        <text id="Jul" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="348" y="220">Jul</tspan>
        </text>
        <text id="Aug" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="402" y="220">Aug</tspan>
        </text>
        <text id="Sep" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="456" y="220">Sep</tspan>
        </text>
        <text id="Okt" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="510" y="220">Okt</tspan>
        </text>
        <text id="Nov" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="564" y="220">Nov</tspan>
        </text>
        <text id="Dez" font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C">
            <tspan x="618" y="220">Dez</tspan>
        </text>

      <g *ngFor="let m of reportSvgItems"
        [attr.id]="m.id"
      >
          <rect [attr.id]="m.idCurrent" fill="#1876D2" [attr.x]="m.x" [attr.y]="m.y" width="24"  [attr.height]="m.height" [matTooltip]="getToolTip(m.month)" matTooltipPosition="above" matTooltipClass="baseBigTT"></rect>
          <rect [attr.id]="m.idLast" fill="#63A7FF" [attr.x]="m.xLastYear" [attr.y]="m.yLastYear" width="24"  [attr.height]="m.heightLastYear"></rect>
          <text font-family="Roboto-Regular, Roboto" font-size="12" font-weight="normal" fill="#1D1C1C" >
            <tspan [attr.x]="m.textX" [attr.y]="m.textY" width="26" textAlign="center">{{m.countTemplates}}</tspan>
            <tspan [attr.x]="m.textXLastYear" [attr.y]="m.textYLastYear" width="26" textAlign="center">{{m.countTemplatesLastYear}}</tspan>
          </text>
      </g>


        <g id="vorjahr" transform="translate(9.000000, 235.000000)">
            <rect id="Rectangle" fill="#1876D2" x="0" y="0" width="210" height="22" rx="2"></rect>
            <text id="2021" font-family="Roboto-Black, Roboto" font-size="12" font-weight="normal" fill="#FFFFFF">
                <tspan x="176.203125" y="15">{{countCurrentYear}}</tspan>
            </text>
            <text id="gesamt-vorjahr:" font-family="Roboto-Medium, Roboto" font-size="12" font-weight="400" fill="#FFFFFF">
                <tspan x="6" y="15">gesamt: {{year}}</tspan>
            </text>
        </g>
        <g id="aktuelles-jahr" transform="translate(9.000000, 257.000000)">
            <rect id="Rectangle-Copy-4" fill="#63A7FF" x="0" y="0" width="210" height="22" rx="2"></rect>
            <text id="2021" font-family="Roboto-Black, Roboto" font-size="12" font-weight="normal" fill="#FFFFFF">
                <tspan x="176.203125" y="16">{{countLastYear}}</tspan>
            </text>
            <text id="gesamt:-2023" font-family="Roboto-Medium, Roboto" font-size="12" font-weight="400" fill="#FFFFFF">
                <tspan x="7" y="15">gesamt vorjahr:</tspan>
            </text>
        </g>
        <rect id="Rectangle" fill="#C6C8CB" transform="translate(330.000000, 203.500000) scale(1, -1) translate(-330.000000, -203.500000) " x="0" y="203" width="660" height="1"></rect>
        <rect id="Rectangle" fill="#C6C8CB" transform="translate(330.000000, 227.500000) scale(1, -1) translate(-330.000000, -227.500000) " x="0" y="227" width="660" height="1"></rect>
        <text id="Gelöschte-Vorgänge:" font-family="Roboto-Medium, Roboto" font-size="12" font-weight="400" fill="#1D1C1C">
            <tspan x="10" y="25">{{chartTitle}}</tspan>
            <!-- <tspan x="10" y="25">{{chartTitle}}:{{apiCall}}</tspan> -->
        </text>
    </g>
</svg>
</div>
