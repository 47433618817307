import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IAccountingPoolFull } from 'src/app/core/models/accounting.model';
import { AccountingOverlayData, AccuntingOverlayMode, AreaOfExpertise } from 'src/app/core/models/user-clinic-select-overlay-data.model';
import { USER_API_CALL, UserApi } from 'src/app/services/api.user.service';
import { ClinicService } from 'src/app/services/clinic.service';
import * as _ from 'lodash';
@Component({
  selector: 'dxfx-accounting-detail-overlay',
  templateUrl: './accounting-detail-overlay.component.html',
  styleUrls: ['./accounting-detail-overlay.component.scss']
})
export class AccountingDetailOverlayComponent implements OnInit {

  accountingTitle: string = "Abrechnungs Bezeichnung"
  email: string = ""
  firstName: string = "ADMIN"
  lastName: string = "ADMIN"

  overlayData?: AccountingOverlayData
  viewMode: AccuntingOverlayMode = AccuntingOverlayMode.NONE
  viewModes = AccuntingOverlayMode
  areaOfExpertisAll: AreaOfExpertise[] = []
  accountingPoolFull?: IAccountingPoolFull 
  constructor(
    private dialogRef:MatDialogRef<AccountingDetailOverlayComponent>,
    @Inject(MAT_DIALOG_DATA) public data: AccountingOverlayData,
    private userApi: UserApi,
    private cs: ClinicService) {
      this.overlayData = data

    }

  ngOnInit(): void {
    this.loadData()
    console.log('AccountingDetailOverlayComponent');

    if(this.overlayData) {
      this.viewMode = this.overlayData.mode
      if(this.viewMode == AccuntingOverlayMode.ACCOUNTING_DETAIL) {
        if(this.overlayData.accounting_pool_id){
          this.loadFullPool(this.overlayData.accounting_pool_id)
        }
      }
    }
  }

  private async loadFullPool(id:number) {
    // this.cs.accountingPoolFull(id)
  }
  close(){
    this.dialogRef.close()
  }
 
get saveEnabled(): boolean {

 
  return true
}


private async loadData() {

    
    const _data2:any = await this.userApi.get(USER_API_CALL.GET_AREAS_OF_EXPERTISE_ADMIN)
    console.log(_data2)
    this.areaOfExpertisAll = _data2 as AreaOfExpertise[]
  
}

async save() {
  var _fachgebiete = []
  _fachgebiete = _.map(this.areaOfExpertisAll, 'id')
  if(this.overlayData && this.viewMode) {
    if(this.viewMode == AccuntingOverlayMode.CREATE_CLINIC_ADMIN) {
      this.cs.createClinicAdmin(this.overlayData.clinic_id, {
        clinicId: this.overlayData.clinic_id,
        firstName: this.firstName, 
        lastName: this.lastName,
        email: this.email,
        managedBy: this.overlayData.clinic_id,
        fachgebiete: _fachgebiete, 
        phone: "",
      })
        .then(x => {
        this.close()
      })
    }
    
  }
  
  // this.cs.createAccountingForClinic(this.overlayData!.clinic_id, this.accountingTitle)
  // // await this.clinicAPI.post(API_REQUESTS(API_CALL_CLINIC.CREATE_ACCOUNTING),{clinicId: this.overlayData!.clinic_id, description: this.accountingTitle})
  // setTimeout(()=>{
  //   this.dialogRef.close()
  // },150)
  }
}




