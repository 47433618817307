import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { apiRequest, API_CALL } from 'src/app/core/api.call.enum';
import { ClinicApi } from 'src/app/core/clinic.api';
import { UserI } from 'src/app/core/models/docAccount';
import { ChangeClinicSectorForUserOverlayData } from 'src/app/core/models/overlay.data.model';
import { IPoolUser } from 'src/app/core/models/poolUser.model';
import { IUserAccessType, IUserStateTypeMessage, STATE_MESSAGE_VISIBILITY } from 'src/app/core/models/user.states.model';
import { ApiService } from 'src/app/services/api.service';
import { PoolService } from 'src/app/services/pool.service';
import { UiService } from 'src/app/services/ui.service';

@Component({
  selector: 'dxfx-change-user-access',
  templateUrl: './change-user-access.component.html',
  styleUrls: ['./change-user-access.component.scss']
})
export class ChangeUserAccessComponent implements OnInit {

  // currentUserState: any

  // selectedBlockMessage?: any

  private _uid = ""
  private _initialUserStateTypeId?: number
  private _initialUserStateTypeMessageId?: number
  private _initAccessItem?:IUserAccessType

  currentAccessItem?:IUserAccessType
  selectedUserStateTypeId:any
  // selectedUserStateTypeMessageId?:number


  private _poolUser?: IPoolUser
  private _baseUser?: UserI
  constructor(
    public ui:UiService,
    private apiService: ApiService,
    private clinicAPI: ClinicApi,
    public dialogRef: MatDialogRef<ChangeUserAccessComponent>,
    @Inject(MAT_DIALOG_DATA) public data: ChangeClinicSectorForUserOverlayData

  ) {
    if ( data.isClinicMode && data.forPoolUser) {

      this._poolUser = data.forPoolUser
      this._uid = this._poolUser.uid
    } else if (data.baseUser) {
      this._baseUser = data.baseUser
      this._uid = this._baseUser.uid
    }
  }

  ngOnInit(): void {
    console.log('ChangeUserAccessComponent INIT');


    this._initialUserStateTypeId = this._baseUser!.user_access_type_id
    // this._initialUserStateTypeMessageId = this._poolUser!.user_state_type_message_id
    this.selectedUserStateTypeId = this._baseUser!.user_access_type_id
    console.log("selectedUserStateTypeIdselectedUserStateTypeId ", this.selectedUserStateTypeId);
    

    const _index = this.ui.allUserAccessTypes.findIndex(item => item.id == this._initialUserStateTypeId)
    if ( _index != -1 ) {
      this._initAccessItem = this.ui.allUserAccessTypes[_index]
      this.currentAccessItem = this.ui.allUserAccessTypes[_index]
    }


    // if( this._poolUser ) {
    //   console.log(this._poolUser);
    //   // this.currentUserState = this._poolUser?.user_state_type_id

    //   this._initialUserStateTypeId = this._poolUser!.user_access_type_id
    //   // this._initialUserStateTypeMessageId = this._poolUser!.user_state_type_message_id
    //   this.selectedUserStateTypeId = this._poolUser!.user_access_type_id

    //   const _index = this.ui.allUserAccessTypes.findIndex(item => item.id == this._initialUserStateTypeId)
    //   if ( _index != -1 ) {
    //     this._initAccessItem = this.ui.allUserAccessTypes[_index]
    //     this.currentAccessItem = this.ui.allUserAccessTypes[_index]
    //   }


    //  /// this.selectedUserStateTypeMessageId = this._poolUser!.user_state_type_message_id
    // } else if (this._baseUser) {
    //   console.log(this._baseUser);
    //   // this._initialUserStateTypeId = this._baseUser!.user_access_type_id
      
    //   // this.selectedUserStateTypeId = this._baseUser!.user_access_type_id

    //   const _index = this.ui.allUserAccessTypes.findIndex(item => item.id == this._initialUserStateTypeId)
    //   if ( _index != -1 ) {
    //     this._initAccessItem = this.ui.allUserAccessTypes[_index]
    //     this.currentAccessItem = this.ui.allUserAccessTypes[_index]
    //   }
    // }
  }


  get allUserAccessTypes(): IUserAccessType[] {
    return this.ui.allUserAccessTypes
    if (!this.currentAccessItem) {
      return this.ui.allUserAccessTypes
    }
    let _items: IUserAccessType[] = []
    let _vis = this.data.isClinicMode ? STATE_MESSAGE_VISIBILITY.NON_CLINIC_USER : STATE_MESSAGE_VISIBILITY.CLINIC_USER
    _items = this.ui.allUserAccessTypes.filter(item => item.canChangeTo.includes(this.selectedUserStateTypeId)) // && item.visibility != _vis
    const _index = this.ui.allUserAccessTypes.findIndex(item => item.id == this.selectedUserStateTypeId)
    if ( _index != -1 ) {
      _items.push(this.ui.allUserAccessTypes[_index])
    }

    return _items
  }

  selectedSectorChanged(e:any) {
    // this.currentAccessItem = e.value

    setTimeout(()=>{
      const _index = this.ui.allUserAccessTypes.findIndex(item => item.id == e.value)
        if ( _index != -1 ) {
          console.log('_index', _index);
          this.currentAccessItem = this.ui.allUserAccessTypes[_index]
        }
      this.selectedUserStateTypeId = e.value
      console.log('selectedSectorChanged', this.currentAccessItem);
      console.log('selectedUserStateTypeId', this.selectedUserStateTypeId);
    },500)
    // this.selectedUserStateTypeId = e.value
  }



  async save() {
    const _dto = {
      uid: this._uid,
      // base_role_key: this.selectedUserStateTypeId,
      user_access_type_id: this.selectedUserStateTypeId,
      // user_state_type_message_id: this.selectedUserStateTypeMessageId,
    }
    
    const _path = apiRequest(API_CALL.POST_CHANGE_ACCESS_FOR_USER)
    await this.apiService.apiPost(_path,{
      dto: _dto
    })
    this.dialogRef.close("reload")
  }



  get showSaveButton(): boolean {
    if ( this._initAccessItem == this.currentAccessItem) {
      return false
    }
    return true
  }

  close(): void {
    this.dialogRef.close();
  }
}
