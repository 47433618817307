import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'dxfx-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss'],

})
export class SignInComponent implements OnInit {

  email:string = ''
  password:string = ''
  // email:string = 'admin@d0xf0x.de'
  // password:string = 'HsrmyG3ez2b#'
  constructor(private authS:AuthService, private router:Router) { }

  ngOnInit(): void {
    console.log('SIGNIN COMP')
  }

  signIn(){
    this.authS.signIn(this.email, this.password);
  }
  dashboard(){
    this.router.navigateByUrl('users')
  }
}
