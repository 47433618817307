import { Component, Input } from '@angular/core';

@Component({
  selector: 'dxfx-text-box',
  template: `
    <div class="w100">
      <mat-label class='mat-caption'>{{label}}</mat-label>
      <input class="formControl mat-body" [(ngModel)]='model' type="text" [disabled]=disabled >
    </div>
  `,
  styleUrls: ['./text-box.component.scss']
})
export class TextBoxComponent {

  @Input() label:string | undefined
  @Input() model:string | undefined
  @Input() disabled:boolean = false
  constructor() { }

}
