<!-- p_t20 p_b20 -->
<div class="container-fluid " style="overflow: hidden; z-index: 10; position: static;" *ngIf="fullView">

  <div class="d-flex">
    <div class="p-2" >
      Summe registriert: {{summeRegistered | cgcurrency}}
    </div>
    <div class="p-2 ms-auto">
      <dxfx-df-icon-button title="Neuen Benutzer einladen" icon="add"  (clicked)="inviteNewUser()" ></dxfx-df-icon-button>
    </div>
    <div class="p-2">
      <dxfx-df-icon-button title="-" icon="tune" (clicked)="switchView()"></dxfx-df-icon-button>
    </div>

  </div>

  <!-- <div class="d-flex">

    <div class="p-2 ms-auto">
      <dxfx-df-icon-button title="Neuen Benutzer einladen" icon="add"  (clicked)="inviteNewUser()" ></dxfx-df-icon-button>
    </div>
    <div class="p-2">
      <dxfx-df-icon-button title="-" icon="tune" (clicked)="switchView()"></dxfx-df-icon-button>
    </div>

  </div> -->

  <mat-card class="p0" style="height: calc(100vh - 150px); width: 100%; overflow: hidden;">
    <div class="container-fluid p0 ">
      <dxfx-user-list-header [fullView]="fullView"></dxfx-user-list-header>

      <!-- <div class="d-flex pl14 " style="font-size: 12px;  height: 120px; background-color:#DDECF2;">
        <mat-radio-group
          aria-labelledby="example-radio-group-label"
          class="example-radio-group"
          (change)="filterButtonChanged($event)"
          [(ngModel)]="filterGroupSelected">
          <mat-radio-button class="example-radio-button" *ngFor="let role of userFilter" [value]="role">
            {{role.name}}
          </mat-radio-button>
        </mat-radio-group>
      
      </div> -->

      <!-- <div class="d-flex _tableHeader align-items-center pl14  " style="font-size: 12px;">
        <div class="p-0 w50p m_t20 m_b10">
          <input class="formControl" placeholder="Suche..." [(ngModel)]="searchText" (keypress)="applyFilter($event.target)">
        </div>
      </div> -->
      
      
    <!-- <div class="d-flex pl14 align-items-center" style="font-size: 12px;  background-color:#DDECF2;">

      <div class="p-0 _bRight" style="width: 200px;">Email</div>
      <div class="p-0 _bRight" style="width: 200px;">Nachname</div>
      <div class="p-0 _bRight" style="width: 200px;">Vorname</div>
      <div class="p-2 ms-auto _bRight text-center" style="width: 80px;">Klinik</div>
      <div class="p-2 _bRight text-center" style="width: 90px;"> Status</div>
      <div class="p-2 _bRight text-center" style="width: 50px;"> Plan</div>

      <div class="p-0 rotatedBox"  *ngFor="let role of ui.baseUserRoles" >
        <div class="roatedbox-content">{{role.description}}</div>
      </div>
      
      <div class="p-2 _bRight"  style="width: 46px;"></div>
    </div> -->
      
    </div>

    <div class="container-fluid "  style="height: 100%; overflow-y: scroll; height: calc(100% - 240px);">  
     <div  *ngFor="let user of us.filteredUsers"  class="mat-row"
      [class._td_blocked]="user.app_access_type_enum === USER_APP_ACCESS_TYPES.BLOCKED" 
      style="font-size: 12px; border-bottom: 1px solid rgba(0, 0, 0, 0.05);">
      <dxfx-user-list-row [user]="user" (selected)="select($event)" [fullView]="fullView"></dxfx-user-list-row>
    </div>
  </div>
  
  </mat-card>
</div>

<div class="container-fluid " style="overflow: hidden; z-index: 10; position: static;" *ngIf="!fullView">

  <div class="d-flex">
    <div class="p-2" >
      Summe registriert: {{summeRegistered | cgcurrency}}
    </div>
    <div class="p-2 ms-auto">
      <dxfx-df-icon-button title="Neuen Benutzer einladen" icon="add"  (clicked)="inviteNewUser()" ></dxfx-df-icon-button>
    </div>
    <div class="p-2">
      <dxfx-df-icon-button title="-" icon="tune" (clicked)="switchView()"></dxfx-df-icon-button>
    </div>

  </div>

  <!-- <mat-toolbar class="overlay-toolbar">
    <div class="d-flex" *ngIf="hasFAC">
    
    
      <div class="p-2" >
        Summe registriert: {{summeRegistered | cgcurrency}}
      </div>

      <div class="p-2" >
        <button (click)="download()">
          Download 
      </button>
      </div>
    
    <div class="p-2 ms-auto" >
      <span (dblclick)="switchView()">Users</span>
    </div>
    
    
  </div>
    <span class="toolbar-spacer"></span>
    <dxfx-df-icon-button title="Neuen Benutzer einladen" icon="add"  (clicked)="inviteNewUser()" ></dxfx-df-icon-button>
  </mat-toolbar> -->



  <mat-card class="p0" style="height: calc(100vh - 150px); width: 100%; overflow: hidden;">
    <div class="container-fluid p0 ">
      <!-- START -->
      <dxfx-user-list-header [fullView]="fullView"></dxfx-user-list-header>
      <!-- EMD -->
    </div>
<!-- 69, 1, 20, 35, 5, 8 -->
    <div class="container-fluid " style="height: 100%; overflow-y: scroll; height: calc(100% - 240px);" *ngIf="isloading">
      <div class="d-flex h100 w100 align-items-center justify-content-center" >
        <div class="p-2">
          <mat-spinner diameter="30" color="accent"></mat-spinner>
        </div>
      </div>
    </div>

    <div class="container-fluid "  style="height: 100%; overflow-y: scroll; height: calc(100% - 220px);" *ngIf="!isloading">

     <div  *ngFor="let user of us.filteredUsers"  class="mat-row"
      [class._td_blocked]="state(user)?.appAccess === accessTypes.BLOCKED" 
      style="font-size: 12px; border-bottom: 1px solid rgba(0, 0, 0, 0.05);">
      <dxfx-user-list-row [user]="user" (selected)="select($event)"></dxfx-user-list-row>
    </div>
  </div>

  </mat-card>


</div>
 