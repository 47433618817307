import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ACCOUNT_TYPE_BASE, UserI } from 'src/app/core/models/docAccount';
import { ISubScriptionPLan } from 'src/app/core/models/role.model';
import { ApiService } from 'src/app/services/api.service';
import { USER_API_CALL, USER_API_REQUESTS, UserApi } from 'src/app/services/api.user.service';
import { UiService } from 'src/app/services/ui.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'dxfx-change-subscription-plan',
  templateUrl: './change-subscription-plan.component.html',
  styleUrls: ['./change-subscription-plan.component.scss']
})
export class ChangeSubscriptionPlanComponent implements OnInit {

  constructor(
    public ui:UiService,
    private userApi:UserApi,
    private apiService: ApiService,
    public dialogRef: MatDialogRef<ChangeSubscriptionPlanComponent>,
    @Inject(MAT_DIALOG_DATA) public data: UserI)

   {
  
  }

  selectedOptions:ISubScriptionPLan[] = []
  currentPlan:any
  currenId = 1
  hasChanged = false
  ngOnInit(): void {
    console.log('ChangeSubscriptionPlanComponent INIT');
   console.log(this.data.accountType);
    
   this.selectedOptions = [this.ui.subscriptionPlan[0]]
    if(this.data.subscription_plan_id) {
      const index = this.ui.subscriptionPlan.findIndex(item => item.id == this.data.subscription_plan_id)
      if(index != -1) {
          this.currenId = this.ui.subscriptionPlan[index].id
          this.currentPlan = this.ui.subscriptionPlan[index].name
      }
        
    }
  }
  async save() {

    const _path = USER_API_REQUESTS(USER_API_CALL.POST_USER_SUBSCRION_ID)
 
    const _dto = {
      uid: this.data.uid,
      subscription_plan_id: this.currenId,
      // user_state_type_message_id: this.selectedUserStateTypeMessageId,
    }
    await this.userApi.post(_path, {dto: _dto })
    
    this.dialogRef.close("reload")
  }

  get subscriptionPlan(): ISubScriptionPLan[] {
    let allowd = this.data.accountType == ACCOUNT_TYPE_BASE.CLINIC ? [4,5] : [1,2,3]
    return this.ui.subscriptionPlan.filter(item => allowd.includes(item.id))
  }


  onSelectionCHanged(e:any) {
    console.log(e);
    this.currenId =  e.value

    this.hasChanged = true
    
  }

  get showSaveButton(): boolean {
    
    return this.hasChanged
  }

  close(): void {
    this.dialogRef.close();
  }
}
