<div class="container-fluid dxSpinnerC" *ngIf="isLoading">
  <div class="d-flex h100 w100 align-items-center justify-content-center" >
    <div class="p-2">
      <mat-spinner diameter="30"></mat-spinner>
    </div>
  </div>
</div>

<div class="container-fluid m_t15 p30" *ngIf="!isLoading && cs.currentClinic">

  <div class="d-flex flex-column w100">
    <div class="p-2 d-flex">
        <div class="d-flex flex-column w100">
          <div class="p-0">

            <div class="d-flex w100">
              <div class="p-2 flex-fill textEditable" matTooltip="Bearbeiten" (click)="editTextFor(TEXTTYPES.HEADLINE)">
                <h1>{{cs.currentClinic.title}}</h1>
              </div>
              <div class="p-2 ml-auto">
                 <dxfx-df-icon-button
                 color="warn"
                title="Löschen" icon="delete"
                (clicked)="deleteClinic()" ></dxfx-df-icon-button>
              </div>
  
            </div>
          </div>

          <div class="p-2 textEditable mt5" matTooltip="Bearbeiten" (click)="editTextFor(TEXTTYPES.DESCRIPTION)">
            {{cs.currentClinic.description}}
          </div>
        </div>
    </div>

    <div class="p-2">
      <mat-accordion>
        <mat-expansion-panel>
          <mat-expansion-panel-header>
            <mat-panel-title>Addresse</mat-panel-title>
          </mat-expansion-panel-header>
          <div class="container-fluid pl0 pr0" >
            <div class="row pl0 pr0`">
              <div class="col-lg-12 col-md-12 col-sm-12 "  >
                <mat-label class='mat-caption'>Klinik Rechn. Info</mat-label>
                <input class="formControl" placeholder="Die Rechnungserstellung erfolgt im Auftrag..."  [(ngModel)]="cs.currentClinic.clinicLegalMain" (ngModelChange)="modelChangeFn($event)">
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 m_t10"  >
                <mat-label class='mat-caption'></mat-label>
                <input class="formControl" placeholder="..."  [(ngModel)]="cs.currentClinic.clinicInvoiceProviderFirst" (ngModelChange)="modelChangeFn($event)">
              </div>
              
              <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
                <mat-label class='mat-caption'></mat-label>
                <input class="formControl" placeholder="..."  [(ngModel)]="cs.currentClinic.clinicInvoiceProviderSecond" (ngModelChange)="modelChangeFn($event)">
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
                <mat-label class='mat-caption'></mat-label>
                <input class="formControl" placeholder="..."  [(ngModel)]="cs.currentClinic.clinicInvoiceProviderThird" (ngModelChange)="modelChangeFn($event)">
              </div>
              
              <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
                <mat-label class='mat-caption'></mat-label>
                <input class="formControl" placeholder="..."  [(ngModel)]="cs.currentClinic.clinicInvoiceProviderFourth" (ngModelChange)="modelChangeFn($event)">
              </div>

              <div class="col-lg-6 col-md-6 col-sm-12 m_t10">
                <mat-label class='mat-caption'>Strasse</mat-label>
                <input  class="formControl" placeholder="Strasse"  [(ngModel)]="cs.currentClinic.address_street" (ngModelChange)="modelChangeFn($event)">
              </div>
              <div class="col-lg-3 col-md-3 col-sm-12 m_t10">
                <mat-label class='mat-caption'>Stadt</mat-label>
                <input class="formControl" placeholder="Stadt"  [(ngModel)]="cs.currentClinic.address_city" (ngModelChange)="modelChangeFn($event)">
              </div>
              <div class="col-lg-2 col-md-2 col-sm-12 m_t10">
                <mat-label class='mat-caption'>Plz</mat-label>
                <input class="formControl" placeholder="Plz"  [(ngModel)]="cs.currentClinic.address_zip" (ngModelChange)="modelChangeFn($event)">
              </div>

            </div>
            <div class="row">
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>Kontoinhaber</mat-label>
                <input class="formControl" placeholder="Kontoinhaber"  [(ngModel)]="cs.currentClinic.bank_owner" (ngModelChange)="modelChangeFn($event)">
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>Bankinstitut</mat-label>
                <input class="formControl" placeholder="Bankinstitut"  [(ngModel)]="cs.currentClinic.bank_name" (ngModelChange)="modelChangeFn($event)">
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>IBAN</mat-label>
                <input class="formControl" placeholder="IBAN"  [(ngModel)]="cs.currentClinic.iban" (ngModelChange)="modelChangeFn($event)">
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>BIC</mat-label>
                <input class="formControl" placeholder="BIC"  [(ngModel)]="cs.currentClinic.bic" (ngModelChange)="modelChangeFn($event)">
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6">
                <mat-label class='mat-caption'>Ust-IdNr.</mat-label>
                <input class="formControl" placeholder="Ust-IdNr."  [(ngModel)]="cs.currentClinic.ustNr" (ngModelChange)="modelChangeFn($event)">
              </div>
              <div class="col-lg-4 col-md-4 col-sm-6" style="padding-top: 25px;">
                <dxfx-df-icon-button *ngIf="showSaveButton"
                title="Adresse speichern" icon="save"
               (clicked)="updateClinic()" ></dxfx-df-icon-button>
              </div>
            </div>
            </div>
        </mat-expansion-panel>
      </mat-accordion>
    </div>

    <div class="p-2">
      <mat-toolbar class="overlay-toolbar">
        Admin
        <span class="toolbar-spacer"></span>
        <dxfx-df-icon-button
         title="Anlegen" icon="add"
        (clicked)="addAccounting()" ></dxfx-df-icon-button>
      </mat-toolbar>
    </div>
    <div class="p-2">
      <div class="container-fluid p0" style="background-color: rgba(0,0,0,0.02); padding-top: 8px;">
        <div class="d-flex flex-wrap ">

          <ng-template ngFor let-item [ngForOf]="cs.clinicAdmins" >
            <mat-card class="p-2 sector-box lg"  > 
              <!-- (click)="openAccounting(item)" -->
                <div class="d-flex flex-column">
                  <div class="p-1"><mat-icon>face</mat-icon></div>
                  <div class="p-1 fs14">{{item.email}}</div>
                  <div class="p-1 fs12">{{item.firstName}} {{item.lastName}}</div>
                </div>
             </mat-card>
          </ng-template>

        </div>
      </div>
    </div>

  

    <div class="p-2">
      <mat-toolbar class="overlay-toolbar">
        Fachbereiche
        <span class="toolbar-spacer"></span>
        <dxfx-df-icon-button
         title="Bereich anlegen" icon="add"
        (clicked)="addBereich()" ></dxfx-df-icon-button>
        <!-- <button mat-flat-button>Bereich anlegen</button> -->
      </mat-toolbar>
    </div>

    <div class="p-2">
      <div class="container-fluid p0" style="background-color: rgba(0,0,0,0.02); padding-top: 8px;">
        <div class="d-flex flex-wrap ">
          <ng-template ngFor let-item [ngForOf]="cs.sectors" >
            <mat-card class="p-2 sector-box" (click)="openBereich(item)">
                <div class="d-flex flex-column">
                  <div class="p-2"><mat-icon>group</mat-icon></div>
                  <div class="p-2">{{item.title}}</div>
                  <!-- <div class="p-2" style="font-size: 9px;">{{item.pool_id}}</div> -->
                </div>

             </mat-card>
          </ng-template>

        </div>
      </div>

    </div>

    <div class="p-2 ">

    </div>

  </div>
</div>