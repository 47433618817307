<div style="height:100%; overflow: hidden;">

  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <mat-icon class="mediumIconButton"  (click)='close()'>close</mat-icon>
  </mat-toolbar>


  <div class="container" style="height: calc(100% - 90px); overflow-y: scroll;">

    <div class="d-flex flex-column h100" *ngIf="sector">
      <div class="p-2 textEditable m_t15" matTooltip="Bearbeiten" (click)="editTextFor(TEXTTYPES.HEADLINE)">
        <h2>{{sector.title}}</h2>
      </div>

      <div class="p-2 textEditable mt5" matTooltip="Bearbeiten" (click)="editTextFor(TEXTTYPES.DESCRIPTION)">
        {{sector.description}}
      </div>

      <div class="p-0 h100">
        <mat-tab-group animationDuration="0ms" dynamicHeight>
          <mat-tab label="Benutzer">
            <div class="container-fluid h100 p0" style="min-height: 350px;">

              <div class="d-flex h100 w100 align-items-center justify-content-center" *ngIf="isLoadingUsers">
                <div class="p-2">
                  <mat-spinner diameter="30"></mat-spinner>
                </div>
              </div>

              <div *ngIf="!isLoadingUsers">
                <mat-toolbar class="overlay-toolbar">
                  <span class="toolbar-spacer"></span>
                  <dxfx-df-icon-button
                   title="Neuen Benutzer einladen" icon="mail"
                  (clicked)="inviteNewUser()" ></dxfx-df-icon-button>
                  <!-- <dxfx-df-icon-button
                   title="Benutzer hinzufügen" icon="add"
                  (clicked)="addUser()" ></dxfx-df-icon-button> -->
                </mat-toolbar>

                <table mat-table [dataSource]="poolUserDataSource" matSort class="mat-elevation-z0" style="width: 100%;" >
                  <ng-container matColumnDef="firstName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl10 ">Vorname</th>
                    <td mat-cell *matCellDef="let element" class="pl10 " style="min-width:200px;max-width:350px" >{{element?.firstName}}</td>
                   </ng-container>
                  <ng-container matColumnDef="lastName">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl10 ">Name</th>
                    <td mat-cell *matCellDef="let element" class="pl10 " style="min-width:200px;max-width:350px">{{element?.lastName}}</td>
                   </ng-container>
                  <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl10 ">Email</th>
                    <td mat-cell *matCellDef="let element" class="pl10 " style="min-width:200px;max-width:350px">{{element?.email}}</td>
                   </ng-container>

                   <ng-container matColumnDef="action" class="pr0">
                    <th mat-header-cell *matHeaderCellDef  class="pr0"></th>
                    <td mat-cell *matCellDef="let element" class="pr0 pl10">

                      <mat-icon class="mediumIconButton" matTooltip="User Berechtigung ändern" matTooltipClass="baseTT"
                       (click)='changeUserAccess($event, element)'>manage_accounts</mat-icon>

                      <mat-icon class="mediumIconButton ml10"  matTooltip="Fachbereich wechseln" matTooltipClass="baseTT"
                      (click)='changeUserPool($event, element)'>badge</mat-icon>
                      <!-- <mat-icon class="mediumIconButton"  (click)='removeUserFromPool($event, element)'>person_remove</mat-icon> -->
                    </td>
                   </ng-container>
                   <ng-container matColumnDef="status">
                    <th mat-header-cell *matHeaderCellDef mat-sort-header class="pl10 ">Status</th>
                    <td mat-cell *matCellDef="let element" class="pl10 " style="min-width:200px;max-width:350px">{{element?.email}}</td>
                   </ng-container>

                   <tr mat-header-row *matHeaderRowDef="poolUsersColumns;  sticky: true"></tr>
                   <tr mat-row class="element-row" *matRowDef="let row; columns: poolUsersColumns; let i = index;"
                   [class.tr_disabled]="row.app_access_type_enum === 'BLOCKED'" [class.trGray]="(i % 2) === 1" (click)='selectUser($event,row)'></tr>
                </table>
              </div>
            </div>
          </mat-tab>

          <mat-tab label="Adressen">
            <div class="container-fluid h100 p0">

              <mat-toolbar class="overlay-toolbar">
                <span class="toolbar-spacer"></span>

                <dxfx-df-icon-button *ngIf="currentAddress"
                 title="Adresse entfernen" icon="delete"
                (clicked)="deleteAddress()" color="warn"></dxfx-df-icon-button>

                <dxfx-df-icon-button
                 title="Adresse hinzufügen" icon="add"
                (clicked)="addAddress()" ></dxfx-df-icon-button>

                <dxfx-df-icon-button *ngIf="showSaveButton"
                title="Adresse speichern" icon="save"
               (clicked)="updateAddress()" ></dxfx-df-icon-button>
              </mat-toolbar>

            <div class="d-flex h100 w100 align-items-center justify-content-center _br"  *ngIf="addresses.length === 0">
              <div class="p-2">
                <h2>Keine Adresse verhanden</h2>
              </div>
            </div>


              <div class=" " *ngIf="currentAddress && addresses.length > 0">

                <mat-tab-group animationDuration="0ms" dynamicHeight color="accent" backgroundColor="accent" >
                  <!-- ="$adminAccent" -->
                  <mat-tab *ngFor="let address of addresses; let i = index" label="{{(i + 1).toString()}}">
                    <dxfx-clinic-address [address]="address"></dxfx-clinic-address>
                  </mat-tab>

                </mat-tab-group>
                
<!-- 
              <div class="d-flex mt15">
                <div class="p-0">
                  <button mat-raised-button (click)="update()">Speichern</button>
                </div>
                <div class="p-0 ms-auto m_t5">
                  <mat-radio-group color="primary" class="example-radio-group"
                     (change)="addressIndexChanged($event)">

                  <mat-radio-button class="example-radio-button"
                  *ngFor="let address of addresses; let i = index;" [value]="address">
                  </mat-radio-button>
                </mat-radio-group>
              </div>
              <div class="p-0 ms-auto ">
                  <mat-icon class="dxfx_icon_btn_mini " style="padding-top: 4px!important" matTooltip="Neue Addresse anlegen" (click)="addAddress()">add</mat-icon>
                  <button mat-raised-button (click)="addUsers()">User hinzufügen</button>
                </div>
              </div> -->
              </div>
            </div>
          </mat-tab>

        </mat-tab-group>
      </div>
    </div>



  </div>


  <mat-toolbar class="overlay-toolbar">
    <span class="toolbar-spacer"></span>
    <dxfx-df-icon-button
      title="Bereich entfernen" icon="delete"
     (clicked)="deleteSector()" color="warn"></dxfx-df-icon-button>
  </mat-toolbar>
</div>
