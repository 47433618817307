import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { API_CALL } from 'src/app/core/api.call.enum';

import { IEmailTemplate } from 'src/app/core/models/emailItem';
import { ApiService } from 'src/app/services/api.service';
import { UiService } from 'src/app/services/ui.service';
import { EmailDetailComponent } from '../email-detail/email-detail.component';

@Component({
  selector: 'dxfx-email-template-overview',
  templateUrl: './email-template-overview.component.html',
  styleUrls: ['./email-template-overview.component.scss']
})
export class EmailTemplateOverviewComponent implements OnInit {
  emailTemplates:IEmailTemplate[] = []


  constructor(private api:ApiService, private dialog:MatDialog, private ui:UiService) { }


  ngOnInit(): void {
    console.log('EmailTemplateOverviewComponent')
    setTimeout(()=>{
      this.load();
      this.ui.currentPage.emit('email-templates');
    },1000)
  }
  async load(){
    (await this.api.apiGet(API_CALL.GET_EMAIL_DETAIL)).pipe().subscribe(
      _data => {
        // let _newData:any[] = _data as any[]
        this.emailTemplates = _data as IEmailTemplate[]




      }
    );


  }
  openEmailDetail(email:IEmailTemplate){
    this.dialog.open(EmailDetailComponent,{
      width:'100vw',
      height:"100vh",
      data:email
    })
  }
}
