import { Component, OnInit} from '@angular/core';
import { UiService } from 'src/app/services/ui.service';


@Component({
  selector: 'dxfx-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  title: string = ""


  constructor(private ui: UiService) { }

  ngOnInit(): void {
    console.log('DASHBOARD')
    setTimeout(()=>{
       this.ui.currentPage.emit('dashboard')
     },800)
  }

}
