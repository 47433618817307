import { EventEmitter, Injectable } from '@angular/core';
import { API_CALL } from '../core/api.call.enum';
import * as _ from 'lodash';

import {  ISubScriptionPLan, ITemplatePool, IUserBaseRole, IUserFilterItem, PadNextFachArztFull, UserFilterType, UserFilterValue } from '../core/models/role.model';
import {  IUserAccessType } from '../core/models/user.states.model';
import { ApiService } from './api.service';
import { PoolService } from './pool.service';

@Injectable({
  providedIn: 'root'
})
export class UiService {
  private _templateSortName:number = 0;
  private _templateSortEmail:number = 0;
  templateSortChanged:EventEmitter<number[]> = new EventEmitter<number[]>();

  private sortNameArr:string[] = ['nameAsc', 'nameDesc', ''];
  private sortEmailArr:string[] = ['','emailAsc', 'emailDesc'];
  
  
  private  _klinikUserFilter: number[] = [17,18, 19, 20, 21, 22,23,24]
  private  _accountantUserFilter: number[] = [8,9,10,21, 22,23,24]
  private  _testUserFilter: number[] = [11,12,13,14,15,16]
  private  _onlyPaidUser: number[] = [2,3,4,5,6,7,8,9,10,17,18,19,20,21,22,23]
  private  _allRolIds: number[] = []
 
  get allUserRoleKeys() :number [] {

    return this._allRolIds
  }
  get padNextFachFull() :PadNextFachArztFull [] {

    return this._padNextFachFull
  }

  //   console.log(this.padNextFachFull);
  private _template_pools: ITemplatePool[] = [];
  private _baseUserRoles: IUserBaseRole[] = [];
  private _subscriptionPlan: ISubScriptionPLan[] = [];
  
  private _allUserAccessTypes: IUserAccessType[] = []
  private _activeUserFilterStates: IUserAccessType[] = []
  private _padNextFachFull: PadNextFachArztFull[] = []

  currentPage:EventEmitter<string> = new EventEmitter<string>();
  hasNavigateTo:EventEmitter<string> = new EventEmitter<string>();
  snack:EventEmitter<string> = new EventEmitter<string>();
  customSnack:EventEmitter<ISnackData> = new EventEmitter<ISnackData>();
  activeUserFilter: UserFilterValue =  { type: UserFilterType.ALL, viewValue: "Alle anzeigen"}
  userFilterAll:UserFilterValue[] = [
    { type: UserFilterType.ALL, viewValue: "Alle anzeigen"},
    { type: UserFilterType.PAID_ONLY, viewValue: "Zahlende User"},
    { type: UserFilterType.ACCOUNTANT, viewValue: "Abrechnungs Personen"},
    { type: UserFilterType.CLINIC_USER, viewValue: "Klinik Benutzer"},
    { type: UserFilterType.TEST_USER, viewValue: "Test Benutzer"},
  ]
  // userFilter:UserFilterValue[] = []

  toggleUserFilter(role: UserFilterValue){
    this.activeUserFilter = role
    // this.activeUserFilter === role ? this.activeUserFilter : 
    switch (this.activeUserFilter.type) {
      case UserFilterType.ALL: 
      this._allRolIds = _.map(this._baseUserRoles , 'base_role_key');
      console.log(this._allRolIds);
      return
      case UserFilterType.PAID_ONLY: 
      this._allRolIds = this._onlyPaidUser
      return
      case UserFilterType.ACCOUNTANT: 
      this._allRolIds = this._accountantUserFilter
      return
      case UserFilterType.CLINIC_USER: 
      this._allRolIds = this._klinikUserFilter
      return
      case UserFilterType.TEST_USER: 
      this._allRolIds = this._testUserFilter
      return
    }
    // const _index = this.userFilter.findIndex(item => item.type == role.type)
    // if(_index != -1 ) {
    //   // this.userFilter.splice(_index, 1)
    //   this.userFilter = [this.userFilterAll[0]]
    // } else {
    //   // this.userFilter.push(role)
    //   this.userFilter = [role]
    // }
    // if(this.userFilter.includes(role)){
     
    // } else {
      
    // }
  }
 

  // userFilterIncludes(filter:UserFilterValue):boolean{
  //   if(this.userFilter == null){
  //     this.userFilter = []
  //   }
  //   return this.userFilter.includes(filter)
  // }
  constructor(
    private api:ApiService,
    private poolApi: PoolService) {
      this.initUiData()
      

    }
   


  async initUiData() {
    // const _types = await (await this.api.getPromise(API_CALL.GET_UI_USER_STATE_TYPES)).toPromise()
    // this._userStateTypes = _types as IUserStateType[]
    const _types = await (await this.api.getPromise(API_CALL.GET_ALL_ACCESS_TYPE)).toPromise()
    this._allUserAccessTypes = _types as IUserAccessType[]

    // console.log(this._allUserAccessTypes);
    const _baseRoles = await (await this.api.getPromise(API_CALL.GET_USER_BASE_ROLES)).toPromise()
    this._baseUserRoles = _baseRoles as IUserBaseRole[]
    this._allRolIds = _.map(this._baseUserRoles , 'base_role_key');
    // console.log(this._baseUserRoles);
    
    const __subscriptionPlan = await (await this.api.getPromise(API_CALL.GET_SUBSCRIPTION_PLAN)).toPromise()
    this._subscriptionPlan = __subscriptionPlan as ISubScriptionPLan[]
    
    const padAll = (await this.api.getPromise(API_CALL.GET_PAD_NEXT_FACH_ALL)).pipe().subscribe(data => {
      this._padNextFachFull = data as PadNextFachArztFull[]
      console.log(this._padNextFachFull);
    })
    
  }

 
  async loadPools(){
    

    // (await this.api.apiGet(API_CALL.TEMPLATE_POOLS)).pipe().subscribe(
    //   _data => {
    //     this._template_pools = _data as ITemplatePool[]
    //     console.log('pools');

    //     console.log(this._template_pools)
    //   }
    // )
    // this.loadRoles()

  }


  toggleUserAccessState(state: IUserAccessType) {

  }
  get baseUserRoles(): IUserBaseRole[] {
    
    var items = []
    items = _.filter(this._baseUserRoles, item => item.isVisible == 1)
    return items.sort((a,b) => a.order - b.order)
  }
  get allUserAccessTypes(): IUserAccessType[] {
    return this._allUserAccessTypes
  }
  // get userStateTypeMessages(): IUserStateTypeMessage[] {
  //   return this._userStateTypeMessages
  // }
  // get userStateTypeMessageChanges(): IStateMessageAvalableChanges[] {
  //   return this._userStateTypeMessageChanges
  // }

  activeUserFilterStates(filter:IUserAccessType):boolean{
    if(this._activeUserFilterStates == null){
      this._activeUserFilterStates = []
    }
    return this._activeUserFilterStates.includes(filter)
  }
  get template_pools(): ITemplatePool[] {
    return this._template_pools
  }
  // get allRoles(): IRole[] {
  //   return this._roles
  // }
  navigate(path:string){
    console.log(`ui navigate emit ${path}\n`);
    
    this.hasNavigateTo.emit(path);
  }


  

  


  get templateSort(): any{
    const _name = this.templateNameSort
    const _date = this.templateEmailSort
    if(_date == ''){

      return _name == 'nameDesc' ? 'NAME_DESC' : 'NAME_ASC';

    } else if(_date == 'emailAsc'){

      if(_name == '') {
        return 'DATE_ASC'
      } else {
        return _name == 'nameDesc' ? 'DATE_ASC_NAME_DESC' : 'DATE_ASC_NAME_ASC';
      }

    } else if(_date == 'emailDesc'){
      if(_name == '') {
        return 'DATE_DESC'
      } else {
        return _name == 'nameDesc' ? 'DATE_DESC_NAME_DESC' : 'DATE_DESC_NAME_ASC';
      }

    }
  }


async loadAllUsersAccess(){
  (await this.api.apiGet(API_CALL.USERS_ADMIN_ALL_WITH_ACCESS)).pipe().subscribe(
    _data2 => {
      console.log("loadAllUsersAccess> ", _data2)

    }
  )
}




toggleTemplateNameSort(){
  if(this._templateSortName == 2){
    this._templateSortName = 0;
  } else {
    this._templateSortName ++;
  }
  this.templateSortChanged.emit([this._templateSortName, this._templateSortEmail])
}

toggleTemplateEmailSort(){
  if(this._templateSortEmail == 2){
    this._templateSortEmail = 0;
  } else {
    this._templateSortEmail ++;
  }
  this.templateSortChanged.emit([this._templateSortName, this._templateSortEmail])
}
  get templateNameSort():string {
    return this.sortNameArr[this._templateSortName];
  }

  get templateEmailSort():string {
    return this.sortEmailArr[this._templateSortEmail];
  }
  get subscriptionPlan(): ISubScriptionPLan[] {
    return this._subscriptionPlan
  }
  // async addUserToClinic(clinicId:number, userId:string){
  //   //console.log(API_REQUESTS(API_CALL_CLINIC.ADD_CLINIC_TO_USER))
  //   (await this.clinicApi.post(API_REQUESTS(API_CALL_CLINIC.ADD_CLINIC_TO_USER),{
  //     clinicId: clinicId,
  //     userId: userId
  //   }));
  // }


 
}


//
export interface ISnackData {
  duration: number
  panelClass: string
  message: string
}
